import $ from "jquery";

/**
 * @description
 * Configurações gerais da App
 */
export const Configs = {
  client_name: {
    name: "",
    reg_name: "",
  },
  // URL do site: https://omeudominio.com/
  base_url:
    process.env.NODE_ENV === "development"
      ? "http://localhost:3000/"
      : "https://priluxmetalworking.com/",
  rest: "https://priluxmetalworking.com/api",
  thirdPartyRest: {
    countries: "https://restcountries.eu/rest/v2",
  },
  media_prefix: "https://priluxmetalworking.com/assets/",
  media: {
    docs: "docs/",
    imgs: "imgs/",
    symbols: "imgs/symbols/",
  },
  default_lang:
    window.sessionStorage.getItem("defualtLang") === null
      ? "pt"
      : window.sessionStorage.getItem("defualtLang"), // default lang
  lang: ["pt"],// lang: ["pt", "en", "es", "fr"],
  social_media: {
    fb: ["https://www.facebook.com/PriluxMetalworking.92/", "fa-facebook"], // Facebook
    in: ["https://www.linkedin.com/company/prilux-lda.", "fa-linkedin"], // LinkedIn
    ut: [
      "https://www.youtube.com/channel/UCcGs4OShHDbhTH3i1bKKnOQ",
      "fa-youtube",
    ], // YouTube
    tw: [], // Twitter
    gplus: [], // Google +
    inst: ["https://www.instagram.com/prilux.metalworking/", "fa-instagram"], // Instagram
    pt: [], // Pinterest
    wapp: [], // WhatsApp
    skyp: [], // Skype
    wz: [], // Waze
    or: [], // Orkut
    tb: [], // Tumblr
    flck: [], // Flickr
    blog: [], // Blog
    snap: [], // Snapchat
    mysp: [], // MySpace}
  },
  form_fields: {
    // name
    subject: {
      type: "hidden",
      className: "form-control",
      id: "subject",
      name: "subject",
      //"data-i18n-key": "",
    },
    // name
    name: {
      type: "text",
      className: "form-control",
      id: "name",
      name: "name",
      "data-i18n-key": "name",
    },
    // idade
    age: {
      type: "number",
      className: "form-control",
      id: "age",
      name: "age",
      "data-i18n-key": "age",
    },
    // morada
    address: {
      type: "text",
      className: "form-control",
      id: "address",
      name: "address",
      "data-i18n-key": "address",
    },
    // cidade
    city: {
      type: "text",
      className: "form-control",
      id: "city",
      name: "city",
      "data-i18n-key": "city",
    },
    // nacionalidade
    citizenship: {
      type: "text",
      className: "form-control",
      id: "citizenship",
      name: "citizenship",
      "data-i18n-key": "citizenship",
    },
    // contacto mobile | phone
    mobile: {
      type: "number",
      className: "form-control",
      id: "mobile",
      name: "mobile",
      //onChange: props.handleChange,
      "data-i18n-key": "mobile",
    },
    // email
    email: {
      type: "email",
      className: "form-control",
      id: "email",
      name: "email",
      "data-i18n-key": "email",
    },
    // company
    company: {
      type: "text",
      className: "form-control",
      id: "company",
      name: "company",
      "data-i18n-key": "company",
    },

    // função
    occupation: {
      type: "text",
      className: "form-control",
      id: "occupation",
      name: "occupation",
      "data-i18n-key": "occupation",
    },
    // perfil linkeind
    linkedin: {
      type: "text",
      className: "form-control",
      id: "linkedin",
      name: "linkedin",
      "data-i18n-key": "linkedin",
    },
    // observações
    remarks: {
      type: "textarea",
      className: "form-control",
      id: "remarks",
      name: "remarks",
      rows: 3,
      //onChange: props.handleChange,
      "data-i18n-key": "remarks",
    },
    // CV / Ficheiro
    cv: {
      type: "file",
      className: "form-control",
      id: "cv",
      name: "cv",
      rows: 3,
      //onChange: props.handleChange,
      "data-i18n-key": "cv",
    },
    department: {
      type: "select",
      className: "form-control",
      id: "department",
      name: "department",
      //onChange: props.handleChange,
      "data-i18n-key": "department",
      offspring: [],
      /**
       * offspring: [], // array de opções para o SELECT de departamento
       *
       * Neste caso obtém um component
       *
       * para efeitos de demo de desenvolvimento simula obtenção dos
       * dados a partir de simulação de acesso API localizado em /public/assets/api4contacts_{lang}.json
       */
    },
    // tipo cliente
    clientKind: {
      type: "select",
      className: "form-control",
      id: "clientkind",
      name: "clientkind",
      //onChange: props.handleChange,
      "data-i18n-key": "clientKind",
      offspring: [],
      /**
       * offspring: [], // array de opções para o SELECT de departamento
       *
       * Neste caso obtém um component
       *
       * para efeitos de demo de desenvolvimento simula obtenção dos
       * dados a partir de simulação de acesso API localizado em /public/assets/api4contacts_{lang}.json
       */
    },
    // país
    country: {
      type: "select",
      className: "form-control",
      id: "country",
      name: "country",
      //onChange: props.handleChange,
      "data-i18n-key": "country",
      offspring: [],
      /**
       * offspring: [], // array de opções para o SELECT de departamento
       *
       * Neste caso obtém um component
       *
       * para efeitos de demo de desenvolvimento simula obtenção dos
       * dados a partir de simulação de acesso API localizado em /public/assets/api4contacts_{lang}.json
       */
    },
    // consentimento RGPD
    rgpdconsent: {
      type: "checkbox",
      className: "form-check-input",
      name: "rgpdconsent",
      "data-i18n-key": "",
      offspring: [
        {
          id: "rgpd",
          text: "",
          value: "1",
        },
      ],
      /**
       * offspring: [], // array de opções para o CHECKbox de tipos de carta de condução
       *
       * Neste caso obtém um component
       *
       * para efeitos de demo de desenvolvimento simula obtenção dos
       * dados a partir de simulação de acesso API localizado em /public/assets/api4contacts_{lang}.json
       */
    },

    emarketing: {
      type: "radio",
      className: "form-check-input",
      name: "emarketing",
      "data-i18n-key": "emarketing",
      offspring: [
        {
          id: "emarketingY",
          i18n: "yes",
          value: "1",
        },
        {
          id: "emarketingN",
          i18n: "no",
          value: "0",
        },
      ],
      /**
       * offspring: [], // array de opções para o CHECKbox de tipos de carta de condução
       *
       * Neste caso obtém um component
       *
       * para efeitos de demo de desenvolvimento simula obtenção dos
       * dados a partir de simulação de acesso API localizado em /public/assets/api4contacts_{lang}.json
       */
    },
  },
};

/**
 * @v 0.0
 * @date 2020/07
 *
 * arrayOfElements => [
 *  { id: <int>,
 *    ...,
 *    type=<string>},
 *  {...},
 *  {...}
 * ]
 *
 * typeOfElement => "generic | thumbnail | etc"
 *
 * @param {*} arrayOfElements
 * @param {*} typeOfElement
 */
export const extractElementsFromArrayByIsType = (
  arrayOfElements,
  typeOfElement
) => {
  let result = null;

  try {
    if (arrayOfElements !== null) {
      result = arrayOfElements.filter((val) => {
        return val.type.toLowerCase() === typeOfElement.toLowerCase();
      });
    } else {
      throw "Array of elements must have some content.";
    }
  } catch (error) {
    console.error(`[ERROR]: ${error}`);
  }

  return Array.isArray(result) && result.length > 0 ? result : null;
};

/**
 * @v 0.0
 * @date 2020/07
 *
 * @description
 * Para inclusão de elementos recorrendo ao caminho absoluto dos mesmos
 *
 * @dependency
 * Esta função depende da presença das entradas media_prefix e media no
 * array/objecto Configs
 *
 * @param {*} kindOfElment -> nomenclatura do ficheiro
 * @param {*} kindOfElment -> tipo de media a incluir na App
 */
export const insertMediaWithAppAbsPath = (mediaName, kindOfMedia) => {
  let absPath = undefined;

  try {
    absPath =
      Configs.media[kindOfMedia] !== undefined && typeof mediaName === "string"
        ? `${Configs.media_prefix}${Configs.media[kindOfMedia]}${mediaName}`
        : "";
  } catch (error) {
    console.error(`[ERROR]: ${error}`);
  }

  return absPath;
};

/**
 * @description
 *
 * Verifica/testa se uma imagem fornecida por parÃ¢metro existe. se afirmativo
 * apresenta a mesma, caso contrÃ¡rio apresenta imagem alternativa/default
 *
 * @description ends
 *
 * @param {string} _src       -> URL da imagem
 * @param {function} callback -> funÃ§Ã£o a correr como callback Ã  verificaÃ§Ã£o
 *                               da existÃªncia ou nÃ£o da imagem
 * @returns n.a.
 * */
export const imageExists = (_src, callback) => {
  var img = new Image();
  var res = false;

  try {
    img.onload = function () {
      callback(true);
    };
    img.onerror = function () {
      callback(false);
    };

    img.src = _src;
  } catch (e) {
    console.error("[ImageExists]: " + e);
  }
};

/**
 * @description
 *
 * Obtem dados introduzidos pelo utilizador na form, coloca-os num objecto
 * tipo FormData e devolve-os à entidade caller para o tratamento dos mesmos
 *
 * "Pega" no objecto entrado por parâmetro e coloca-o para a FORM DATA - NÃO
 * EXECUTA qualquer tipo de validação dos dados...
 *
 * @param {*} usrData         -> dados inseridos na FORM pelo utilizador
 * @returns {object} FormData -> FormData para presistência em sistema remoto
 */
export const setDataToFormData = (usrData) => {
  let data2Send = new FormData();

  try {
    // o objecto que entra por parâmetro deve ser constituído
    // por um conjunto de pares KEY : valor
    let objKeys = Object.keys(usrData);

    if (Array.isArray(objKeys) && objKeys.length > 0) {
      objKeys.forEach((val) => {
        data2Send.append(val, usrData[val]);
      });
    } else {
      throw this.t("errorHandler").frmDataEmptyOrNull;
    }
  } catch (error) {
    console.error(`[setDataToFormData]: ${error}`);
  }

  return data2Send;
};

/**
 * @description
 * Algumas mensagens de status não activam de acordo com o que
 * é suposto, i.e., por aleração da STATE var associada pelo que
 * se procedeu com esta funcção no sentido de contornar essa situação
 *
 * Proceder a refactoring assim que se verificar possivel
 *
 * USADO em AboutQuality, AboutGlobal
 *
 * @param {string} submitRes          -> objecto com os dados a considerar
 *                                       para a mensagem a apresentar ao utilizador
 * @param {React.Component} component -> component onde está a ser processado o pedido
 *                                       de acesso à API que resulta nesta mensagem
 */
export const dealWithMessageBox = (submitRes, component) => {
  // verifica o conteúdo do resultado para definição de caminho a seguir
  if ((submitRes !== null) & submitRes.res) {
    // referencia vários elementos da estrutura da mensagem a apresentar ao utilizador
    let mBox = document.getElementsByClassName("submit-status")[0];
    let mBoxText = mBox.getElementsByTagName("span")[0];
    let close = mBox.getElementsByClassName("close")[0];

    // função auxiliar para proceder ao reset da caixa de mensagem e por essa
    // via prepara a mesma p a próx mensagem
    const resetBox = () => {
      if (mBox) {
        mBoxText.innerHTML = "";
        mBox.classList.remove("show");
        mBox.classList.remove("alert-success");
        mBox.classList.remove("alert-danger");
        mBox.classList.add("alert-light");

        let { submitRes } = component.state;
        submitRes = null;
        component.setState({ submitRes }, () => console.log(component.state));

        close.removeEventListener("click", null);
      }
    };

    // se a referenciação da caixa de mensagem devolveu resultados, trata de apresentar
    // a mensagem ao utilizador
    if (mBox) {
      mBox.classList.add("show");

      mBox.classList.remove("alert-light");

      if (submitRes.res) {
        mBox.classList.add("alert-success");
      } else {
        mBox.classList.add("alert-danger");
      }

      /**
       * Evento click no icone de fecho da message (X)
       */
      close.addEventListener("click", (e) => {
        resetBox();
      });

      /**
       * fecha box automáticamente
       */
      setTimeout((e) => {
        resetBox();
      }, 3000);
    }

    if (
      mBoxText &&
      submitRes &&
      "msg" in submitRes &&
      typeof submitRes.msg === "string"
    ) {
      mBoxText.innerHTML = submitRes.msg;
    }
  }
};

/**
 * @description
 * Para tratamento do resultado dos processos de comunicação com a API
 * em componentes como AboutGlobal, AboutQuality
 *
 * @param {array} result  -> resultado do pedido à API, normalmente um array de objectos
 * @param {object} xhr    -> auto explicativo...
 * @param {object} myForm -> FORM origem do pedido e onde se processa a interacção do utilizador
 * @param {React.Component} component -> Component React onde é processado o pedido
 */
export const dealWithAPIReturnedData = (result, xhr, myForm, compoent) => {
  let formLoader = document.getElementById("formLoader"); // referencia o loader que está ao lado do butão submit da form

  // se a referenciação devolve resultados, existem um loader, esconde-o
  if (formLoader !== undefined) {
    formLoader.classList.remove("l-visible");
  }

  // verifica o status da resposta do pedido à API, neste caso de uma comunicação com
  // origem em determinada FORM
  if (
    xhr.status === 200 && // se status de resposta 200, OK
    "res" in result && // verifica se existe um RES , e trata-os de acordo...
    result.res !== undefined &&
    result.res !== null
  ) {
    let { submitRes } = compoent.state;
    submitRes = result;

    // para apresentação ao utilizador do resultado do pedido executado
    dealWithMessageBox(result, compoent);

    // procede ao reset da FORM se resposta for positiva...
    if (submitRes.res) {
      myForm.reset();
    }

    // altera o estado da mensagem de retorno do processo de envio ao servidor
    compoent.setState({ submitRes });
  } else {
    // SE PEDIDO NOK
    throw this.t("errorHandler").emailNotSentError;
  }
};

/**
 * @description
 * Complemento ao windowPushState para preparação de informação para o efeito
 *
 * @param {string} url -> url para o pushstate,  se este não existir usa as vars
 *                        de sistema para o efeito
 * @param {object} getv -> objecto onde estão definidas as variaveis GET a user no URL
 */
export const changeBrowserHistory = (url = null, getv = null) => {
  try {
    // constroí URL

    const _url = () => {
      let { protocol, host, pathname } = window.location;
      return `${protocol}//${host}${pathname}`;
    };

    // novo URL a usar p o efeito
    let newPath = url !== null ? url : _url();

    // variavel para guardar as GET vars
    let getVars = "?";

    // se o objecto das GET vars n estiver vazio... cosntroi a string para
    // o efeito
    if (getv !== null) {
      let objKeys = Object.keys(getv);

      objKeys.forEach((val, key) => {
        getVars += `${val}=${getv[val]}&`;
      });
    }

    // retira o último & da string...
    getVars = getVars.substr(0, getVars.length - 1);
    newPath += getVars;

    // altera o URL do browser
    window.history.pushState("", "", newPath);
  } catch (error) {
    console.error(`[ERRO] ${error}`);
  }
};

/**
 * @description
 * Da acção de click nos filtros usados para este projecto, resulta um
 * link com o formato: protocolo://dominio/opcao_filtro
 * Neste caso deste projecto em concreto apenas necessitamos da última
 * componente, sendo que esta função trata de o obter, i.e., recebe o
 * URL clicado separa-o nas suas partes constituintes o obtem apenas a(s)
 * componente(s) com o prefixo fc, isto porque, os filtros são definidos por
 * uma ou mais componenetes do tipo Fc1, Fc2, fc3 , ... neste caso em concreto
 * tb poderão ser constituídos por componentes fid e outros....
 *
 * @param {string} selectFilter -> URL do filtro seleccinado
 * @param {string} requestURI   -> URI onde a componente FC do filtro vai ser usada
 */
export const splitFilterUrl = (selectFilter, requestURI) => {
  let result = requestURI; // para o novo URI a usar em eventual pedido de filtro

  try {
    /**
     * O formato do FILTRO passa por
     * https://<domninio>/<lg>/<view>/fc....
     * extrai a componente fc.... do filtro
     *
     * Faz a divisão do URL disponibilizado pelo /, filtra os resultados e obtem
     * as componentes FC
     **/
    let fcFilter = selectFilter.split("/").filter((val) => {
      return val.indexOf("fc") >= 0;
    });

    /**
     * Concatena a(s) componente(s) anteriores do filtro no URL efectivamente
     * a usar no pedido filtrado
     */
    if (Array.isArray(fcFilter) && fcFilter.length === 1) {
      result += `${fcFilter[0]}`;
    }
  } catch (error) {
    console.error(`[ERRO] ${error}`);
  }

  return result; // devolve resultados
};

/**
 * @description
 * Obtem a compoente SEARCH do browser e separa-os nos seus constituíntes
 * de acordo com um conjunto de parametros disponibilizado através de um array
 * ou não
 *
 * @param {string} URISearch               -> componentes search do URL
 * @param {array | null} components2bFound -> array de chaves (string) a serem pesquisadas no URL
 */
export const extractSomeComponentsFromURISearch = (
  URISearch,
  components2bFound = null
) => {
  let result = null;
  let uriComponents = null;

  try {
    //verifica se se URISearch possui valor...
    if (URISearch !== null && URISearch !== undefined) {
      // explode o valor obtido com base no "&", ter em atenção que o primeiro
      // caracter da string é um ?
      uriComponents = URISearch.substring(1).split("&");

      // da operação anterior resulta um array de string cujo conteúdo tem o
      // formato varX = valorX
      // transforma esse array num objecto que exprema essa mesma situação
      uriComponents = uriComponents.filter((val, key) => {
        let t = val.split("=");

        if (
          Array.isArray(uriComponents) &&
          uriComponents.length > 0 &&
          Array.isArray(components2bFound) &&
          components2bFound.length
        ) {
          return components2bFound.some((r) => t.includes(r));
        } else {
          return t;
        }
      });

      // transforma os resultados obtidos num objecto
      let temp = {};

      uriComponents.forEach((val, key) => {
        let t = val.split("=");
        temp = Object.assign(temp, {
          [t[0]]: t[1],
        });
      });

      result = temp;
    }
  } catch (error) {
    console.error(error);
  }

  return result;
};

export const makeURISearch2RestReadble = (URISearch) => {
  let result = "";
  try {
    let aux = Object.keys(URISearch);

    aux.forEach((val, key) => {
      result += `${val}${URISearch[val]}/`;
    });
  } catch (error) {
    console.error(`[ERRO] ${error}`);
  }

  return result;
};

/*Deteção se a Div está na VIewport isto é visivel no browser */
$.fn.isInViewport = function () {
  var elementTop = $(this).offset().top;
  var elementBottom = elementTop + $(this).outerHeight();

  var viewportTop = $(window).scrollTop();
  var viewportBottom = viewportTop + $(window).height();

  return elementBottom > viewportTop && elementTop < viewportBottom;
};

//Deteção de Colisão entre div's
$.fn.isColliding = function ($div2, margin = 0) {
  try {
    // Div 1 data
    var $div1 = $(this);
    var d1_offset = $div1.offset();
    var d1_height = $div1.outerHeight(true) - margin;
    var d1_width = $div1.outerWidth(true);
    var d1_distance_from_top = d1_offset.top + d1_height;
    var d1_distance_from_left = d1_offset.left + d1_width;

    // Div 2 data
    var d2_offset = $div2.offset();
    var d2_height = $div2.outerHeight(true);
    var d2_width = $div2.outerWidth(true);
    var d2_distance_from_top = d2_offset.top + d2_height;
    var d2_distance_from_left = d2_offset.left + d2_width;

    var not_colliding =
      d1_distance_from_top < d2_offset.top ||
      d1_offset.top > d2_distance_from_top ||
      d1_distance_from_left < d2_offset.left ||
      d1_offset.left > d2_distance_from_left;
  } catch (error) {}
  // Return whether it IS colliding
  return !not_colliding || false;
};

window.mobileAndTabletCheck = function () {
  var Return_Device;
  if (
    /(up.browser|up.link|mmp|symbian|smartphone|midp|wap|phone|android|iemobile|w3c|acs\-|alav|alca|amoi|audi|avan|benq|bird|blac|blaz|brew|cell|cldc|cmd\-|dang|doco|eric|hipt|inno|ipaq|java|jigs|kddi|keji|leno|lg\-c|lg\-d|lg\-g|lge\-|maui|maxo|midp|mits|mmef|mobi|mot\-|moto|mwbp|nec\-|newt|noki|palm|pana|pant|phil|play|port|prox|qwap|sage|sams|sany|sch\-|sec\-|send|seri|sgh\-|shar|sie\-|siem|smal|smar|sony|sph\-|symb|t\-mo|teli|tim\-|tosh|tsm\-|upg1|upsi|vk\-v|voda|wap\-|wapa|wapi|wapp|wapr|webc|winw|winw|xda|xda\-) /i.test(
      navigator.userAgent
    )
  ) {
    if (
      /(tablet|ipad|playbook)|(android(?!.*(mobi|opera mini)))/i.test(
        navigator.userAgent
      )
    ) {
      Return_Device = "Tablet";
    } else {
      Return_Device = "Mobile";
    }
  } else if (
    /(tablet|ipad|playbook)|(android(?!.*(mobi|opera mini)))/i.test(
      navigator.userAgent
    )
  ) {
    Return_Device = "Tablet";
  } else {
    Return_Device = "Desktop";
  }

  return Return_Device;
};
