import React, { Component /* , Fragment */ } from "react";
import { withTranslation } from "react-i18next";

import "../scss/AppButton.scss";

class AppButton extends Component {
  constructor(props) {
    super(props);
  }

  makeButton = () => {
    let _class = this.props.className ? this.props.className : "";
    let result = null;

    switch (_class) {
      case "universe_button":
        result = (
          <>
            <img src="/assets/imgs/Seta-botao-01.png" alt="" />
            <img src="/assets/imgs/Seta-botao-01.png" alt="" />
            <img src="/assets/imgs/Seta-botao-01.png" alt="" />
          </>
        );
        break;
      default:
        result = (
          <>
            <img src="/assets/imgs/Seta-botao-01.png" alt="" />
            <img src="/assets/imgs/Seta-botao-01.png" alt="" />
            <img src="/assets/imgs/Seta-botao-01.png" alt="" />
          </>
        );
    }

    return result;
  };

  render() {
    return (
      <>
        {this.props.text}
        <span className="icon_next">{this.makeButton()}</span>
      </>
    );
  }
}

export default withTranslation()(AppButton);
