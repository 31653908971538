import React, { Component } from "react";
import { withTranslation } from "react-i18next";

/**
 * @description
 *
 * Para tratamento de erros
 *
 * ==> Como utilizar <==
 *
 * Fazer o import do ficheiros nos locais onde eventualmente se vai
 * usar esta funcionalidade e na execução do código, após as necessárias
 * validações executar, se necessário, um thorw Error(<mensagem a apresentar>)
 *
 * @param {*} props
 */
class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      errorInfo: null,
    };
  }

  /**
   * @description
   * Para tratamento de ERROS - error handling
   *
   * @param {*} error
   * @param {*} info
   */
  componentDidCatch(error, info) {
    //console.log(error);

    this.setState({
      error: true,
      errorInfo: info,
    });

    //this.logErrorToMyService(error, info);
  }

  /**
   *
   * @param {*} error
   * @param {*} info
   */
  logErrorToMyService(error, info) {
    /* TODO */
    /* console.log("reporting");
    new RestDataSource("https://reporting.incograf.eu").storesData(
      "",
      {
        error: error,
        info: info,
      },
      (response) => {
        console.log(response);
      }
    ); */
  }

  render() {
    return this.state.hasError ? (
      <p>Something went wrong!</p>
    ) : (
      this.props.children
    );
  }
}

export default withTranslation()(ErrorBoundary);
