import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

//import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { Configs } from "../Toolbox";

const LanguageSelector = (props) => {
  const { i18n } = useTranslation();
  const [openStats, setOpenStats] = useState(true);
  const openStatClass = "ls-opened";

  /**
   * @description
   * Equivalente ao componentDidMount em objectos / class
   * Define o idioma default da App no ficheiro App.js, funciona
   * ao jeito Lift State Up
   */
  useEffect(() => {
    //   props.lang(i18n.language);
    //setOpenStats(false);
  });

  const changeLanguage = (e) => {

    const target =
      e.target.nodeName === "LI" ? e.target : e.target.closest("li");
    const selLang = target.innerText.toLowerCase();
    const activeSiblings = target.parentNode.children;
    i18n.changeLanguage(selLang);

    window.sessionStorage.setItem("defualtLang", selLang);

    let pathArray = window.location.pathname.substring(1).split('/');
    pathArray[0]=selLang;
    pathArray = "/" + pathArray.join('/');

    //window.sessionStorage.removeItem("stickyBFM");
    window.location.href = pathArray;
    for (let i = 0; i < activeSiblings.length; i++) {
      activeSiblings[i].classList.remove("active");

      if (activeSiblings[i].innerText.toLowerCase() === selLang) {
        activeSiblings[i].classList.add("active");

      }
    }
  };

  const setLanguages = () => {
    let langs = null,
      count = 0;
    const { lang } = Configs;

    langs = lang.map((val, key) => {
      let cName =
        i18n.language.toLowerCase() === val.toLowerCase() ? "active" : "";
      return (
        <li className={cName} key={count++} onClick={changeLanguage}>
          {val}
        </li>
      );
    });

    return langs;
  };

  const handleSelLangClick = (e) => {
    setOpenStats(!openStats);
  };

  return (
    <div
      className={`lang ${openStats === true ? "" : openStatClass}`}
      onClick={handleSelLangClick}
    >
      <span>
        {i18n.language}
        {/*<FontAwesomeIcon icon="chevron-down" size="xs" />*/}
      </span>
      {/*this.state.lang*/}
      <ul className={`lang-selector`}>{setLanguages()}</ul>
    </div>
  );
};

export default LanguageSelector;

/**
 * <div onChange={changeLanguage}>
      

      {/*  
    Configs -lang --> Carousel
      <input type="radio" value="pt" name="language" defaultChecked /> Português
      <input type="radio" value="en" name="language" /> English
      <input type="radio" value="zh-hk" name="language" /> Traditional Chinese }
      </div>
 * 
 */
