import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";

/* import { Configs } from "../Toolbox"; */

class ShareSocialMedia extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: null,
      data: null,
      shareUrl: null,
      nameShare: null,
    };

    this.t = props.t; //tradutor
    this.i18n = null;
  }

  componentDidMount() {
    this.t = this.props.t; //para utilização nas ações de tradução
    this.i18n = this.props.i18n;

    let { lang, nameShare } = this.state;

    lang = this.i18n.language;
    /* data = this.props.pData ? this.props.pData : null; */

   // shareUrl = this.props.shareUrl ? this.props.shareUrl : null;
    nameShare = this.props.nameShare ? this.props.nameShare : null;
    this.setState({
      lang,
      nameShare,
    });
  }
  /**
   *
   * @param {*} prevProps
   * @param {*} prevState
   */
  componentDidUpdate(prevProps, prevState) {
    let { lang, nameShare } = this.state;
    lang = this.i18n.language;

    /*  // novo idioma seleccionado, actualiza-o
    if (lang !== this.i18n.language) {
      lang = this.i18n.language;

      this.setState({ shareUrl });
    } */

    /* if (
      (shareUrl === null || Object.keys(shareUrl).length === 0) &&
      "shareUrl" in this.props
    ) {
      this.setState({ lang });
    } */
  }

  makeSocialMedia = () => {
    /* let shareUrl = Configs.base_url + this.props.match.url.substring(1); */
    /* let shareUrl= null; */
    let { nameShare } = this.state;
    var shareUrl = window.location.href;

    return (
      <div className="ShareSocialMedia">
        <p>{`${nameShare} `}</p>
        <div className="separator"></div>
       <a href={"https://www.facebook.com/sharer/sharer.php?u=" + shareUrl} rel="nofollow noopener" target="_blank">
          <span className="facebook">
            <i className="fab fa-facebook-f"></i>
          </span>
        </a>
        <a href={"https://www.addtoany.com/add_to/linkedin?linkurl=" + shareUrl} rel="nofollow noopener"  target="_blank">
         <span className="linkedin">
           <i className="fab fa-linkedin-in"></i>
         </span>
        </a>
      </div>
    );
  };

  render() {
    return <>{this.makeSocialMedia()}</>;
  }
}

export default withTranslation()(ShareSocialMedia);
