import React, { Component } from "react";
import /* BrowserRouter as Router,
  Switch, */
/*NavLink,
    Link,
    NavLink,
    Redirect,
    useParams, */
"react-router-dom";
import { withTranslation } from "react-i18next";

import RestDataSource from "../../components/webservices/RestDataSource";

import GridCards from "../GridCards";
import "../../scss/GridCards.scss";

import "../../scss/views/ProjectsView.scss";

import {
  splitFilterUrl,
  extractSomeComponentsFromURISearch,
} from "../../../src/Toolbox";

/**
 * @description
 * Tratamento das acções desenvolvidas na VIEW relacionada com
 * PROJECTOS, seja lista de projectos seja PROJECTO DETALHE
 */
class ProjectsView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lang: null,
      data: null,
      filter: null,
      selectFilter: null,
      expr2Search: null,
    };

    this.URIsearchComponents = null; // para armazenamento do componentes SEARCH do URI

    this.t = props.t; // para efeitos de tradução
    this.i18n = props.i18n; // para efeitos de tradução
    this.logicLatch = false; // TRINCO LÓGICO - FALSE aberto | TRUE fechado

    this.optionId = undefined; // ID da CATEGORIA dos artigos
    this.optionSlug = undefined; // respectivo slug

    //verifica se o match existe e retira a origem da VIEW
    if (
      this.props.match &&
      this.props.match.params &&
      "optionId" in this.props.match.params &&
      "optionSlug" in this.props.match.params
    ) {
      this.optionId = this.props.match.params.optionId;
      this.optionSlug = this.props.match.params.optionSlug;
    }

    /**
     * o formato geral destes elementos é o seguinte:
     * ?filter=europa&var1=val1&var2=val2....&varN=valN
     * extrai o componentes FILTER e FID...
     *
     * -> componentes de filtro de interesse para este componente
     *    em particular o fc1, que na prática é o id da categoria
     *    a filtrar
     */
    this.filter2Find = ["filter", "fc1"];

    this.URIsearch = null;

    this.webserver = new RestDataSource(); // para comunicação com a API (REST)
    this.restURI = `rest/project/content/`; // para utilização nos pedidos de dados à API / URI do pedido
  }

  /**
   * @description
   * Para inicialização de elementos como LANG, OPTIONID, etc
   * Definição do URL / URI a usar nos pedidos à API
   */
  componentDidMount() {
    window.sessionStorage.removeItem("stickyBFM");
    let { lang, URIsearch } = this.state;
    setTimeout(() => {
      window.scrollTo(0, 44);
    }, 0);
    lang = this.i18n.language;

    // obtem componentes GET do URL...
    this.URIsearchComponents = this.props.location.search;

    // veriica se as componentes GET possuem algo que "interesse", i.e.,
    // filtros...
    if (
      this.URIsearchComponents !== null &&
      this.URIsearchComponents !== undefined
    ) {
      this.URIsearch = extractSomeComponentsFromURISearch(
        this.URIsearchComponents,
        this.filter2Find
      );
    }

    // actualiza idioma e pede dados à API
    this.setState({ lang, URIsearch });
    //this.getDataFromAPI(this.makeURI());

    var element = document.querySelector(".wrapper");
    element.scrollIntoView({
      behavior: "smooth",
    });
  }

  /**
   * @description
   * Actualiza dados resultantes de determinada acção sobre a APP
   * acção q carece de UPDATE de informação ao USER
   *
   * @param {*} prevProps
   * @param {*} prevState
   */
  componentDidUpdate(prevProps, prevState) {
    let { lang } = prevState;
    //let { URIsearch } = this.state;

    let requestURI = this.makeURI(); // `${this.i18n.language}/${this.restURI}/${this.optionId}`;

    // obtem componentes GET do URL...
    this.URIsearchComponents = this.props.location.search;

    // verifica se existe componente URIsearch para efeitos de filtro
    if (this.URIsearch !== null) {
      requestURI +=
        this.URIsearch.fc1 !== undefined && this.URIsearch.filter !== undefined
          ? `?filter=${this.URIsearch.filter}&fc1=${this.URIsearch.fc1}`
          : "";

      this.URIsearch = null;
    }

    // novo idioma seleccionado, actualiza-o
    if (lang !== this.i18n.language) {
      lang = this.i18n.language;

      this.setState({ lang }, () => {
        // idioma alterado, obtem os dados da API no novo idioma
        if (this.logicLatch === false) {
          // fecha o trinco para efeitos de acesso ao pedido de
          // novos conteúdos à API
          this.logicLatch = true;

          // executa pedido
          this.getDataFromAPI(requestURI);
        }
      });
    }

    let { selectFilter, expr2Search } = prevState;

    /**
     * Qdo se verifica um evento de clique sobre um dos elementos do filtro
     * este actualiza este componente que por sua vez deverá provocar novo pedido
     * à API, em alternativa, caso a info. esteja toda do lado cliente, esconder os
     * elementos que não fazem parte do filtro...
     *
     * Neste caso são provocados dois pedidos, ou se filtra de acordo com os conteúdos
     * da STATE var ou se recorre aos trinco lógicos para evitar pedidos desnecessários
     * sobre a API
     */
    if (
      (selectFilter === null ||
        this.props.selFilter.toLowerCase() !== selectFilter.toLowerCase()) &&
      this.props.selFilter !== null &&
      this.props.selFilter !== undefined &&
      this.state.selectFilter !== this.props.selFilter
    ) {
      selectFilter = this.props.selFilter;

      this.setState({ selectFilter }, () => {
        /**
         * O formato do FILTRO passa por
         * https://<dominio>/<lg>/<view>/fc....
         * extrai a componente fc.... do filtro
         **/

        requestURI += splitFilterUrl(selectFilter, requestURI);

        // constroi o componente SEARCH se ele existir
        if (
          expr2Search !== undefined &&
          expr2Search !== null &&
          expr2Search.length > 0
        ) {
          requestURI += "/ffs" + expr2Search;
        }

        this.getDataFromAPI(requestURI, true);
      });
    }

    //component expr2Search
    if (
      (expr2Search === null ||
        this.props.searchExpr.toLowerCase() !== expr2Search.toLowerCase()) &&
      this.props.sesearchExprlFilter !== null &&
      this.props.searchExpr !== undefined &&
      this.state.expr2Search !== this.props.selFilter
    ) {
      expr2Search = this.props.searchExpr;
    }
  }

  makeURI() {
    let requestURI = `${this.i18n.language}/${this.restURI}`;

    // verifica se existe optionId e optionSlug e acrescenta-os ao URI
    if (this.optionId !== undefined && this.optionSlug !== undefined) {
      requestURI += `${this.optionId}/${this.optionSlug}`;
    }

    return requestURI;
  }

  /**
   * @description
   * Para obter dados da API
   *
   * @param {*} requestURL
   */
  getDataFromAPI = (requestURL, filtered = false) => {
    this.webserver.getData(requestURL, (result, xhr) => {
      this.logicLatch = false;

      if (xhr.status === 200 && "data" in xhr && typeof xhr.data === "object") {
        // set HEADER
        let headerData = [];


        if ("header" in result && !filtered) {

          //console.log(result);
          //let headerData = [];
          let { header } = result;
          headerData["cat_universo"] = this.t("menu").projects[0];
          headerData["subcat_universo"] = header.title;
          headerData["titulo_universo"] = header.subTitle;
          headerData["description_universo"] = header.description;
        }

        let { data, filter, search } = this.state;

        // set PROJS
        if ("projectos" in result) {
          const { projectos } = result;
          data = projectos;
          //this.setState({ data });
        }

        // set filter
        if ("filterOptions" in result && !filtered) {
          //let { filter } = this.state;
          const { filterOptions } = result;
          filter = filterOptions.map((val) => {
            return {
              id: val.id,
              text: val.title,
              slug: val.slug,
              uri: `${this.i18n.language}/${this.t("menu").projects[1]}/fc1${
                val.id
              }`,
              /* onClick: (e) => {
                const target = e.target;
                //e.preventDefault();
              }, */
            };
          });
        }

        // set search ...
        /***
         * Para activação da pesquisa ao accionar a tecla ENTER no SEARCH
         */
        if (!filtered) {
          search = {
            onKeyDown: (e) => {
              let requestURI = this.makeURI();

              if (e.key === "Enter") {
                let { selectFilter, expr2Search } = this.state;

                expr2Search = e.target.value;

                if (selectFilter !== null) {
                  let fcFilter = selectFilter.split("/").filter((val) => {
                    return val.indexOf("fc") >= 0;
                  });

                  if (Array.isArray(fcFilter) && fcFilter.length === 1) {
                    requestURI += `${fcFilter[0]}/`;
                  }
                }

                // console.log(expr2Search);
                // constroi o componente SEARCH se ele existir
                if (
                  expr2Search !== undefined &&
                  expr2Search !== null &&
                  expr2Search.length > 0
                ) {
                  requestURI += "ffs" + expr2Search;
                }

                this.getDataFromAPI(requestURI, true);
              }
            },
          };
        }

        if (Array.isArray(filter) && !filtered) {
          filter.unshift({
            id: -1,
            text: this.t("all"),
            uri: `${this.i18n.language}/${this.t("menu").projects[1]}`,
          });
        }

        if (!filtered) {
          this.props.setParentData({ headerData, filter, search });
          this.setState({ data, filter });
        } else {
          this.setState({ data });
        }
      }
    });
  };

  makeProjectList = () => {
    if (this.state.data !== null)
      return this.state.data.all.map((val) => {
        return <p key={val.id}> {val.title} | </p>;
      });
  };

  makeProjectUnit = () => {
    return <div>PROJECTOS DETALHE</div>;
    //  return <ProjectDetailView></ProjectDetailView>;
  };

  render() {
    let style = "grid_news col-10 col-md-6 col-lg-6";

    return (
      <>
        {this.optionId !== undefined && this.optionSlug !== undefined ? (
          this.makeProjectUnit()
        ) : (
          <GridCards
            pData={this.state.data}
            cardStyle={style}
            noDate={true}
            noSubTitle={true}
            cardUrlDetail={this.t("menu").projects[1]}
          />
        )}
      </>
    );
  }
}

export default withTranslation()(ProjectsView);
