import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import $ from "jquery";
/*
import ReactHtmlParser from "react-html-parser";
import HtmlParser from "react-html-parser";


import {
  extractElementsFromArrayByIsType,
  insertMediaWithAppAbsPath,
} from "../../../Toolbox";*/

import "../../../../src/scss/AboutNews.scss";

import GridCards from "../../GridCards";
import "../../../scss/GridCards.scss";

/* import { valHooks } from "jquery";
import NewsDetailView from "../NewsDetailView"; */

import RestDataSource from "../../../components/webservices/RestDataSource";
import { ListFilter } from "../../FiltersAndOrders";

/**
 * AKA SOMOS PRILUX
 */
class AboutNews extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lang: null,
      data: {},
      /** **/
      filterData: null,
      filter: null, // componentes de filtro
      selectFilter: null, // qual o elemento do filtro clicado
      selectOrder: "otidesc", // qual o ordenador seleccionado
      /** **/
    };

    this.t = props.t; //tradutor
    this.i18n = null;

    this.logicLatch = false; // TRINCO LÓGICO - FALSE aberto | TRUE fechado

    if (
      this.props.match &&
      this.props.match.params &&
      "optionId" in this.props.match.params &&
      "optionSlug" in this.props.match.params
    ) {
      this.optionId = this.props.match.params.optionId;
      this.optionSlug = this.props.match.params.optionSlug;
    }

    this.webserver = null; // para comunicação com a API (REST)
    // ATENTION to 222
    this.restURI = `rest/about/content/222`; // para utilização nos pedidos de dados à API / URI do pedido

    this.getData = null;
  }

  /**
   * @description
   * Para inicialização de elementos como LANG, OPTIONID, etc
   * Definição do URL / URI a usar nos pedidos à API
   */
  componentDidMount() {
    window.sessionStorage.removeItem("stickyBFM");
    this.t = this.props.t; //para utilização nas ações de tradução
    this.i18n = this.props.i18n;

    let { lang, data } = this.state;

    lang = this.i18n.language;
    data = this.props.pData ? this.props.pData : null;

    this.webserver = this.props.webserver ? this.props.webserver : null;

    this.setState({ lang, data });

    let filter = document.getElementsByClassName("filter");

    if (filter.length > 0) {
      filter = filter[0];
      filter.classList.add("col-lg-10");
    }

    let order = document.getElementsByClassName("order");

    if (order.length > 0) {
      order = order[0];
      order.classList.add("col-lg-2");
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let { lang, data, filterData, filter } = this.state;
    let requestURI = this.makeURI();

    //this.getData = this.props.fData ? this.props.fData : null;

    if (
      "pData" in this.props &&
      "content" in this.props.pData /*data === null ||*/ &&
      JSON.stringify(this.props.pData.content.all) !==
        JSON.stringify(data.all) &&
      Object.keys(this.props.pData).length > 0
    ) {
      lang = this.i18n.language;

      data =
        prevProps.pData.content !== this.props.pData.content
          ? this.props.pData.content
          : data;

      this.setState({ lang, data });
    }

    if (
      ("fData" in this.props &&
        this.props.fData !== null &&
        filterData === null) ||
      JSON.stringify(this.props.fData) !== JSON.stringify(filterData)
    ) {
      // recolhe os dados para construir os componentes do filtro
      filterData = this.props.fData;
      // constroi os elementos do filtro
      filter = filterData.map((val) => {
        return {
          id: val.id,
          text: val.title,
          uri: `${this.i18n.language}/${this.t("menu").news[1]}/fc1${val.id}`,
          onClick: (e) => {
            this.dealWithFilterClick(e);
          },
        };
      });

      // define o elemento 0 (TODOS) do filtro
      if (Array.isArray(filter) /*&& !filtered*/) {
        filter.unshift({
          id: -1,
          text: this.t("all"),
          uri: `${this.i18n.language}/${this.t("menu").news[1]}`,
          onClick: (e) => {
            this.dealWithFilterClick(e);
          },
        });
      }

      this.setState({ filterData, filter });
    }

    //selectFilter: null
    let { selectOrder } = prevState;

    /**
     * o valore do ordenador foi alterado, solicitar novos dados
     * JUNTO de servidor com os componentes FILTRO e ORDER
     */
    if (selectOrder !== this.state.selectOrder) {
      this.updateNewsList();
    }
  }

  /**
   * @description
   * Tratamento das acções resultantes no evento de click no filtro
   *
   * @param {*} e
   */
  dealWithFilterClick = (e) => {
    e.preventDefault();

    const target = e.target;
    let { selectFilter } = this.state;

    // actualiza a STATE var para o elemento seleccionado
    selectFilter = target.href;
    this.setState({ selectFilter }, () => {
      // concluído o processo de actualização da STATE var para
      // o elemento clicado, trata de actualizar a lista de notícias
      this.updateNewsList();
    });
    $(".flt-container .active").removeClass("active");
    // retira a class active de um elemento que a possua
    let ulCont = target.closest("ul");
    let active = ulCont.getElementsByClassName("active");

    if (active.length > 0) {
      active[0].classList.remove("active");
    }

    // define como activo o elemento clicado
    target.classList.add("active");
  };

  /**
   * @description
   * Trata do processo de actualização das listas de notícias
   * após acção do utilizador sobre o filtro e/ou o ordenador
   */
  updateNewsList = () => {
    let requestURI = this.makeURI();

    // obtem e trata o valor seleccionado no filtro para a execução do
    // respectivo pedido ao servidor, assim como o respctivo valor do
    // ordandor seleccionado
    let { selectFilter, selectOrder } = this.state;

    // o pedido é executado no formato:
    // https://<domninio>/<lg>/<view>/fc1<int>
    // isto para o filtro

    let fcFilter =
      selectFilter !== null
        ? selectFilter.split("/").filter((val) => {
            return val.indexOf("fc") >= 0;
          })
        : null;

    // adiciona componente filtro ao URI
    if (Array.isArray(fcFilter) && fcFilter.length === 1) {
      requestURI += `${fcFilter[0]}/`;
    }

    // para o ordenador, o formato passa a ser:
    // https://<domninio>/<lg>/<view>/oti<asc|desc>
    // onde ti = TIME
    if (selectOrder !== undefined && selectOrder !== null) {
      if (requestURI.charAt(requestURI.length - 1) !== "/") {
        requestURI += "/";
      }
      requestURI += selectOrder;
    }

    // se ambos os elementos possuem valor, então o formato geral do pedido
    // será algo como:
    // https://<domninio>/<lg>/<view>/fc1<int>/oti<asc|desc>
    if (this.webserver !== null) {
      this.webserver.getData(requestURI, (result, xhr) => {
        if (
          xhr.status === 200 &&
          "content" in result &&
          result.content !== undefined &&
          result.content !== null &&
          "all" in result.content &&
          result.content.all !== undefined
        ) {
          let { data } = this.state;
          data = result.content;

          /**
           * procede à actualização dos dados relacinados com as listas de
           * notícias e actualiza a lista do componente parent, sendo que
           * eventualmente poderia funcionar apenas com uma das "limpezas".
           * conceito a rever para outras ocasiões...
           */
          this.setState({ data }, () => {
            if (this.props.setParentData) {
              this.props.setParentData({ data });
            }
          });
        }
      });
    }
  };

  /**
   * @description
   * Fabrica o URI do pedido à BD
   */
  makeURI() {
    let requestURI = `${this.i18n.language}/${this.restURI}/`;

    // verifica se existe optionId e optionSlug e acrescenta-os ao URI
    if (this.optionId !== undefined && this.optionSlug !== undefined) {
      requestURI += `${this.optionId}/${this.optionSlug}`;
    }

    return requestURI;
  }

  /**
   * @description
   * Fabrica o corpo das notícias com base nos dados armazenados na
   * STATE var
   */
  makeNews = () => {
    let news = null;

    if (this.state.data) {
      let style = "grid_news col-12 col-md-6 col-lg-4";
      let urlD = "";

      return (
        <GridCards
          pData={this.state.data}
          cardStyle={style}
          noDate={false}
          noSubTitle={false}
          cardUrlDetail={this.t("menu").news[1]}
        />
      );
    }
    return news;
  };

  /**
   * @description
   * Armazena o valor seleccionado no ordenador
   *
   * @param {*} elemData
   */
  setParentData = (elemData) => {
    this.setState(elemData);
  };

  /**
   * @description
   * Fabrica o corpo das notícias, o HEADER é fabricado no AboutVIEW
   */
  render() {
    return (
      <div className="body-content_news">
        <div className="row flt-container">
          <ListFilter
            t={this.t}
            /*srchKeyDownHandler=
             {this.state.search !== null ? this.state.search.onKeyDown : ""
            }*/
            filterOptions={this.state.filter}
            setParentData={this.setParentData}
          />
        </div>
        {this.state.data !== undefined ? this.makeNews() : null}
      </div>
    );
  }
}

export default withTranslation()(AboutNews);
