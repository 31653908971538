import React, { Component } from "react";
//import { BrowserRouter as Router, Route } from "react-router-dom";
import { withTranslation } from "react-i18next";
import ReactFileReader from "react-file-reader";

// procede ao import dos vários componentes das FORM : FormComponents.jsx
import Input, {
  Button,
  Select,
  TextArea,
  CheckInput,
  Label,
} from "../template/forms/FormComponents";

import "../scss/form/Forms.scss";

import "../scss/AppButton.scss";
import AppButton from "../template/AppButton";
import { Configs } from "../Toolbox";

/**
 * @version 0.1
 * @date 2020/07
 *
 * @description
 * (20200709)
 * Para esta versão acresce a funcionalidade de proceder ao RESET da form
 * a partir do exterior do compomnente, i.e., existe uma prop denominada fReset
 * que ao ser fornecida a TRUE procede-se ao exvaziamento dos dados da FORM,
 * inseridos pelo utilizador, assim com ao RESET dos respectivos campos.
 * Este tratamento executa-se no componentDidUpDate
 *
 *
 * @version 0.0
 * @date 2020/06
 *
 * @description
 * Para tratamento de eventos e processos relacionados com formulários, como
 * por exemplo a recolha dos dados do utilizador
 *
 * O formato geral do array de objectos para o campo state.formFields é o
 * seguinte:
 *
 * [{
 *    type: "text",
 *    className: "form-control",
 *    id: "name",
 *    name: "name",
 *    "data-i18n-key": "name"},
 * {
 *    type: "email",
 *    ...
 * },
 * {
 *    type: "number",
 *    ...
 *  },
 *  {
 *    type: "select",
 *    ...
 *    offspring: [],
 *  },
 *  {
 *    type: "textarea",
 *    ...
 *    name: "remarks",
 *    rows: 3,
 *    ...
 *  },
 * ...];
 *
 * Onde:
 *  a) cada chave do array representa uma propriedade/atributo do campo
 *  b) Aos tipos text | email | number | ... correponde uma InputText os demais
 *     têm components definidos para cada caso : Ver FormComponets.jsx
 *
 * @dependencies
 * - Bootstrap
 *
 * @props
 * @param {string} id : ID da FORM a tratar
 * @param {Array} fields : array  com os campos a imprimir na FORM
 * @param {boolean} fReset : prop para o reset da FORM TRUE => reset FORM
 * @param {event} onSubmit : eventHandler para o tratamento do submit
 *
 *
 * @properties (do objecto)
 * @param {Array} state.formFields : Array de objectos que representam os vários
 *                                   campos que compõem a FORM, este array é
 *                                   disponibilizado pelo component parent, por
 *                                   exemplo ContactsView.jsx - este componentes
 *                                   estarão localizados em:
 *                                   /template/views/....jsx
 * @param {object} state.formData: Dados inseridos pelo utilizador
 */
class Forms extends Component {
  constructor(props) {
    super(props);

    /**
     * o valor de "formFields" é definido pelo conteúdo das props, verifica se
     * o mesmo existe e atribui valor de acordo com a condição
     */
    this.i18n = this.props.i18n; // para internacionalização
    this.lang = this.i18n.language;
    this.state = {
      formFields: props.fields ? props.fields : {}, // definição dos campos da FORM
      formData: {}, // para armazenamento dos dados inseridos pelo utilizador
    };
  }

  /**
   *
   * @param {*} props
   * @param {*} state
   * //a;
   */

  static getDerivedStateFromProps(props, state) {
    /**
     * TO implement
     */
    return null;
  }

  /**
   * @description
   * Lifecycle method
   */
  componentDidMount() {
    if (window.isFormsLoadOnce) {
      return;
    }
    window.isFormsLoadOnce = true;
    /**
     * TO implement
     */
    const reactFileReader = document.getElementsByClassName(
      "react-file-reader-button"
    )[0];

    let inputFileReader = document.createElement("input");
    inputFileReader.classList.add("form-control");
    inputFileReader.setAttribute("type", "readonly");
    inputFileReader.setAttribute("name", "file-reader");
    inputFileReader.setAttribute("required", "true");



    inputFileReader.oninput = function () { this.setCustomValidity(this.props.t("forms").required_field[0]); }
    inputFileReader.oninvalid = function () { this.setCustomValidity(""); }

    let labelFileReader = document.createElement("label");
    labelFileReader.innerHTML = this.props.t("forms").cv[0];

    if (reactFileReader !== undefined && reactFileReader !== null) {
      reactFileReader.prepend(labelFileReader);
      reactFileReader.prepend(inputFileReader);

      inputFileReader.oninput = function () { this.setCustomValidity("hhhhhh"); }
      inputFileReader.oninvalid = function () { this.setCustomValidity(""); }
    }
  }

  /**
   * @description
   * Lifecycle method
   *
   * (20200709)
   * Verifica a existência da PORP fReset e procede ao reset da FORM se
   * esta PROP existe e se é TRUE - para tal terá de existir uma PROP
   * ID para referenciar a FORM a aplicar o reset
   *
   *
   * @param {*} prevProps -> props anterior ao processo de UPDATE
   * @param {*} prevState -> state vars anteriores ao processo de UPDATE
   */
  componentDidUpdate(prevProps, prevState) {
    let { formData } = this.state;

    if (
      this.props.fReset !== undefined &&
      this.props.fReset === true &&
      Object.keys(this.state.formData).length > 0
    ) {
      // limpa os dados armazenados
      formData = {};
      this.setState({ formData }, () => {
        // após actualização das STATE vars procede ao RESET da FORM,
        // se o respectivo ID for cinhecido
        if (this.props.id) {
          document.getElementById(this.props.id).reset();
        }
      });
    }
  }

  /**
   * @description
   * Tratamento para o evento alteração do valor do(s) campo(s) da FORM,
   * sendo este valor armazenado no STATE - formData
   *
   * @param {*} e
   */
  handleChange = (e) => {
    let target = e.target,
      { formData } = this.state;

    // verifica que tipo de campos se trata e executa o armazenamento de
    // acordo com essa condição
    if (target.type === "radio") {
      formData[target.name] = target.checked && target.value;
    } else if (target.type === "checkbox") {
      // verifica se o elemento já existe no array de resultados
      // se não existir cria um array p armazenamento dos valores
      if (formData[target.name] === undefined) {
        formData[target.name] = [];
      }

      // verifica se o elemento clicado assume um estado CHECKED ou se
      // pelo contrário está UNCHECKED
      if (target.checked && !formData[target.name].includes(target.value)) {
        // se elemento n existe no array de elementos seleccionados
        // acrescenta o mesmo
        formData[target.name].push(target.value);
      } else {
        // elemento não está seleccionado - retira-o do array de resultados
        formData[target.name].splice(
          formData[target.name].indexOf(target.value),
          1
        );
      }
    } else {
      // armazena valores para os restantes casos de INPUT
      formData[target.name] = target.value;

      // verifica se campo é required e se não o for coloca uma class isValued
      // a assinalar essa situação - isto se tiver valor
      if (
        target.getAttribute("required") === null &&
        target.value.trim().length > 0
      ) {
        target.classList.add("isValued");
      } else if (
        // se campo n possuir valor retira a class
        target.getAttribute("required") === null &&
        target.value.trim().length === 0
      ) {
        target.classList.remove("isValued");
      }
    }

    this.setState({ formData: formData });
  };

  /**
   * @description
   * Trata o(s) ficheiro(s) inseridos pelo utilizador para submissão
   * à API
   *
   * @param {*} files
   */
  handleFiles = (files) => {
    try {
      if (typeof files === "object" && "fileList" in files) {
        let { fileList } = files;
        // neste caso aguardamos o carregamento de apenas um único ficheiro...
        // apresenta ao utilizador o nome do ficheiro seleccionado...
        let inputReader = document.getElementsByName("file-reader")[0];
        inputReader.value = fileList[0].name;
        inputReader.classList.add("isValued");

        // coloca o ficheiro para as STATEVARS juntamente com os restantes
        // dados da FORM
        let { formData } = this.state;
        formData["file-reader"] = files;
        this.setState({ formData });
      } else {
        throw this.props.t("errorHandler").fileLoadError;
      }
    } catch (error) {
      console.error(`[FileUpload]: ${error}`);
    }
  };

  /**
   * @description
   * Fabrica os componentes da FORM de acordo com mos objectos que estão incluídos
   * no ARRAY de objectos definidos na STATE var
   */
  makeFields = () => {
    const parent = this; // referência objecto base (parent) para utiliazção no array map
    let fields = ""; // para armazenamento dos componentes após fabrido dos mesmos

    // se existe conteúdo na state var para os campos da FORM fabrica-o
    if (this.state.formFields && Array.isArray(this.state.formFields)) {
      fields = this.state.formFields.map((field, key) => {
        let res = null;

        //let _fieldid = field.id;

        // adiciona o evento handler ao component se exte não estiver definido
        // caso contrário prossegue com o evento definido, qdo da inicialização,
        // do objecto / array, e disponibilizado, por argumento
        if (field.onChange === undefined || field.onChange === null) {
          field = Object.assign(field, { onChange: parent.handleChange });
        }

        // verifica o tipo de component e incorpora-o na FORM de acordo com o mesmo
        /**
         * Eventualmente esta secção poderá ser alvo de refactoring suprimindo a
         * existência do switch -> para nova versão
         */
        let cKey = field.name + key,
          cName = "form-group "; // valor default para as class do container dos campos

        if (field.type) {
          switch (field.type.trim()) {
            case "file": // dropdown
              res = (
                <div key={cKey} className={cName}>
                  <ReactFileReader
                    handleFiles={this.handleFiles}
                    fileTypes={[".pdf", ".doc", ".docx", ".odt", ".zip"]}
                    base64={true}
                    multipleFiles={false}
                  >
                    <button className="btn btn-file-reader"> Upload </button>
                  </ReactFileReader>
                </div>
              );
              break;
            case "select": // dropdown
              res = (
                <div key={cKey} className={cName}>
                  <Select
                    props={field}
                    t={parent.props.t}
                    onChange={parent.handleChange}
                  ></Select>
                </div>
              );
              break;
            case "textarea":
              res = (
                <div key={cKey} className={cName}>
                  <TextArea
                    props={field}
                    t={parent.props.t}
                    onChange={parent.handleChange}
                  />
                </div>
              );
              break;
            case "radio":
            case "checkbox":
              if (field.offspring) {
                res = field.offspring.map((val, k) => {
                  // executa um "clone" do props p adicionar um atributo value
                  // ao RADIO e retirar o offspring p que este n seja colocado
                  // no elemento como atributo do mesmo
                  let _field = Object.assign({ value: val.value }, field);
                  delete _field.offspring;

                  return (
                    <div key={field.name + k} className="form-check">
                      <div className="checkbox-container">
                        <label className="checkbox-label">
                          <CheckInput
                            id={val.id}
                            props={_field}
                            t={parent.props.t}
                            onChange={parent.handleChange}
                          />
                          <span className="checkbox-custom rectangular"> </span>
                        </label>
                        <label
                          key="1"
                          htmlFor={val.id}
                          className="form-check-label"
                        >
                          {val.i18n !== undefined ? (
                            this.props.t(val.i18n)
                          ) : (
                            <a
                              href={
                                Configs.base_url +
                                this.lang +
                                "/" +
                                this.props.t("menu").privacy[1]
                              }
                              target="_blank" rel="noopener noreferrer"
                            >
                              {val.i18n
                                ? this.props.t(val.i18n)
                                : val.text
                                ? val.text
                                : ""}{" "}
                            </a>
                          )}
                        </label>
                      </div>
                    </div>
                  );
                });

                // incluir o título do campo se este existir
                if (field["data-i18n-key"]) {
                  res = [
                    <Label
                      key={field.name}
                      htmlFor={field.name}
                      i18nKey={field["data-i18n-key"]}
                      t={this.props.t}
                    />,
                    res,
                  ];
                }
              }
              break;
            default:
              res = (
                <div key={cKey} className={cName}>
                  <Input
                    props={field}
                    t={parent.props.t}
                    onChange={parent.handleChange}
                  />
                </div>
              );
          }
        }

        // devolve o camponent fabricado
        return res;
      });
    }

    // devolve todos os components que compõem a FORM
    return fields;
  };

  /**
   *
   * @param {*} e
   */
  handleResetForm = (e) => {
    e.stopPropagation();

    const target = e.target;
    const parentForm = target.closest("form");
    let isValuedElements = parentForm.getElementsByClassName("isValued");

    // obtem todos os campos do tipo INPUT (text / number) e TEXT AREA
    // e remove a class isValued
    for (let i = 0; i < isValuedElements.length; i++) {
      isValuedElements[i].classList.remove("isValued");
    }

    // ------------------------------------------ MUDA REFERENCIA POR DEPART
    let jobRefInput = document.getElementById("jobref");
    let depart = document.getElementsByName("department")[0];

    if (depart !== undefined) {
      depart.closest(".form-group").classList.remove("d-none");
      jobRefInput.closest(".form-group").classList.add("d-none");
    }
  };

  /**
   * @returns {form | null}
   */
  render() {
    /**
     * define os conteúdos, i.e. a FORM a usar de acordo com o URL
     *
     * NOTA : como alternativa a este modelo poder-se-à recorrer à
     *        renderização condicional, i.e., indicar a form a usar
     *        no momento em que se invoca este component
     *
     * Verifica se os campos a usar na form estão definidos, se não estiverem
     * devolve null
     */
    if (this.state.formFields) {
      return (
        <form
          autoComplete="off"
          id={this.props.id}
          onSubmit={(e) => this.props.onSubmit(e, this.state.formData)}
          onReset={this.handleResetForm}
          encType={this.props.enctype !== undefined ? this.props.enctype : ""}
        >
          {
            /* corpo da FORM*/
            this.makeFields()
          }
          <Button type="reset" className="btn_reset">
            <AppButton
              type="reset"
              className=""
              text={this.props.t("forms").titles.reset}
            />
          </Button>
          <Button type="submit" className="btn_submit">
            <AppButton
              type="submit"
              className=""
              text={this.props.t("forms").titles.submit}
            />
          </Button>
          <img
            alt="loader"
            id="formLoader"
            className="loader"
            src="/assets/imgs/a-loader-2.gif"
          />
        </form>
      );
    }

    return null;
  }
}

export default withTranslation()(Forms);
