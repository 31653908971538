import React from "react";

/**
 * @description
 * Inputs do tipo:
 * - text | email | hidden | password |
 *
 *
 * @param {*} props.className
 */
const Input = ({ props, t }) => {
  const i18nKey =
    props !== undefined && props["data-i18n-key"] !== undefined
      ? props["data-i18n-key"]
      : undefined;

    return (
    <>
      <input
        {...props}
        onInvalid={function (e) {e.target.setCustomValidity(t("forms").required_field[0]) }}
        onInput={function (e){ e.target.setCustomValidity('')}}
        placeholder={
          t("forms")[i18nKey] !== undefined ? t("forms")[i18nKey][1] : ""
        }
      />
      <Label
        htmlFor={props.id}
        i18nKey={props["data-i18n-key"] ? props["data-i18n-key"] : null}
        t={t}
      />
    </>
  );
};

export default Input;

export const CheckInput = ({ id, props, t }) => {
    const i18nKey =
    props !== undefined && props["data-i18n-key"] !== undefined
      ? props["data-i18n-key"]
      : undefined;

  return <input id={id}
                {...props}
                onInvalid={function (e) {e.target.setCustomValidity(t("forms").required_field[0]) }}
                onInput={function (e){ e.target.setCustomValidity('')}} />;
};

/**
 *
 * @param {*} param0
 */
export const TextArea = ({ props, t }) => {
  const i18nKey =
    props !== undefined && props["data-i18n-key"] !== undefined
      ? props["data-i18n-key"]
      : undefined;

  return (
    <>
      <textarea
        {...props}
        onInvalid={function (e) {e.target.setCustomValidity(t("forms").required_field[0]) }}
        onInput={function (e){ e.target.setCustomValidity('')}}
        placeholder={
          t("forms")[i18nKey] !== undefined ? t("forms")[i18nKey][1] : ""
        }
      ></textarea>
      <Label
        htmlFor={props.name}
        i18nKey={props["data-i18n-key"] ? props["data-i18n-key"] : null}
        t={t}
      />
    </>
  );
};

/**
 *
 * @param {*} param0
 */
export const Select = ({ props, t }) => {
  return (
    <>
      <select
        name={props.name}
        className={props.className}
        onChange={props.onChange}
      >
        <option></option>
        {props.offspring &&
          props.offspring.map((opt, key) => {
            return (
              <option key={opt.id} value={opt.id}>
                {opt.text}
              </option>
            );
          })}
      </select>
      <Label htmlFor={props.name} i18nKey={props["data-i18n-key"]} t={t} />
    </>
  );
};

/**
 *
 * @param {*} props
 */
export const Button = (props) => {
  return (
    <button type={props.type} className={props.className}>
      {props.children}
    </button>
  );
};

/**
 *
 * @param {*} param0
 */
export const Label = ({ htmlFor, i18nKey, t }) => {
  return (
    <label htmlFor={htmlFor ? htmlFor : ""}>
      {t("forms")[i18nKey] && t("forms")[i18nKey][0]
        ? t("forms")[i18nKey][0]
        : ""}
    </label>
  );
};
