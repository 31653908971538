import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import {
  extractElementsFromArrayByIsType,
  insertMediaWithAppAbsPath,
} from "../Toolbox";

import "../../src/scss/GridCards.scss";

/**
 *
 */
class GridCards extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lang: null,
      data: null,
      cardStyle: "",
      cardInfo: "",
      cardTitle: "",
      cardUrlDetail: "",
    };

    this.t = props.t; //tradutor
    this.i18n = null;
  }

  /**
   *
   */
  componentDidMount() {
    this.t = this.props.t; //para utilização nas ações de tradução
    this.i18n = this.props.i18n;

    let {
      lang,
      data,
      cardStyle,
      cardInfo,
      cardTitle,
      cardUrlDetail,
    } = this.state;

    lang = this.i18n.language;
    data = this.props.pData ? this.props.pData : null;
    cardStyle = this.props.cardStyle ? this.props.cardStyle : "";
    cardInfo = this.props.cardInfo ? this.props.cardInfo : "";
    cardTitle = this.props.cardTitle ? this.props.cardTitle : "";
    cardUrlDetail = this.props.cardUrlDetail ? this.props.cardUrlDetail : "";

    this.setState({
      lang,
      data,
      cardStyle,
      cardInfo,
      cardTitle,
      cardUrlDetail,
    });
  }

  /**
   *
   * @param {*} prevProps
   * @param {*} prevState
   */
  componentDidUpdate(prevProps, prevState) {
    let { lang, data } = this.state;

    if (
      ((data === null || Object.keys(data).length === 0) &&
        "pData" in this.props &&
        this.props.pData !== null &&
        Object.keys(this.props.pData).length > 0) ||
      JSON.stringify(this.props.pData) !== JSON.stringify(prevState.data)
    ) {
      data = this.props.pData;
      this.setState({ data });
    }
  }

  makeGrid = () => {
    const { data, cardStyle, cardUrlDetail } = this.state;
    let result = null;

    if (data !== null && "all" in data && data.all !== null) {
      result = data.all.map((val) => {
        let cardInfo =
          val.category !== null && val.category !== undefined
            ? val.category[0].title
            : "";

        cardInfo +=
          this.props.noDate !== null &&
          this.props.noDate !== undefined &&
          this.props.noDate === false
            ? " - " + val.time.slice(0, 10)
            : "";

        let cardTitle =
          val.title !== null && val.title !== undefined ? val.title : "";

        cardTitle +=
          this.props.noSubTitle !== null &&
          this.props.noSubTitle !== undefined &&
          this.props.noSubTitle === true
            ? " " + val.subTitle
            : "";

        // obtem imagem do banner
        let img = extractElementsFromArrayByIsType(val.imgs, "generic");

        // valida resultado obtido e gera o link absoluto da imagem
        img =
          img !== null ? insertMediaWithAppAbsPath(img[0].name, "imgs") : null;
        // obtem alt para imagem para efeitos de SEO

        return (
          <div key={val.id} className={`${cardStyle} `}>
            <div className="card ">
              <a
                className="anchor-img"
                href={`/${this.i18n.language}/${cardUrlDetail}/${val.id}/${val.slug}`}
              >
                <div
                  className="card-img-top"
                  style={{
                    backgroundImage: "url(" + img + ")",
                  }}
                ></div>
              </a>
              <a
                  href={`/${this.i18n.language}/${cardUrlDetail}/${val.id}/${val.slug}`}
              >
              <div className="card-body">
                <p className="card-text">{`${cardInfo} `}</p>
                <p className="card-text title">{`${cardTitle} `}</p>
              </div></a>
            </div>
          </div>
        );
      });
    }

    return result;
  };

  render() {
    return (
      <div className="all">
        {this.state.data !== null &&
        "all" in this.state.data &&
        this.state.data.all !== null
          ? this.makeGrid()
          : null}
        <div
          className={`col-12 noresults ${
            this.state.data &&
            "all" in this.state.data &&
            Array.isArray(this.state.data.all) &&
            this.state.data.all.length > 0
              ? "d-none"
              : ""
          }`}
        >
          {this.t("noResults")}
        </div>
      </div>
    );

    /** Corpo da grid para os cards **/

    //this.props.children
  }
}

export default withTranslation()(GridCards);
