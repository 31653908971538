import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import RestDataSource from "../../components/webservices/RestDataSource";

import "../../scss/views/DownloadsView.scss";

//DownloadsView
import { insertMediaWithAppAbsPath } from "../../Toolbox";

/**
 * @description
 * Apresentação de conteúdo relacionados com eventual VIEW Sobre Nós // About...
 *
 * @param {object} texts -> p armazenamento dos textos estáticos a apresentar na view
 */
class DownloadsView extends Component {
  //const { t } = useTranslation(); // p utilização de tradução
  //const [texts, setTexts] = useState({}); // p armazenamento de STATE vars

  constructor(props) {
    super(props);

    this.state = {
      lang: null,
      data: {},
      filter: null,
      selectFilter: null,
      expr2Search: null,
    };

    this.t = props.t; // para efeitos de tradução
    this.i18n = props.i18n; // para efeitos de tradução
    this.logicLatch = false; // TRINCO LÓGICO - FALSE aberto | TRUE fechado

    this.optionId = undefined; // ID da CATEGORIA dos artigos
    this.optionSlug = undefined; // respectivo slug

    //verifica se o match existe e retira a origem da VIEW
    if (
      this.props.match &&
      this.props.match.params &&
      "optionId" in this.props.match.params &&
      "optionSlug" in this.props.match.params
    ) {
      this.optionId = this.props.match.params.optionId;
      this.optionSlug = this.props.match.params.optionSlug;
    }

    this.webserver = new RestDataSource(); //para a comunicação com a API(REST)
    this.restURI = `rest/documents/content/`; //para utilização nos pedidos de dados à API / URI do pedido
  }

  /**
   * Hook equivalente ao componentDidMount
   */

  componentDidMount() {
    window.sessionStorage.removeItem("stickyBFM");
    let { lang } = this.state;
    lang = this.i18n.language;

    //atualiza idioma e pede dados à API
    this.setState({ lang });

    var element = document.querySelector(".wrapper");
    element.scrollIntoView({
      behavior: "smooth",
    });
  }

  /**
   * @description
   * Actualiza dados resultantes de determinada acção sobre a APP
   * acção q carece de UPDATE de informação ao USER
   *
   * @param {*} prevProps
   * @param {*} prevState
   */
  componentDidUpdate(prevProps, prevState) {
    let { lang } = prevState;

    //url -->  `${this.i18n.language}/${this.restURI}/${this.optionId}`
    let requestURI = this.makeURI();

    // novo idioma seleccionado, actualiza-o
    if (lang !== this.i18n.language) {
      lang = this.i18n.language;

      this.setState({ lang }, () => {
        // idioma alterado, obtem os dados da API no novo idioma
        if (this.logicLatch === false) {
          // fecha o trinco para efeitos de acesso ao pedido de
          // novos conteúdos à API
          this.logicLatch = true;
          // executa pedido
          this.getDataFromAPI(this.makeURI());
        }
      });
    }

    //expr2Search: null
    //selectFilter: null

    let { selectFilter, expr2Search } = prevState;

    /**
     *
     *  selectFilter
     *
     *
     * Qdo se verifica um evento de clique sobre um dos elementos do filtro
     * este actualiza o componente que por sua vez deverá provocar novo pedido
     * à API, em alternativa, caso a info. esteja toda do lado cliente, esconder os
     * elementos que não fazem parte do filtro...
     *
     * Neste caso são provocados dois pedidos, ou se filtra de acordo com os conteúdos
     * da STATE var ou se recorre aos trinco lógicos para evitar pedidos desnecessários
     * sobre a API
     */
    if (
      (selectFilter === null ||
        this.props.selFilter.toLowerCase() !== selectFilter.toLowerCase()) &&
      this.props.selFilter !== null &&
      this.props.selFilter !== undefined &&
      this.state.selectFilter !== this.props.selFilter
    ) {
      selectFilter = this.props.selFilter;

      this.setState({ selectFilter }, () => {
        /**
         * O formato do FILTRO passa por
         * https://<domninio>/<lg>/<view>/fc....
         * extrai a componente fc.... do filtro
         */

        let fcFilter = selectFilter.split("/").filter((val) => {
          return val.indexOf("fc") >= 0;
        });

        if (Array.isArray(fcFilter) && fcFilter.length === 1) {
          requestURI += `${fcFilter[0]}`;
        }

        //constroi o componente SEARCH se ele existir
        if (
          expr2Search !== undefined &&
          expr2Search !== null &&
          expr2Search.length > 0
        ) {
          requestURI += "/ffs" + expr2Search;
        }

        this.getDataFromAPI(requestURI, true);
      });
    }

    //component expr2Search
    if (
      (expr2Search === null ||
        this.props.searchExpr.toLowerCase() !== expr2Search.toLowerCase()) &&
      this.props.sesearchExprlFilter !== null &&
      this.props.searchExpr !== undefined &&
      this.state.expr2Search !== this.props.selFilter
    ) {
      expr2Search = this.props.searchExpr;
    }
  }

  makeURI() {
    let requestURI = `${this.i18n.language}/${this.restURI}`;

    //verifica se existe optionId e optionSlug e acrescenta-os ao URI
    if (this.optionId !== undefined && this.optionSlug !== undefined) {
      requestURI += `${this.optionId}/${this.optionSlug}`;
    }

    return requestURI;
  }

  /**
   * @description
   * Para obter dados da API
   *
   * @param {*} requestURL
   */

  getDataFromAPI = (requestURL, filtered = false) => {
    this.webserver.getData(requestURL, (result, xhr) => {
      this.logicLatch = false;

      if (xhr.status === 200 && "data" in xhr && typeof xhr.data === "object") {
        /*Faz um scroll para o início da página caso
         * estejamos a carregar no link localizado no footer
         */
        var element = document.querySelector(".wrapper");
        element.scrollTop = 0;

        //jumbotron-content

        //set HEADER
        let headerData = [];

        if ("intro" in result && !filtered) {
          /* let headerData = []; */
          let { intro } = result;

          headerData["cat_universo"] = this.props.t("menu").downloads[0];
          headerData["subcat_universo"] = intro.title;
          headerData["titulo_universo"] = intro.subTitle;
          headerData["description_universo"] = intro.description;
        }

        let { data, filter, search } = this.state;

        // set DOCUMENTS
        if ("documents" in result) {
          const { documents } = result;
          data = documents;

          //this.setState({ data });
        }

        // set filter
        if ("filterOptions" in result && !filtered) {
          //let { filter } = this.state;
          const { filterOptions } = result;

          filter = filterOptions.map((val) => {
            return {
              id: val.id,
              text: val.title,
              uri: `${this.i18n.language}/${this.t("menu").downloads[1]}/fc1${
                val.id
              }`,
            };
          });
        }

        /***
         *
         * set search ...
         *
         * Para ativação da pesquisa ao acionar a tecla ENTER
         * no SEARCH
         */

        if (!filtered) {
          search = {
            onKeyDown: (e) => {
              let requestURI = this.makeURI();

              if (e.key === "Enter") {
                let { selectFilter, expr2Search } = this.state;

                expr2Search = e.target.value;

                if (selectFilter !== null) {
                  let fcFilter = selectFilter.split("/").filter((val) => {
                    return val.indexOf("fc") >= 0;
                  });

                  if (Array.isArray(fcFilter) && fcFilter.length === 1) {
                    requestURI += `${fcFilter[0]}/`;
                  }
                }

                // constroi o componente SEARCH se ele existir
                if (
                  expr2Search !== undefined &&
                  expr2Search !== null &&
                  expr2Search.length > 0
                ) {
                  requestURI += "ffs" + expr2Search;
                }

                this.getDataFromAPI(requestURI, true);
              }
            },
          };
        }

        if (Array.isArray(filter) && !filtered) {
          filter.unshift({
            id: -1,
            text: this.t("all"),
            uri: `${this.i18n.language}/${this.t("menu").downloads[1]}`,
          });
        }

        if (!filtered) {
          this.props.setParentData({ headerData, filter, search });
          this.setState({ data, filter });
        } else {
          this.setState({ data });
        }
      }
    });
  };

  /**
   * @description
   * Quando existe um link através de menu principal,
   * procura pela ancora correspondente
   * e faz scroll até chegar ao destino
   */
  handleStickyMenuItemClick = () => {
    /*  var element = this.props.match.params.familyId;
    var ele = document.querySelector(".jumbotron");
    ele.scrollIntoView({
      behavior: "smooth",
      
    }); */
  };

  /**
   * Downloads
   */
  makeListDownloads = () => {
    let list = null;
    if (
      this.state.data &&
      this.state.data !== null &&
      this.state.data.all &&
      this.state.data.all.length > 0
    ) {
      list = this.state.data.all.map((val, key) => {

        let techSheet = null;

        if (val.type === "video") {
          techSheet = insertMediaWithAppAbsPath(val.name, "imgs");
        } else {
          techSheet = insertMediaWithAppAbsPath(val.name, "docs");
        }

        var langDocs = this.props.t("docstype");
        var found = null;

        if (Object.keys(langDocs).includes(val.type)) {
          found = Object.keys(langDocs).find(
            (langDocs) => langDocs === val.type
          );
        }

        return (
          <li className="line_files" key={val.id}>
            <a href={techSheet} target="_blank" rel="noopener noreferrer">
              <img
                className="img_pdf"
                src="/assets/imgs/PDF.png"
                alt="icon_download"
              />
              <div className="col-8 col-lg-9 col-xl-10 info_downloads">
                <h5 className="cat_downloads">{langDocs[found]}</h5>
                <h6 className="filename_downloads">{val.alt}</h6>
              </div>
              <img
                className="img_download"
                src="/assets/imgs/Seta-03.png"
                alt="icon_download"
              />
            </a>
          </li>
        );
      });
      return list;
    }
  };

  render() {
    return (
      <>
        <ul className="list_of_documents">
          {this.state.data !== undefined ? this.makeListDownloads() : null}
        </ul>
        <div
          className={`col-12 noresults ${
            this.state.data &&
            "all" in this.state.data &&
            Array.isArray(this.state.data.all) &&
            this.state.data.all.length > 0
              ? "d-none"
              : ""
          }`}
        >
          {this.t("noResults")}
        </div>
      </>
    );
  }
}

export default withTranslation()(DownloadsView);
