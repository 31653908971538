import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";
import { withTranslation } from "react-i18next";

import { Configs } from "../../../Toolbox";

import Forms from "../../../components/Forms";
import AlertMessage from "../../AlertMessage";

import "../../../../src/scss/AboutTeam.scss";

/**
 * AKA SOMOS EQUIPA
 *
 * @description
 * Para o tratamento das acções, layout e afins correspondentes à pagina
 * SOMOS EQUIPA
 */
class AboutTeam extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lang: null, // p o idioma
      data: [], // p os dados
      submitRes: null, // para registo de mensagem de retorno da FORM
    };

    this.jobsOpenStats = true; // TRUE : EXSITEM FALSE N EXISTEM

    // p utilização nas acções de tradução
    this.t = this.props.t;
    this.i18n = this.props.i18n;

    this.webserver = this.props.webserver ? this.props.webserver : null; // para comunicação com a API - provem do PARENT
    this.restURI = `rest/hr/online-application`;

    // campos do formulário de submissão de cadidatura a oportunidades em aberto
    // ou expontânea(s)
    this.fields = [
      {
        type: "text",
        className: "form-control",
        id: "jobref",
        name: "jobref",
        "data-i18n-key": "ref",
        readOnly: "readonly",
      },
      Object.assign({ required: "required" }, Configs.form_fields.department), // mandatório
      Object.assign({ required: "required" }, Configs.form_fields.name), // mandatório
      Object.assign({ required: "required" }, Configs.form_fields.age), // mandatório
      Object.assign({ required: "required" }, Configs.form_fields.citizenship), // mandatório
      Object.assign({ required: "required" }, Configs.form_fields.email), // mandatório
      Object.assign({ required: "required" }, Configs.form_fields.mobile), // mandatório
      Object.assign({}, Configs.form_fields.address),
      Object.assign({}, Configs.form_fields.linkedin),
      Object.assign({}, Configs.form_fields.remarks),
      Object.assign({ required: "required" }, Configs.form_fields.cv),
      Object.assign({ required: "required" }, Configs.form_fields.rgpdconsent),
    ];

    // torna todos os campos obrigatórios & costumiza-os
    // obtem info para departamentos
    //
    //  id: "drivelic-1",
    //  i18n: "yes",
    //  value: "yes",

    if (this.fields[1]) {
      let departOptions = this.t("forms").company_departs;
      let res = [];
      let count = 0;

      if (departOptions) {
        this.fields[1].offspring = departOptions.map((val, key) => {
          count++;
          return {
            id: this.t("forms").company_departs[key][0], // count,
            value: val[key],
            text: this.t("forms").company_departs[key][1], //company_departs
          };
        });
      }
    }

    // actualiza info para a form de apply 4 job...
    // o campo remarks neste caso em particular não é remarks é Comments
    // actualiza essa info
    if (this.fields[9]) {
      this.fields[9]["data-i18n-key"] = "yourmsg";
    }

    // consentimento RGPD
    // custumiza o campo relacionado com o RGPD
    if (this.fields[11]) {
      this.fields[11].offspring[0].text = this.t("forms").rgpdconsent[1];
    }
  }

  /**
   * @description
   * Carregamento e inicialização dos elementos do compoente / página
   */
  componentDidMount() {
    window.sessionStorage.removeItem("stickyBFM");
    this.t = this.props.t; // p utilização nas acções de tradução
    this.i18n = this.props.i18n;

    let { lang, data } = this.state;

    lang = this.i18n.language; // actualiza idioma
    data = this.props.pData ? this.props.pData : null; // actualiza com dados do componente pai

    this.setState({ lang, data }); // actualiza STATE vars

    // actualiza layout de componentes pai com anúncios de
    // emprego activos
    this.props.setParentData({ childData: this.makeJobOpportunities(lang) });

    // o formulário de preenchimento de oportunidades em aberto possui duas
    // áreas de registo, EXSPONTNEA / REPOSTA A ANUNCIO, esconde esta última
    // mostrando a expotânea em default
    // jobref
    let jobRef = document.getElementsByName("jobref");

    jobRef = jobRef ? jobRef[0] : null;
    jobRef.closest(".form-group").classList.add("d-none");

    let anchorRef = window.location.href;

    let anchor = window.location.hash.substring(
      window.location.hash.lastIndexOf("/#") + 1
    );
    anchor = anchor.replace("#", "");

    let finalAnchor = document.getElementById(anchor);

    if (anchorRef.includes("#") && finalAnchor) {
      setTimeout(() => {
        finalAnchor.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }, 500);
    }
  }

  /**
   * @description
   * Para actualização de layout etc qdo se verificar algum tipo de update
   * aos dados do componente
   *
   * pData : dados com origem no componente pai, i.e., conteúdo do layout
   * a apresentar por este componentes, oportunidade de emprego em aberto,
   * por exemplo
   *
   * @param {*} prevProps
   * @param {*} prevState
   */
  componentDidUpdate(prevProps, prevState) {
    let { lang, data } = this.state; // state vars

    if (
      "pData" in this.props && // verifica se pData (dados de parent component) existem
      "content" in this.props.pData && // verifica se existe uma chave component nesses dados
      (data === null || // verifica se os dados de STATE var são nulos
        JSON.stringify(this.props.pData.content) !== JSON.stringify(data)) && // verifica se os dados em pData são difentes dos dados guardados em STATE var numa acção anterior
      Object.keys(this.props.pData).length > 0 // verifica se os dados do parent possuem conteúdo
    ) {
      lang = this.i18n.language; // actualiza idioma em utilização
      data = this.props.pData.content; // actualiza dados com origem no parent

      // actualiza STATE vars
      this.setState({ lang, data }, () =>
        // após actualização das STATE vars procede com a actuialização
        // dos conteúdos para parent, este proveem de parent, são tratados
        // neste component, i.e., é fabricado o layout com recurso ao método
        // makeJobOpportunities, o voltam ao parent para serem injectados na VIEW
        this.props.setParentData({ childData: this.makeJobOpportunities(lang) })
      );
    }
  }

  /**
   * @description
   * Tratamento do click na opção "Candidatar Agora"
   *
   * @param {*}
   */
  handleApply4JobClick = (e) => {
    const target = e.target;
    const jobRef = target.closest(".card-body").getAttribute("data-ref");

    this.setReadJobRef(jobRef);
  };

  /**
   * @description
   * Procede ao tratamento dos inputs DEPARTAMENTO / REFREFENCIA
   *
   * @param {*} jobref
   */
  setReadJobRef = (jobref) => {
    let jobRefInput = document.getElementById("jobref"); // inout referencia de oportunidade
    let scroll2 = jobRefInput.closest(".team-jobapply");
    let depart = document.getElementsByName("department"); // select departamento

    depart = depart ? depart[0] : null;

    jobRefInput.value = jobref;

    // jobref
    let formGroup = jobRefInput.closest(".form-group");

    formGroup.classList.remove("d-none");
    formGroup.classList.add("valid-value");

    // department
    depart.closest(".form-group ").classList.add("d-none");
    scroll2.scrollIntoView({ behavior: "smooth" });

    // tratamento para movimentar a label para cima, i.e,
    // colocar na posição de input preenchido ou input vazio de
    // acordo com a situação actual...
    if (jobRefInput.value.trim().length > 0) {
      jobRefInput.classList.add("isValued");
    } else if (jobRefInput.value.trim().length === 0) {
      jobRefInput.classList.remove("isValued");
    }
  };

  /**
   * @description
   * Usa para a execução da listagem um objecto de state var com o formato GERAL (parent)
   *
   * @param {*} val
   */
  makeJobOpportunities = () => {
    let tagOpportunitiesText = this.t("views").about.team.jobs; // expressão de dicionário
    tagOpportunitiesText = tagOpportunitiesText ? tagOpportunitiesText : null;

    // obtem lista de oportunidades em aberto...
    let {
      data: { team },
    } = this.state;

    let jobs = team !== undefined && team.jobs ? team.jobs : [];

    // actualiza o status da mensagm de NO JOBS
    this.jobsOpenStats = jobs.length !== 0 && true;

    // fabrica LAYOUT
    let opportunitiesList = jobs.map((val) => {
      return (
        <div className="card" key={val.id}>
          <div className="card-header" id={`offer_${val.id}`}>
            <h5 className="mb-0">
              <button
                className="btn btn-link collapsed"
                data-toggle="collapse"
                data-target={`#collapse_${val.id}`}
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                <span className="view-more"></span>
                {val.title}
              </button>
            </h5>
          </div>
          <div
            id={`collapse_${val.id}`}
            className="collapse"
            aria-labelledby={`offer_${val.id}`}
            data-parent="#offers"
          >
            <div className="card-body" data-ref={val.ref}>
              {val.description && val.description.trim().length > 0
                ? ReactHtmlParser(val.description)
                : ""}
              <div className="col-12 apply2Job-container">
                <button
                  className="apply2Job"
                  onClick={this.handleApply4JobClick}
                >
                  {this.t("forms").titles.applyNow}
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    });

    // mostra layout, i.e., acordeão com as oportunidades em aberto
    return (
      <div className="col-12 applyjob-opportunities">
        <div className="col-12 applyjob-content">
          <h2>{this.t("views").about.team.openJobs}</h2>
          <div
            className={`col-9 noOffers ${
              this.jobsOpenStats === true ? "d-none" : ""
            }`}
          >
            {this.t("forms").titles.noJobsFound}
          </div>

          <div
            id="offers"
            className={`${this.jobsOpenStats === true ? "" : "d-none"}`}
          >
            {opportunitiesList ? opportunitiesList : null}
          </div>
        </div>

        <div className={`tag-opportunities ${tagOpportunitiesText}`}>
          <span className="text">{this.t("views").about.team.jobs}</span>
        </div>
      </div>
    );
  };

  /**
   * @description
   * Procede ao tratamento do evento de submissão da FORM
   *
   * @param {*} e
   * @param {*} usrData
   */
  handleSubmit = (e, usrData) => {
    e.preventDefault();
    /* const target = e.target; */
    const { lang } = this.state;

    let formLoader = document.getElementById("formLoader");

    if (formLoader !== undefined) {
      formLoader.classList.add("l-visible");
    }

    //let { resMsg, resMsgStats } = this.state; // p tratamento da resposta do servidor
    //let data2Send = setDataToFormData(usrData);
    //usrData = document.getElementById("jobApply");

    // dados de utilizador para FORMDATA
    let data2Send = new FormData(document.getElementById("jobApply"));

    // adidiona ficheiro... CV ao data2Send
    data2Send.set("cv", usrData["file-reader"].fileList[0]);

    if (data2Send instanceof FormData && this.webserver) {
      // se processo anterior OK, procede ao envio da info.
      this.webserver.sendRequest(
        "POST", // método a usar no pedido
        `${lang}/${this.restURI}`, // URL do pedido
        (result, xhr) => {
          if (formLoader !== undefined) {
            formLoader.classList.remove("l-visible");
          }

          if (
            xhr.status === 200 &&
            "res" in result &&
            result.res !== undefined &&
            result.res !== null
          ) {
            let { submitRes } = this.state;
            submitRes = result;

            // procede ao reset da FORM se resposta for positiva...
            if (submitRes.res) {
              document.getElementById("jobApply").reset();
            }

            // altera o estado da mensagem de retorno do processo de envio ao servidor
            this.setState({ submitRes });

            /* // obtem a resposta do servidor
            resMsg =
              "msg" in result && result.msg.trim().length > 0
                ? ReactHtmlParser(result.msg)
                : "";

            resMsgStats = true;
            this.setState({ resMsg, resMsgStats });

            // coloca a fonte a vermelho se assim se justificar... ERRO
            // errstats
            // se ERRO de servidor, sinaliza a resposta
            if (!result.res) {
              let textStats = document
                .getElementsByClassName("sendmail-resp")[0]
                .getElementsByClassName("text");

              textStats[0].classList.add("errstats");
            }
 */
          } else {
            // SE NOK
            throw this.t("errorHandler").emailNotSentError;
          }
        },
        data2Send
      );
    }

    // visto que o FORM possui um INPUT de inserção de ficheiros, procede ao tratamento
    // dos dados com recusro ao objeto JAVASCRIP new FORMDATA -> ver TRAVOCAR...
  };

  /**
   *
   * @param {*} res
   */
  handleCloseAlert = (res) => {
    // ao fechar a mensagem de STATUS limpa a STATE no que à mensagem do servidor
    // diz respeito
    let { submitRes } = this.state;
    submitRes = null;
    // altera o estado da mensagem de retorno do processo de envio ao servidor
    this.setState({ submitRes });
  };

  /**
   * Mostra conteúdo base da VIEW, i.e., form a incoprorar na respectiva secção
   * do ABOUTVIEW, parent
   */
  render() {
    let {
      data: { team },
    } = this.state;

    let players =
      team &&
      "players" in team &&
      Array.isArray(team.players) &&
      team.players[1]
        ? team.players[1]
        : null;

    //console.log(this.submitRes);

    return (
      <div className="col-12 team-intro-content">
        <div className="col-12 col-md-6 col-lg-4 team-intro-header">
          {/* Titulo do componente + Intro. */}
          <h2>{players !== null ? players.title : ""}</h2>
        </div>
        <div className="col-12 col-md-6 col-lg-8 team-intro-body">
          {players !== null ? ReactHtmlParser(players.description) : ""}
        </div>
        <div className="col-12 team-jobapply" id="formJobs">
          <h4>{this.t("views").about.team.jobApplyHeader}</h4>
          {/* Form de registo de oportunidades de emprego */}
          <Forms
            id="jobApply"
            fields={this.fields}
            //fReset={!this.state.openStats}
            onSubmit={this.handleSubmit}
            enctype="multipart/form-data"
          />
          <AlertMessage
            onClose={this.handleCloseAlert}
            resStats={this.state.submitRes}
            myClass={`submit-status alert alert-dismissible fade ${
              this.state.submitRes !== null && this.state.submitRes.msg
                ? "show"
                : ""
            }`}
          >
            <span>
              {this.state.submitRes !== null && this.state.submitRes.msg
                ? this.state.submitRes.msg
                : ""}
            </span>
          </AlertMessage>
        </div>
      </div>
    );
  }
}

export default withTranslation()(AboutTeam);
