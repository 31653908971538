import React from "react";
import { useTranslation } from "react-i18next";
import "../../scss/Error404.scss";
import { Configs } from "../../Toolbox";

const Error404 = (props) => {
  const { t } = useTranslation();

  return (
    <div className="body_notFound">
      <p className="error404">{t("404").subTitle}</p>
      <p className="notFound">{t("404").phrase}</p>
      <br />
      <button type="button" className="goBack">
        <a href={Configs.base_url + Configs.default_lang}>
          <p className="back">{t("404").back}</p>
        </a>
      </button>
    </div>
  );
};

export default Error404;
