import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import { useTranslation } from "react-i18next";

import ProjectDetailView from "./views/ProjectDetailView";

import BudgetForm from "./forms/PopUpForm"; // FORM para orçamentos
import AppMenu from "./AppMenu"; // MENU da App
import AppFooter from "./AppFooter"; // FOOTER da App
import StickyFilterMenu from "./FiltersAndOrders";

// ------------------------------------------------------- Views
// home
import Home from "./views/Home";
// serviços
import ServicesView from "./views/ServicesView";

// projectos
import ProjectsView from "./views/ProjectsView";

// UNIVERSO PRILUX
import About from "./views/AboutView";
// prilux
// globais
// equipa
// qualidade
// sinergias
// notícia

// notícia detalhe
import NewsDetailView from "./views/NewsDetailView";

// PROJETOS E Downloads
import ProjectsAndDownloads from "./Jumbotron";

// contacts
import Contacts from "./views/ContactsView";

//downloads
import DownloadsView from "./views/DownloadsView";

// pesquisa (resultado)
import SearchProductView from "./views/SearchProductView";

// Politica de privacidade
import RGPD from "./views/RGPD"; // RGPD

//Projetos Financiados
import FundedProjectsView from "./views/FundedProjectsView";
// .------------------------------------------------------ Views end

// ------------------------------------------------------- ERROR HANDLING
import Error404 from "./error/Error404";
// ------------------------------------------------------- ERROR HANDLING

import { Configs, dealWithAPIReturnedData } from "../Toolbox";
import LaserCutView from "./views/LaserCutView";
import BendingView from "./views/BendingView";
//import Forms from "../components/Forms";

/**
 * @description
 * Para apresentação do "ambiente de trabalho" da App da base de todas as
 * páginas
 *
 * @param {*} props
 */
const DashboardView = (props) => {
  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState();
  const [stickyFMOptions, setSFMOptions] = useState();

  const [wClick, setWClick] = useState(null);

  // o component AppMenu executa um pedido sobre a API p obtencao dos
  // componentes que asseguram o menu sendo que estes são necessários
  // tb no Footer, no sentido de evitar novo pedido destes dados sobre a API
  // neste componente, obtem-se estes a partir do MENU passando-os via DASHBOARD
  // para o FOOTER
  const [navigation, setNavigation] = useState();

  const webserver = props.webserver ? props.webserver : null;

  /// campos para o FORM de pedido de orçamento(s)
  let fields = [
    Object.assign({ required: "required" }, Configs.form_fields.name),
    Object.assign({ required: "required" }, Configs.form_fields.email),
    Object.assign({ required: "required" }, Configs.form_fields.mobile),
    Object.assign({ required: "required" }, Configs.form_fields.remarks),
    Object.assign({ required: "required" }, Configs.form_fields.rgpdconsent),
  ];

  // torna todos os campos obrigatórios

  // actualiza info para a form de budget...
  // o campo remarks neste caso em particular não é remarks é Comments
  // actualiza essa info
  if (fields[3]) {
    fields[3]["data-i18n-key"] = "comments";
  }

  // custumiza o campo relacionado com o RGPD
  if (fields[4]) {
    fields[4].offspring[0].text = t("forms").rgpdconsent[1];
  }

  /**
   * @description
   * Equivalente ao componentDidMount / componentDidUpdate
   *
   */
  useEffect(() => {
    // Actualiza o idioma usado na app... esta var poderá servir como
    // Lifting Up State e por esta via implicar o render dos components
    // child. Neste momento julga-se últil para obter os conteúdos
    // dinâmicos a partir de uma API ou BO
    setLang(i18n.language);

    //window.scrollTo({ top: 0, behavior: "smooth" });
  }, [lang, setLang, navigation, setNavigation, i18n, wClick, setWClick]);

  return (
    <Router>
      <div className="wrapper row">
        <Route
          exact
          path={[
            `/:lang([a-z]{2})/${t("menu").services[1]}`,
            `/:lang([a-z]{2})/${t("menu").services[1]}/:optionId/:optionSlug`,
            `/:lang([a-z]{2})/${t("menu").about[1]}/:optionId/:optionSlug`,
            `/:lang([a-z]{2})/${t("menu").news[1]}/:optionId/:optionSlug`,
            `/:lang([a-z]{2})/${t("menu").projects[1]}/:optionId/:optionSlug`,
            `/:lang([a-z]{2})/${t("menu").ghouse[1]}-${t("menu").services[1]}/:optionId/:optionSlug`,
            `/:lang([a-z]{2})/${t("menu").ghouse[1]}-${t("menu").services[1]}/:optionId/:optionSlug/:familyId/:familySlug/`,
            `/:lang([a-z]{2})/${t("menu").ghouse[1]}/:optionId/:optionSlug/:familyId/:familySlug/`,
            `/:lang([a-z]{2})/${t("menu").laserCut[1]}`,
            `/:lang([a-z]{2})/${t("menu").laserCut[1]}/:optionId/:optionSlug`,
            `/:lang([a-z]{2})/${t("menu").bending[1]}`,
            `/:lang([a-z]{2})/${t("menu").bending[1]}/:optionId/:optionSlug`,

          ]}
          render={(props) => (
            <StickyFilterMenu
              {...props}
              fclass="sticky-fm"
              stickyFMOptions={stickyFMOptions}
            />
          )}
        />

        <Route
          exact
          path={[
            `/:lang([a-z]{2})`,
            `/:lang([a-z]{2})/${t("menu").ghouse[1]}/:optionId/:optionSlug`,
            `/:lang([a-z]{2})/${t("menu").ghouse[1]}/:optionId/:optionSlug/:familyId?/:familySlug?`,
            `/:lang([a-z]{2})/${t("menu").suporte_ghouse[1]}/:optionId/:optionSlug/:familyId?/:familySlug?`,
          ]}
          render={(props) => (
            <BudgetForm {...props} wClicked={wClick} fields={fields} />
          )}
        />
        {/* <BudgetForm fields={fields} /> */}
        <AppMenu
          use="stickybar"
          navData={setNavigation}
          lang={lang}
          {...props}
          wClicked={wClick}
        />
        <main style={{ width: "100%" }}>
          <Switch>
            <Route
              exact
              path={`/:lang([a-z]{2})`}
              render={(props) => <Home {...props} />}
            />

            <Route
              exact
              path={`/:lang([a-z]{2})/${t("menu").downloads[1]}`}
              render={(props) => <ProjectsAndDownloads {...props} />}
            />
            <Route
              exact
              path={`/:lang([a-z]{2})/contactos`}
              render={(props) => <Contacts {...props} />}
            />
            <Route
              exact
              path={`/:lang([a-z]{2})/${t("menu").privacy[1]}`}
              render={(props) => <RGPD {...props} />}
            />

            <Route
              path={`/:lang([a-z]{2})/${
                t("menu").about[1]
              }/:optionId/:optionSlug`}
              render={(props) => (
                <About
                  setSFMOptions={setSFMOptions}
                  navData={navigation}
                  {...props}
                />
              )}
            />

            <Route
              exact
              path={`/:lang([a-z]{2})/${t("menu").about[1]}`}
              render={(props) => <About {...props} />}
            />

            <Route
              exact
              path={`/:lang([a-z]{2})/${
                t("menu").news[1]
              }/:optionId/:optionSlug`}
              render={(props) => (
                <NewsDetailView
                  setSFMOptions={setSFMOptions}
                  navData={navigation}
                  {...props}
                />
              )}
            />

            <Route
              exact
              path={[
                `/:lang([a-z]{2})/${t("menu").services[1]}`,
                `/:lang([a-z]{2})/${t("menu").services[1]}/:optionId/:optionSlug`,
              ]}
              render={(props) => (
                <ServicesView
                  setSFMOptions={setSFMOptions}
                  navData={navigation}
                  {...props}
                />
              )}
            />
            {/* Corte a lase */}
            <Route
              exact
              path={[
                `/:lang([a-z]{2})/${t("menu").laserCut[1]}`,
                `/:lang([a-z]{2})/${t("menu").laserCut[1]}/:optionId/:optionSlug`,
              ]}
              render={(props) => (
                <LaserCutView
                  setSFMOptions={setSFMOptions}
                  navData={navigation}
                  {...props}
                />
              )}
            />
            {/*Quinagem*/}
            <Route
              exact
              path={[
                `/:lang([a-z]{2})/${t("menu").bending[1]}`
              ]}
              render={(props) => (
                <BendingView
                  setSFMOptions={setSFMOptions}
                  navData={navigation}
                  {...props}
                />
              )}
            />


            {/* LISTA DE PROJECTOS OU DE DOWNLOADS */}
            <Route
              exact
              path={`/:lang([a-z]{2})/${t("menu").projects[1]}`}
              render={(props) => <ProjectsAndDownloads {...props} />}
            />
            {/* DETALHE DE PROJECTOS */}
            <Route
              exact
              path={`/:lang([a-z]{2})/${
                t("menu").projects[1]
              }/:optionId/:optionSlug`}
              render={(props) => (
                <ProjectDetailView
                  setSFMOptions={setSFMOptions}
                  navData={navigation}
                  {...props}
                />
              )}
            />

            {/* PESQUISA DE PRODUTO */}
            <Route
              exact
              path={`/:lang([a-z]{2})/${t("search")}/q=:searchText`}
              render={(props) => <SearchProductView {...props} />}
            />

            {/* PROJETOS FINANCIADOS */}
            <Route
              exact
              path={`/:lang([a-z]{2})/${t("menu").funded_projects[1]}`}
              render={(props) => <FundedProjectsView {...props} />}
            />

            {/*  PAGE 404 */}
            <Route path="*" render={(props) => <Error404 {...props} />} />
            {/* <Route path="*" render={(props) => <Redirect to={`/${lang}`} />} /> */}
          </Switch>
        </main>
      </div>
      <AppFooter className="app-footer" navData={navigation} />
    </Router>
  );
};

export default DashboardView;

export const StickyBar = () => {};
