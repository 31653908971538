import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import ReactHtmlParser from "react-html-parser";
//import HtmlParser from "react-html-parser";
/* import { Configs } from "../../../Toolbox"; */

import Forms from "../../../components/Forms";
import AlertMessage from "../../AlertMessage";

import {
  Configs,
  extractElementsFromArrayByIsType,
  insertMediaWithAppAbsPath,
  //dealWithMessageBox,
  dealWithAPIReturnedData,
} from "../../../Toolbox";
import { Parallax, ParallaxProvider } from "react-scroll-parallax";
import "../../../../src/scss/AboutQuality.scss";
import VisibilitySensor from "react-visibility-sensor";
/**
 * AKA SOMOS QUALIDADE
 */
class AboutQuality extends Component {
  constructor(props) {
    super(props);

    /**
     * campos do formulário das sugestões
     * neste caso são todos obrigatórios
     **/

    this.fields = [
      Object.assign({ required: "required" }, Configs.form_fields.subject), // mandatório
      Object.assign({ required: "required" }, Configs.form_fields.name), // mandatório
      Object.assign({ required: "required" }, Configs.form_fields.email), // mandatório
      Object.assign({ required: "required" }, Configs.form_fields.mobile), // mandatório
      Object.assign({ required: "required" }, Configs.form_fields.remarks), // mandatório
      Object.assign({ required: "required" }, Configs.form_fields.rgpdconsent), // mandatório
      Object.assign({ required: "required" }, Configs.form_fields.emarketing), // mandatório
    ];

    // define o subject da mensagem
    this.fields[0].value = this.props.t("menu").quality[0];

    this.state = {
      lang: null,
      data: {},
      frmFields: this.fields,
      submitRes: null, // para registo de mensagem de retorno da FORM
    };

    this.t = props.t; //tradutor
    this.i18n = null;

    this.webserver = this.props.webserver ? this.props.webserver : null; // para comunicação com a API - provem do PARENT
    this.restURI = `rest/quality/contactos/send`;
  }

  componentDidMount() {
    window.sessionStorage.removeItem("stickyBFM");
    //console.log(this.props.tsss);

    this.t = this.props.t; //para utilização nas  ações de tradução
    this.i18n = this.props.i18n;

    let { lang, data } = this.state;

    lang = this.i18n.language;
    data = this.props.pData ? this.props.pData : null;

    // altera telemóvel para telefone
    if (this.fields[3]) {
      this.fields[3]["data-i18n-key"] = "phone";
    }

    // actualiza info para a form de apply 4 job...
    // o campo remarks neste caso em particular não é remarks é Comments
    // actualiza essa info
    if (this.fields[4]) {
      this.fields[4]["data-i18n-key"] = "yourmsg";
    }

    // custumiza o campo relacionado com o RGPD
    if (this.fields[5]) {
      this.fields[5].offspring[0].text = this.t("forms").rgpdconsent[1];
    }

    this.setState({ lang, data });

    this.props.setParentData({ childData: this.makeSecondPart(lang) });
  }

  componentDidUpdate(prevProps, prevState) {
    let { lang, data } = this.state;

    if (
      "pData" in this.props &&
      "content" in this.props.pData &&
      (data === null ||
        JSON.stringify(this.props.pData.content) !== JSON.stringify(data)) &&
      Object.keys(this.props.pData).length > 0
    ) {
      lang = this.i18n.language;
      data = this.props.pData.content;
      this.setState({ lang, data }, () =>
        this.props.setParentData({ childData: this.makeSecondPart(lang) })
      );
    }
  }

  makeMission = () => {
    let mission = null;
    if (this.state.data !== null && this.state.data.quality) {
      mission = this.state.data.quality.intro[2];

      // obtem imagem do banner
      let img = extractElementsFromArrayByIsType(mission.imgs, "generic");

      // valida resultado obtido e gera o link absoluto da imagem
      img =
        img !== null ? insertMediaWithAppAbsPath(img[0].name, "imgs") : null;

      // obtem alt para imagem para efeitos de SEO
      let alt = '';
       alt = mission.imgs[0] ? mission.imgs[0].alt : '';

      return (
        <div className="MissionValue">
          <h3 className="TitleMission">{mission.title}</h3>
          <div className="info_mission">
            <p className="DescriptionMission">
              {ReactHtmlParser(mission.description)}
            </p>
          </div>
          {img && <img alt={alt} src={img} className="sgs"/>}
        </div>
      );
    }
    return mission;
  };

  makeDNA = () => {
    let dna = null;
    if (this.state.data !== null && this.state.data.quality) {
      dna = this.state.data.quality.values.map((val, key) => {
        return (
          <div className="DNA_things" key={val.id}>
            <h3 className="TitleDNA">{val.title}</h3>
            <h4 className="subTitleDNA"> {val.subTitle}</h4>
            <ul className="descriptionDNA">
              {ReactHtmlParser(val.description)}
            </ul>

            <div className="quality_content">
              <ParallaxProvider>
                <Parallax className="custom-class" x={[50, 10]} tagOuter="figure">
                 <span className="word_quality">
                    {this.props.t("views").about.quality.word_quality}
                  </span>
                </Parallax>
              </ParallaxProvider>
            </div>

          </div>

        );
      });
    }

    return dna;
  };

  /**
   * Function for call Sections for
   * makeCriterios --> section in gray
   * makeSugestions --> section w/ form for sugestion in white
   * **/
  makeSecondPart = () => {
    return (
      <>

        {this.state.data !== undefined ? this.makeCriterios() : null}
        {this.state.data !== undefined ? this.makeSugestions() : null}
      </>
    );
  };

  /**Seção cinzenta que engloba tudo sobre os criterios **/
  makeCriterios = () => {
    if (this.state.data !== null && this.state.data.quality) {
      return (
        <div className="body_4_values">
          {this.state.data.quality.assess !== undefined
            ? this.makeAssessIntro()
            : null}

          <div className="criteria_assess col-xl-9">
            {this.state.data.quality.assess !== undefined
              ? this.makeAssessCriteria()
              : null}
          </div>

          <div className="imq_teste col-xl-8">
            {this.state.data.quality.assess !== undefined
              ? this.makeAssessImq()
              : null}
          </div>
        </div>
      );
    }
  };

  //Relativo à parte inicial (titulo + descritivo)
  makeAssessIntro = () => {
    let intro = null;
    if (
      this.state.data.quality.assess !== null &&
      this.state.data.quality.assess.intro
    ) {
      intro = this.state.data.quality.assess.intro;
      return (
        <div className="intro_assess col-xl-8">
          <h2 className="intro_assess_title">
            {" "}
            {ReactHtmlParser(intro.title)}{" "}
          </h2>
          <p className="intro_assess_description">
            {ReactHtmlParser(intro.description)}
          </p>
        </div>
      );
    }
    return intro;
  };

  //Relativo aos criterios de fornecimento de produtos e de serviços
  makeAssessCriteria = () => {
    let criteria = null;
    if (
      this.state.data.quality.assess !== null &&
      this.state.data.quality.assess.criteria
    ) {
      criteria = this.state.data.quality.assess.criteria.map((val, key) => {
        return (
          <div key={val.id}>
            <h2 className="criteria_assess_title"> {val.title} </h2>
            <h2 className="criteria_assess_title"> {val.subTitle} </h2>
            <div className="criteria_assess_list">
              {ReactHtmlParser(val.description)}
            </div>
          </div>
        );
      });
    }
    return criteria;
  };

  //Escala de IMQ
  makeAssessImq = () => {
    let imq = null;
    if (
      this.state.data.quality.assess !== null &&
      this.state.data.quality.assess.imq
    ) {
      imq = this.state.data.quality.assess.imq.map((val, key) => {
        return (
          <div className="imq_assess" key={val.id}>
            <h3 className="imq_assess_title"> {val.title} </h3>
            <h3 className="imq_assess_title"> {val.subTitle} </h3>
            <p className="imq_assess_description"> {val.description} </p>
          </div>
        );
      });
    }
    return imq;
  };

  onVisibilityChange = (isVisible = false) => {
    if (isVisible) {
      //   console.log("rezise");
      window.dispatchEvent(new Event("resize"));
    }
  };

  makeSugestions = () => {
    /** this.t("views").about.team.jobs; **/
    return (
      <div className="body_4_sugestions">
        <h6 className="title_opinion">
          {this.t("views").about.quality.your_opinion}
        </h6>
        <h5 className="subTitle_opinion">
          {this.t("views").about.quality.sugestions_claims}
        </h5>
        <div className="form_sugestion">
          <Forms
            id="makeSugestions"
            fields={this.state.frmFields}
            onSubmit={this.handleSubmit}
          />
          <AlertMessage
            //onClose={this.handleCloseAlert}
            resStats={this.state.submitRes}
            myClass={`submit-status alert alert-dismissible fade ${
              this.state.submitRes !== null && this.state.submitRes.msg
                ? "show"
                : ""
            }`}
          >
            <span>
              {this.state.submitRes !== null && this.state.submitRes.msg
                ? this.state.submitRes.msg
                : ""}
            </span>
          </AlertMessage>
        </div>
        <div className="div-sugestion">
          <ParallaxProvider>
            <Parallax className="custom-class" x={[0, 80]} tagOuter="figure">
              <span className="title_sugestions">
                {this.t("views").about.quality.word_sugestions}
              </span>
            </Parallax>
          </ParallaxProvider>
        </div>
      </div>
    );
  };

  handleSubmit = (e, usrData) => {
    e.preventDefault();

    const target = e.target;
    const { lang } = this.state;

    let myForm = document.getElementById("makeSugestions");
    let formLoader = document.getElementById("formLoader");

    if (formLoader !== undefined) {
      formLoader.classList.add("l-visible");
    }

    if (myForm !== undefined) {
      let data2Send = new FormData(document.getElementById("makeSugestions"));

      if (data2Send instanceof FormData && this.webserver) {
        this.webserver.sendRequest(
          "POST",
          `${lang}/${this.restURI}`,
          (result, xhr) => {
            //this.dealWithAPIReturnedData(result, xhr, myForm);
            dealWithAPIReturnedData(result, xhr, myForm, this);
          },
          data2Send
        );
      }
    }
  };

  render() {
    const { quality } = this.state.data;

    return (
      <>
        <div className="body-content_about">
          {quality && quality.intro ? this.makeMission() : null}
          {this.state.data !== undefined ? this.makeDNA() : null}
        </div>
      </>
    );
  }
}

export default withTranslation()(AboutQuality);
setTimeout(() => {
  window.dispatchEvent(new Event("resize")); /* console.clear(); */
}, 1000);
