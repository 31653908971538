import Axios from "axios";
import { Configs } from "../../Toolbox";

/**
 * @version 0.1
 * @date 2020/07
 *
 * Acresce a execução do construtor por defeito...
 *
 * @version 0.0
 * @date 2020/06
 *
 * @description
 * Para execução de pedidos HTTP junto de uma API localizada no endereço indicado
 * quando da inicialização do objecto
 *
 * @dependencies
 * - AXIOS
 *
 * @remark
 * - Axios provides methods for sending different types of HTTP request—the get, post, and
 * put methods, * for example
 * - Axios automatically converts the JSON data format into a JavaScript object and presents
 * it through the response data property.
 * - Axios devolve no responde algumas propriedades de utilizadade futura, na execução
 * do fluxo de dados:
 *  status    : This property returns the status code for the response, such as 200 or 404.
 *  statusText: This property returns the explanatory text that accompanies the status code, such as OK or
 *              Not Found.
 *  headers   : This property returns an object whose properties represent the response headers.
 *  data      : This property returns the payload from the response.
 *  config    : This property returns an object that contains the configuration options
 *              used to make the request.
 *  request   : This property returns the underlying XMLHttpRequest object that was used to make
 *              the request, which can be useful if you require direct access to the API provided
 *              by the browser.
 *
 */
export default class RestDataSource {
  /**
   * @param {string} method        -> Verbo a usar na execução dos pedidos
   * @param {string} baseUrl       -> Url base da API onde os pedidos serão executados
   * @param {string} errorCallback -> Callback para tratamento de eventuais erros
   */
  //constructor(baseUrl, method = null, errorCallback = null) {

  constructor(...args) {
    this.baseUrl = null;
    this.method = "get";
    this.errorCallback = (err) => {
      console.error(err);
    };

    let nArgs = args.length;

    // se o nr de argumentos for superior a 0 chama o respectiv construtor
    if (nArgs > 0 && nArgs <= 3) {
      this["paramConstr" + nArgs] && this["paramConstr" + nArgs](...args);
    } // caso contrário chama o construtor por defito
    else {
      this.defaultConstr();
    }
  }

  /**
   * @description
   *
   * Constructor por defeito... sendo que as vars estão inicializadas dentros da
   * declaração da class este está no formato TODO
   */
  defaultConstr() {
    if (Configs) {
      const { rest } = Configs;
      this.baseUrl = rest;
    }
  }

  /**
   * @description
   *
   * Contrutores por parâmetro...
   *
   *
   * @param {string} baseUrl -> para definição dom URL base
   */
  paramConstr1(baseUrl) {
    this.baseUrl = baseUrl;
  }

  /**
   *
   * @param {string} baseUrl     -> para definição dom URL base
   * @param {string|null} method -> metodo a usar
   */
  paramConstr2(baseUrl, method) {
    this.baseUrl = baseUrl;
    this.method = method;
  }

  /**
   *
   * @param {string} baseUrl         -> para definição dom URL base
   * @param {string|null} method     -> metodo a usar
   * @param {function} errorCallback -> para tratamento de eventuais erros
   */
  paramConstr3(baseUrl, method, errorCallback) {
    this.paramConstr2(baseUrl, method);
    this.errorCallback = typeof errorCallback === "function" && errorCallback;
  }

  /**
   * @description
   * Método para a execução do pedido à API, este método executa os pedidos e
   * procede remete as resposta a tratamento via callback, isto com base nos
   * parâmetros que lhe são passados quando da invocação do mesmo
   *
   * @param {function} method   -> <GET|POST|PUT|...>
   * @param {function} url      -> URL de pedido
   * @param {function} callback -> callback function para execução no momento em que o pedido
   *                               obtem uma resposta
   * @param {function} data     -> dados a enviar à API no pedidos que assim o justifiquem
   */
  async sendRequest(method, uri, callback, data) {
    let url = `${this.baseUrl}/${uri}`,
      meth = method !== null && method !== undefined ? method : this.method;

    /**
     * @description
     * Este método, request, devolve uma promise pelo que o seu tratamento é executado
     * de acordo com o código abaixo. Esta Promise trata-se do outcome / resultado do
     * pedido
     *

     */

    try {
      let response = await Axios.request({
        method: meth,
        url: url,
        data: data,
      }); //.then((response) => callback(response.data));

      callback(response.data, response);
    } catch (err) {
      this.errorCallback(`[Error]: ${err}`);
    }
  }

  /**
   * @description
   * Executa pedidos à API, em modo GET, não passível de alteração de método
   *
   * @param {string} method     -> qual o verbo <POST|GET|PUT|DELETE|...>
   * @param {string} uri        -> URI, sufixo do URL base, define a localiação do recurso
   * @param {funciton} callback -> função a executar quando da reposta ao pedido
   */
  async getData(uri, callback) {
    this.sendRequest(
      "get", // método usado para o pedido
      uri, // endereço do pedido
      callback // callback function
    );
  }

  /**
   * @description
   * Para submissão de pedidos à API em modo POST
   *
   * @param {string} uri        -> URI definido para o URL do pedido à API
   * @param {object} data       -> dados a submeter à API no decurso do pedido
   * @param {function} callback -> callback para tratamento da resposta
   */
  async storesData(uri, data, callback) {
    this.sendRequest("post", uri, callback, data);
  }

  /**
   * @description
   * Para processos de UPDATE / PUT de dados
   *
   * @param {string} uri        -> URI definido para o URL do pedido à API
   * @param {object} data       -> dados a serem actualizados (atenção ao campoID)
   * @param {function} callback -> callback para "tratamento" da resposta
   */
  async updateData(uri, data, callback) {
    this.sendRequest("put", `${uri}/${data.id}`, callback, data);
  }

  /**
   * Para processos de UPDATE / PUT de dados
   *
   * @param {string} uri        -> URI definido para o URL do pedido à API
   * @param {object} data       -> dados a serem actualizados (atenção ao campoID)
   * @param {function} callback -> callback para "tratamento" da resposta
   */
  async deleteData(uri, data, callback) {
    this.sendRequest("delete", `${uri}/${data.id}`, callback, data);
  }

  /**
   *
   * @param {srtring|null|undefined} method -> definição do método a usar no pedido, fica
   *                                           ao critério / responsabilidade do user a boa
   *                                           definição do método face aos parâmetros restantes
   * @param {string} uri                    -> URI definido para o URL do pedido à API
   * @param {object} data                   -> dados a serem actualizados (atenção ao campoID)
   * @param {function} callback             -> callback para "tratamento" da resposta
   */
  requestAPI(method, uri, data, callback) {
    this.sendRequest(method, uri, callback, data);
  }
}
