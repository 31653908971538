import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import ReactHtmlParser from "react-html-parser";

import {
  Configs,
  extractElementsFromArrayByIsType,
  insertMediaWithAppAbsPath,
  dealWithAPIReturnedData,
  //dealWithMessageBox,
} from "../../../Toolbox";
import Forms from "../../../components/Forms";
import AlertMessage from "../../AlertMessage";
import RestDataSource from "../../../components/webservices/RestDataSource";

import "../../../../src/scss/AboutGlobal.scss";

import CountUp, { startAnimation } from "react-countup";
import VisibilitySensor from "react-visibility-sensor";

/**
 * AKA SOMOS GLOBAIS
 */
class AboutGlobal extends Component {
  constructor(props) {
    super(props);

    // campos do formulário de submissão de cadidatura a oportunidades em aberto
    // ou expontânea(s)
    this.fields = [
      Object.assign({ required: "required" }, Configs.form_fields.subject), // mandatório
      Object.assign({ required: "required" }, Configs.form_fields.name), // mandatório
      Object.assign({}, Configs.form_fields.company),
      Object.assign({}, Configs.form_fields.occupation),
      Object.assign({ required: "required" }, Configs.form_fields.clientKind), // mandatório
      Object.assign({ required: "required" }, Configs.form_fields.email), // mandatório
      Object.assign({ required: "required" }, Configs.form_fields.mobile), // mandatório
      Object.assign({ required: "required" }, Configs.form_fields.country), // mandatório
      Object.assign({ required: "required" }, Configs.form_fields.remarks),
      Object.assign({ required: "required" }, Configs.form_fields.rgpdconsent),
      Object.assign({ required: "required" }, Configs.form_fields.emarketing),
    ];

    // define o subject da mensagem
    this.fields[0].value = this.props.t("menu").global[0];

    this.state = {
      lang: null, // p utilização em internacionalização
      data: [], // para armzenamento de dados
      frmFields: this.fields, // campos do formulário
      countryList: [], // lista de países a apresentar na FORM
      submitRes: null, // para registo de mensagem de retorno da FORM
      scrollStatus: true,
    };
    //this.onVisibilityChange = this.onVisibilityChange.bind(this); // Bind for appropriate 'this' context

    // p utilização nas acções de tradução
    this.t = this.props.t;
    this.i18n = this.props.i18n;

    let {
      thirdPartyRest: { countries },
    } = Configs;

    if (this.fields[2]) {
      let occupation = this.t("forms").kind_of_clients;

      /* let res = [];
      let count = 0;

      if (departOptions) {
        this.fields[0].offspring = departOptions.map((val, key) => {
          count++;
          return {
            id: count,
            value: val[key],
            text: this.t("forms").company_departs[key][1], //company_departs
          };
        });
      } */
    }

    this.thirdPartyRest = new RestDataSource(countries);

    this.webserver = this.props.webserver ? this.props.webserver : null; // para comunicação com a API - provem do PARENT
    this.restURI = `rest/global/contactos/send`;
  }

  componentDidMount() {
    window.sessionStorage.removeItem("stickyBFM");

    this.t = this.props.t; //para utilização nas ações de tradução
    this.i18n = this.props.i18n;

    let { lang, countryList, form, kindOfClient } = this.state;
    lang = this.i18n.language;

    // CUSTOM FORM
    // actualiza o campo tipo de cliente
    this.setKindOfClientAndDGPROnForm();

    // altera telemóvel para telefone
    if (this.fields[6]) {
      this.fields[6]["data-i18n-key"] = "phone";
    }

    // actualiza info para a form de apply 4 job...
    // o campo remarks neste caso em particular não é remarks é Comments
    // actualiza essa info
    if (this.fields[8]) {
      this.fields[8]["data-i18n-key"] = "yourmsg";
    }

    // customiza o campo relacionado com o RGPD
    if (this.fields[10]) {
      this.fields[10].offspring[0].text = this.t("forms").rgpdconsent[1];
    }

    // obtem lista de países para definição de FORM
    this.thirdPartyRest.getData("all", (result, xhr) => {
      // verifica se o pedido obteve resultados...
      if (
        xhr &&
        "status" in xhr &&
        xhr.status === 200 && // pedido OK
        "data" in xhr && // data presente
        xhr.data.length > 0 // data possui conteúdo
      ) {
        /**
         * Formato geral do objacto de onde tirar a info.
         * [{
         *   name : <country_name>
         *   ...
         *   translations : {
         *     br: <>,
         *     de: <>,
         *     ...
         *     pt: <>,
         *   }
         * } ... {}]
         */
        countryList = result;

        this.setState({ countryList }, () => {
          this.setCountriesOnForm();
        });
      }
    });

    this.setState({ lang, kindOfClient }, () => {
      //this.fields[3]
      let { frmFields, kindOfClient } = this.state;
      frmFields[4]["offspring"] = kindOfClient;
    });

    this.props.setParentData({ childData: this.makeContact4Partnership() });
  }

  componentDidUpdate(prevProps, prevState) {
    let { lang, data, countryList } = this.state;

    if (
      "pData" in this.props &&
      "content" in this.props.pData &&
      (data === null ||
        JSON.stringify(this.props.pData.content) !== JSON.stringify(data)) &&
      Object.keys(this.props.pData).length > 0
    ) {
      lang = this.i18n.language;
      data = this.props.pData.content;

      this.setState({ lang, data });
      this.setCountriesOnForm();
      this.setKindOfClientAndDGPROnForm();
      this.props.setParentData({ childData: this.makeContact4Partnership() });
    }
  }

  /**
   *
   */
  setKindOfClientAndDGPROnForm = () => {
    let { frmFields } = this.state;

    // actualiza o campo tipo de cliente & RGPD
    if (frmFields[4] && "kind_of_clients" in this.t("forms")) {
      frmFields[4].offspring = this.t("forms").kind_of_clients.map(
        (val, key) => {
          return { id: key, text: val[1], value: val[0] };
        }
      );
    }

    // custumiza o campo relacionado com o RGPD
    if (frmFields[9]) {
      frmFields[9].offspring[0].text = this.t("forms").rgpdconsent[1];
    }
  };

  /**
   *
   */
  setCountriesOnForm = () => {
    let { frmFields, countryList } = this.state;

    frmFields[7].offspring = countryList.map((val, key) => {
      let { translations } = val;

      let _text =
        this.i18n.language in translations
          ? translations[this.i18n.language]
          : val.name;
      return { id: val.numericCode, text: _text };
    });

    this.setState({ frmFields });
  };

  /**
   * @description
   * Para execução da FORM de contacto para eventual parceria
   *
   * @param {*}
   */
  makeContact4Partnership = () => {
    return (
      <div className="col-12 get-in-touch">
        <div className="line"></div>
        <div className="col-12 container">
          <h4>{this.t("views").about.team.jobApplyHeader}</h4>
          <Forms
            id="getInTouch"
            fields={this.state.frmFields}
            //fReset={!this.state.openStats}
            onSubmit={this.handleSubmit}
          />
          <AlertMessage
            onClose={this.handleCloseAlert}
            resStats={this.state.submitRes}
            myClass={`submit-status alert alert-dismissible fade ${
              this.state.submitRes !== null && this.state.submitRes.msg
                ? "show"
                : ""
            }`}
          >
            <span>
              {this.state.submitRes !== null && this.state.submitRes.msg
                ? this.state.submitRes.msg
                : ""}
            </span>
          </AlertMessage>
        </div>
      </div>
    );
  };

  onVisibilityChange = (isVisible = false) => {
    if (isVisible) {
      this.setState({ didViewCountUp: true });
    }
  };

  makeGlobal = (e, usrData) => {
    let global = null;
    if (this.state.data !== null && this.state.data.worldwide) {
      // obtem imagem do banner
      let img = extractElementsFromArrayByIsType(
        this.state.data.worldwide.imgs,
        "generic"
      );
      // valida resultado obtido e gera o link absoluto da imagem
      img =
        img !== null ? insertMediaWithAppAbsPath(img[0].name, "imgs") : null;

      // obtem alt para imagem para efeitos de SEO
      let alt = this.state.data.worldwide.imgs[0].alt;
      let info = this.state.data.worldwide.description.split(" ");
      var numbers = info.filter(function (item) {
        return parseInt(item) == item;
      });
      var numberFinal = numbers.map((x) => parseInt(x));
      var words = info.filter(function (item) {
        return !(parseInt(item) == item);
      });

      /*  var numbers = a.filter((e, i) => i % 2 === 0);
      var words = a.filter((e, i) => i % 2 === 1); */

      return (
        <>
          <img src={img} alt={alt} className="img_map" />
          <div className="countUp">
            <div className="numeros">
              {numberFinal.map((val) => (
                <VisibilitySensor
                  scrollCheck={true}
                  onChange={this.onVisibilityChange}
                  delayedCall
                >
                  <CountUp
                    start={0}
                    end={this.state.didViewCountUp ? val : 0}
                    duration={5}
                    className="numbers"
                  />
                </VisibilitySensor>
              ))}
            </div>
            <div className="locais">
              {words.map((val, key) => (
                <h1 className="number_words" key={val}>
                  {val}
                </h1>
              ))}
            </div>
          </div>

          <h6 className="partners_title">
            {ReactHtmlParser(this.state.data.worldwide.subTitle)}
          </h6>
        </>
      );
    }
    return global;
  };

  /**
   * @description
   * Procede ao tratamento do evento de submissão da FORM
   *
   * @param {*} e
   * @param {*} usrData -> dados de utilizador (sem utilizadade : outra estratégia de desenvolvimento)
   */
  handleSubmit = (e, usrData) => {
    e.preventDefault();

    const target = e.target;
    const { lang } = this.state;

    let myForm = document.getElementById("getInTouch");
    let formLoader = document.getElementById("formLoader");

    if (formLoader !== undefined) {
      formLoader.classList.add("l-visible");
    }

    // tratamento dos dados da FORM
    if (myForm !== undefined) {
      let data2Send = new FormData(myForm);

      this.webserver.sendRequest(
        "POST",
        `${lang}/${this.restURI}`,
        (result, xhr) => {
          //this.dealWithAPIReturnedData(result, xhr, myForm);
          dealWithAPIReturnedData(result, xhr, myForm, this);
        },
        data2Send
      );
    } else {
      console.error(this.t("errorHandler").frmNotFound);
    }
  };

  render() {
    return (
      <>
        <div className="body-content_about">
          {this.state.data !== undefined ? this.makeGlobal() : null}
        </div>
      </>
    );
  }
}

export default withTranslation()(AboutGlobal);
