import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { useTranslation } from "react-i18next";

import AppDashboard from "../template/AppDashboard";
import Error404 from "../template/error/Error404";

// TMP
import RestDataSource from "../components/webservices/RestDataSource";
import ErrorBoundary from "./errorHandling/ErrorBoundary";
import { Configs } from "../Toolbox";

/**
 * @description
 * Component onde se trata e/ou executa o render dos vários componentes
 * que compõem a App, e.g.: about / quality / products / downloads / contacts / ...
 *
 * @param {*} props -> props a usar pelo component
 */
const Dashboard = (props) => {
  const {
    i18n: { language },
  } = useTranslation(); // p utilização de i18n

  /**
   * @description
   * Para a execução de pedidos de dados a sistema remoto / API
   * Referencia este elemento para os demais
   */
  const webserver = new RestDataSource(Configs.rest, null, (err) => {
    console.error(err);
  });

  /**
   * Hook equivalente ao componentDidMount para objectos / class
   */
  useEffect(() => {
    // TODO implement
    /* webserver.getData("people/1", (data, xhr) => {
    }); */
  });

  return (
    <ErrorBoundary>
      <Router>
        <Switch>
          <Route exact path="/">
            <Redirect to={`/${language}`}></Redirect>
          </Route>
          <Route
            path={`/:lang([a-z]{2})`}
            render={(props) => (
              <AppDashboard webserver={webserver} {...props} />
            )}
          />
          <Route path="*" render={(props) => <Error404 {...props} />} />
        </Switch>
      </Router>
    </ErrorBoundary>
  );
};

export default Dashboard;
