import React, { Component } from "react";
import { withTranslation } from "react-i18next";
/* import { Configs } from "../../../Toolbox"; */
import {
  extractElementsFromArrayByIsType,
  insertMediaWithAppAbsPath,
} from "../../../Toolbox";
import ReactHtmlParser from "react-html-parser";

import $ from "jquery";

/* import "../../../../src/scss/views/AboutView.scss"; */
import "../../../../src/scss/Timeline.scss";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

class TimeLine extends Component {
  constructor(props) {
    super(props);

    this.state = { lang: null, data: null };

    // p utilização nas acções de tradução
    this.t = props.t;
    this.i18n = null;
  }

  componentDidMount() {
    window.sessionStorage.removeItem("stickyBFM");
    this.t = this.props.t; // p utilização nas acções de tradução
    this.i18n = this.props.i18n;

    let { lang } = this.state;
    lang = this.i18n.language;

    this.setState({ lang });

    // para utilização de JQUERY (OWL)
    window.jQuery = $;
    require("owl.carousel");
  }

  /***
   * @description
   *
   * @param {*} prevProps
   * @param {*} prevState
   */
  componentDidUpdate(prevProps, prevState) {
    let { lang, data } = this.state;

    if (
      "pData" in this.props &&
      "content" in this.props.pData &&
      (data === null ||
        JSON.stringify(this.props.pData.content.history) !==
          JSON.stringify(data)) &&
      Object.keys(this.props.pData).length > 0
    ) {
      lang = this.i18n.language;

      data =
        this.props.pData.content.history !== undefined
          ? this.props.pData.content.history
          : null;

      this.setState({ data, lang }, () => {
        $(".timeline").owlCarousel({
          autoplay: false,
          loop: false,
          margin: 0,
          responsiveClass: true,
          responsive: {
            0: {
              items: 1,
            },
            992: {
              items: 1,
            },
            1200: {
              items: 1,
            },
          },
          dots: true,
          dotsEach: false,
        });

        var lineTime = document.getElementsByClassName("owl-dots");
        lineTime[0].className += " lineDots";

      });
    }
  }

  makePoints = () => {
    let point = null;

    if (this.state.data !== null && this.state.data.poi) {
      point = this.state.data.poi.map((val, key) => {
        let prev = key > 0 ? this.state.data.poi[key - 1] : null;
        let next =
          key < this.state.data.poi.length - 1
            ? this.state.data.poi[key + 1]
            : null;

        // obtem imagem do banner
        let img = extractElementsFromArrayByIsType(val.imgs, "generic");
        // valida resultado obtido e gera o link absoluto da imagem
        img =
          img !== null ? insertMediaWithAppAbsPath(img[0].name, "imgs") : null;

        let alt = img != null ? img[0].alt : null;

        return (
          <div className="item timeline_points" key={val.id}>
            <div className="col-1 lastYear">
              <p>{prev !== null ? prev.subTitle : ""}</p>
            </div>
            <div className="col-10 currentYear">
              <p className="title_of_timeline">
                {this.props.t("menu").history}
              </p>
              <div className="info_timeline">
                <h6 className="current_year_point">{val.subTitle}</h6>
                <h5 className="info_point">{val.title}</h5>
                <div className="info_point desc">
                  {ReactHtmlParser(val.description)}
                </div>

                <div
                  className="logo_point"
                  style={{
                    backgroundImage: "url(" + img + ")",
                  }}
                ></div>
                {/*  <img className="logo_point" src={img} alt={alt} /> */}
              </div>
            </div>
            <div className="col-1 nextYear">
              <p>{next !== null ? next.subTitle : ""}</p>
            </div>
          </div>
        );
      });
    }
    return point;
  };

  render() {
    return (
      <div className="col-12 owl-carousel timeline">
        {this.state.data !== undefined ? this.makePoints() : null}
      </div>
    );
  }
}

export default withTranslation()(TimeLine);
