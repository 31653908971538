import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { withTranslation } from "react-i18next";
import $ from "jquery";

import RestDataSource from "../../components/webservices/RestDataSource";

import "../../../src/scss/views/AboutView.scss";

import AboutUs from "../views/universe/AboutUs";
import AboutGlobal from "../views/universe/AboutGlobal";
import AboutTeam from "../views/universe/AboutTeam";
import AboutQuality from "../views/universe/AboutQuality";
import AboutSinergy from "../views/universe/AboutSinergy";
import AboutNews from "../views/universe/AboutNews";

import HeaderContent from "../HeaderContent";

import {
  /*  Configs, */
  extractElementsFromArrayByIsType,
  insertMediaWithAppAbsPath,
} from "../../Toolbox";

import TimeLine from "./universe/TimeLine";

/**
 * @description
 * Apresentação de conteúdo relacionados com eventual VIEW Sobre Nós // About...
 *
 * @param {object} texts -> p armazenamento dos textos estáticos a apresentar na view
 */
class About extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lang: props.i18n.language,
      data: {},
      headerData: {
        cat_universo: "", // categoria BASE (UNIVERSO PRILUX | ...)
        subcat_universo: "", // SOMOS PRILUX | ...
        titulo_universo: "", // inspirados pela natureza ...
        description_universo: "", // criada em ...
      },
      filterData: null,
      backgroundImage: "",
      childData: null,
      stickyBFM: null, // para elementos STICKYMENU
    };

    this.t = props.t; // tradutor
    this.i18n = null;

    this.logicLatch = false; // TRINCO LÓGICO - FALSE aberto | TRUE fechado

    this.optionId = undefined; // ID da CATEGORIA dos artigos
    this.optionSlug = undefined; // respectivo slug

    //verifica se o match existe e retira a origem da VIEW
    if (this.props.match && this.props.match.params) {
      this.optionId = this.props.match.params.optionId;
      this.optionSlug = this.props.match.params.optionSlug;
    }

    this.restURL = null; // = `${this.lang}/rest/about/content/${this.optionId}`;

    this.webserver = new RestDataSource(); // para comunicação com a API (REST)
    this.restURI = `rest/about/content`; // para utilização nos pedidos de dados à API / URI do pedido
  }

  /**
   * @description
   * Para inicializaçãpo dos componentes e afins após o seu carregamento no DOM
   *
   */
  componentDidMount() {
    window.isFristTimeLoad = true;
    window.sessionStorage.removeItem("stickyBFM");
    this.t = this.props.t; // p utilização nas acções de tradução
    this.i18n = this.props.i18n;

    let { lang } = this.state;
    lang = this.i18n.language;

    this.setState({ lang });
    setTimeout(() => {
      window.scrollTo(0, 44);
    }, 0);

    const requestURI = `${this.i18n.language}/${this.restURI}/${this.optionId}`;

    this.getDataFromAPI(requestURI, this.setBodyContent);

    $.fn.isInViewport = function () {
      var elementTop = $(this).offset().top;
      var elementBottom = elementTop + $(this).outerHeight();

      var viewportTop = $(window).scrollTop();
      var viewportBottom = viewportTop + $(window).height();

      return elementBottom > viewportTop && elementTop < viewportBottom;
    };

    var isCollidedToHeader = false; //isCollidedToSubMenu
    var isCollidedToContentOffSet = 0; //isCollidedToSubMenuOffSet

    $(window).on("resize scroll", function () {
      let $header = $(".header-content");
      let $bodyPage = $(".r-content");
      let $backgroundImage = $(".body_universe");

      if ($bodyPage.isColliding($header, -160)) {
        if (!isCollidedToHeader) {
          isCollidedToHeader = true;
          isCollidedToContentOffSet = $(window).scrollTop();
        }
        let fixedSize =
          $bodyPage.offset().top - $header.outerHeight() - 160 + "px";

        $header.css({
          position: "absolute",
          top: fixedSize,
        });
      }

      if (isCollidedToHeader >= $(window).scrollTop()) {
        $header.css({
          position: "fixed",
          top: "0px",
          transition: "top 0.8s ease-in-out",
        });

        if ($(window).width() >= 768) {
          $header.css({
            top: "30px",
          });
        }

        if ($(window).width() >= 1200) {
          $header.css({
            top: "-30px",
          });
        }

        if ($(window).width() >= 1500) {
          $header.css({
            top: "30px",
          });
        }

        if ($(window).width() >= 1920) {
          $header.css({
            top: "50px",
          });
        }
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    // verifica se o idioma actual é igual ao idioma pré actualização
    // e actualiza o mesmo se assim se verificar necessário, i.e., se
    // os idiomas não coincidirem
    let { lang } = prevState;
    const requestURI = `${this.i18n.language}/${this.restURI}/${this.optionId}`;
    let isSameUrl = false;
    var onwNavBar = this.props.navData;

    if (!window.isFristTimeLoad) {
      return;
    }

    // novo idioma seleccionado, actualiza-o
    if (lang !== this.i18n.language) {
      lang = this.i18n.language;

      this.setState({ lang }, () => {
        // idioma alterado, obtem os dados da API no novo idioma
       // if (this.logicLatch === false) {
          // fecha o trinco para efeitos de acesso ao pedido de
          // novos conteúdos à API
       //   this.logicLatch = true;
          // executa pedido
          this.getDataFromAPI(requestURI, this.setBodyContent);

        let oldUrl = window.sessionStorage.getItem("oldUrl")
            ? window.sessionStorage.getItem("oldUrl")
            : prevProps.match.url,
            newUrl = window.location.href,
            isSameUrl = true;
        if (!newUrl.includes(oldUrl) || window.isFristTimeLoad) {
          isSameUrl = false;
        }

        if (onwNavBar === undefined) {
          onwNavBar = JSON.parse(window.sessionStorage.getItem("navigation"));
        }
       // }
      });
    }


    /**
     * @description
     * stickyMenu
     */
    if (
      onwNavBar &&
      "universe" in onwNavBar &&
      (this.state.stickyBFM === null || !isSameUrl)
    ) {
      let { stickyBFM } = this.state;

      if ("universe" in onwNavBar) {
        stickyBFM = onwNavBar.universe;

        this.setState({ stickyBFM }, () => {
          let navElems = stickyBFM.map((val) => {
            return {
              id: val.id,
              text: val.title,
              uri: `${this.t("menu").about[1]}/${val.id}/${val.slug}`,
              onClick: this.handleStickyMenuItemClick,
            };
          });

          window.isFristTimeLoad = false;
          this.props.setSFMOptions(navElems);
          window.sessionStorage.setItem("stickyBFM", JSON.stringify(navElems));
        });
      }
    }
  }
  /**
   * @description
   * Método responsável por usar o pedido de dados à API... a usar nas life cycle
   * componentDidMount e/ou componentDidUpdate
   */
  getDataFromAPI = (requestURL, callback) => {
    this.webserver.getData(requestURL, callback);
  };

  setBodyContent = (_data, xhr) => {
    this.logicLatch = false;

    if (typeof _data === "object") {
      let { data, backgroundImage, filterData } = this.state;

      data = _data;

      let { headerData } = this.state;

      let img = extractElementsFromArrayByIsType(_data.header.imgs, "generic");
      backgroundImage =
        img !== null ? insertMediaWithAppAbsPath(img[0].name, "imgs") : "";

      switch (this.optionId) {
        //Somos Prilux
        case "224":
          headerData.cat_universo = this.t("menu").about[0];
          headerData.subcat_universo = data.header.title;
          headerData.titulo_universo = data.header.subTitle;
          headerData.description_universo = data.header.description;

          break;
        //Somos Globais
        case "211":
          headerData.cat_universo = this.t("menu").about[0];
          headerData.subcat_universo = data.header.title;
          //headerData.titulo_universo = data.header.subTitle;
          headerData.description_universo = data.header.description;
          break;
        //Somos Equipa
        case "236":
          headerData.cat_universo = this.t("menu").about[0];
          headerData.subcat_universo = data.header.title;
          //headerData.titulo_universo = data.header.subTitle;
          headerData.description_universo = data.header.description;
          break;
        //Somos Qualidade
        case "257":
          headerData.cat_universo = this.t("menu").about[0];
          headerData.subcat_universo = data.header.title;
          headerData.titulo_universo = data.header.subTitle;
          headerData.description_universo = data.header.description;
          break;
        //Somos Sinergias
        case "240":
          headerData.cat_universo = this.t("menu").about[0];
          headerData.subcat_universo = data.header.title;
          headerData.titulo_universo = data.header.subTitle;
          headerData.description_universo = data.header.description;
          break;
        //Somos Notícias
        case "222":
          headerData.cat_universo = this.t("menu").about[0];
          headerData.subcat_universo = data.header.title;
          //headerData.titulo_universo = data.header.subTitle;
          headerData.description_universo = data.header.description;
          break;

        default:
          break;
      }

      if ("filterOptions" in data) {
        filterData = data.filterOptions;
      }

      this.setState({
        data,
        headerData,
        backgroundImage,
        filterData,
      });
    }
  };

  makeHeader = () => {
    const { headerData } = this.state;
    return <HeaderContent headerData={headerData} />;
  };

  makeBody = () => {
    let view = null;
    let { childData } = this.state;
    switch (this.optionId) {
      case "224": // SOMOS PRILUX
        view = <AboutUs pData={this.state.data} />;
        break;

      //Somos Globais
      case "211":
        view = (
          <AboutGlobal
            webserver={this.webserver}
            pData={this.state.data}
            setParentData={this.changeState}
          />
        );
        break;

      //Somos Equipa
      case "236":
        view = (
          <AboutTeam
            webserver={this.webserver}
            pData={this.state.data}
            setParentData={this.changeState}
          />
        );
        this.view = AboutTeam;
        break;

      //Somos Qualidade
      case "257":
        view = (
          <AboutQuality
            webserver={this.webserver}
            pData={this.state.data}
            setParentData={this.changeState}
          />
        );
        this.view = AboutQuality;
        break;

      //Somos Sinergias
      case "240":
        view = <AboutSinergy pData={this.state.data} />;
        break;

      //Somos Notícias
      /*  
       fData -- dados para a utilização do filtro e ordenação de noticias
       pData -- dados na utilização de elementos do body 
       */

      case "222":
        view = (
          <AboutNews
            webserver={this.webserver}
            fData={this.state.filterData}
            pData={this.state.data}
            setParentData={this.changeState}
          />
        );
        break;

      default:
        break;
    }

    return view;
  };

  changeState = (data) => {
    this.setState(data);
  };

  /**
   * @description
   * Tratamento do click em items do sticky menu, se este estiver localizado
   * na própria VIEW faz scroll da mesma para o topo, se estiver localizado em
   * VIEW diferente procede à ancora para a respectiva VIEW
   *
   * @param {*} e eventHandler
   */
  handleStickyMenuItemClick = (e) => {
    const target = e.target;

    // obtem valores de params a partir das props.match
    // se estes valores estiverem presentes no link que foi clicado
    // encaminhar o conteúdo da VIEW para o topo da mesma
    if (target.href.indexOf(this.optionId) >= 0) {
      e.preventDefault();
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  render() {

    return (
      <div
        className="body_universe row"
        style={{ backgroundImage: "url(" + this.state.backgroundImage + ")" }}
      >
        {this.makeHeader()}

        <div className="col-1 col-lg-2 l-content">
          <div>
            <p className="phrase_side"> {this.props.t("views").about.slogan}</p>
          </div>
        </div>
        <div className="col-11 col-lg-10 r-content">{this.makeBody()}</div>
        <>{this.state.childData}</>
        {this.optionId === "224" ? <TimeLine pData={this.state.data} /> : null }
      </div>
    );
  }
}

// HOC para traduções
export default withTranslation()(About);
