import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import "../scss/AppFooter.scss";
import "../scss/AppMenu.scss";

import SocialMediaIcons from "./SocialMedia";
import FinancingSystems from "./FianacingSystems";
import {
  //AppMenu,
  UniverseMenu,
  StoreAndDownloads,
  Rgpd,
} from "./AppMenu";

import ReactHtmlParser from "react-html-parser";

const AppFooter = (props) => {
  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState();

  useEffect(() => {
    // Actualiza o idioma usado na app... esta var poderá servir como
    // Lifting Up State e por esta via implicar o render dos components
    // child. Neste momento julga-se últil para obter os conteúdos
    // dinâmicos a partir de uma API ou BO
    setLang(i18n.language);
  }, [lang, setLang]);

  var propsFooter = props.navData;

  if (propsFooter && propsFooter !== null && propsFooter !== undefined) {
    /**Link for Corte Laser*/
    if (
      propsFooter.lazerCut &&
      propsFooter.lazerCut !== null &&
      propsFooter.lazerCut !== undefined
    ) {
      var idLaser = propsFooter.lazerCut[0].id;
      var slugLaser = propsFooter.lazerCut[0].slug;
      var urlLaser = idLaser + "/" + slugLaser + "/#f" + idLaser;
    }


    /**Link for Services */
    if (
      propsFooter.services &&
      propsFooter.services !== null &&
      propsFooter.services !== undefined
    ) {
      var idServ = propsFooter.services[0].id;
      var slugServ = propsFooter.services[0].slug;
      var urlService = idServ + "/" + slugServ + "/#f" + idServ;
    }
  }

  return (
    <footer className="app-footer row">
      <div className="col-12">
        <header>
          <div className="line"></div>
          <img alt="" src="/assets/imgs/metalworking_2.svg" className="logo_prilux_metalworking" />
          <p className="slogan_prilux">
            {ReactHtmlParser(t("views").footer.slogan)}
          </p>
        </header>
      </div>
      <div className="nav-container row">
        <div className="app-footer-nav col-12 col-sm-6 col-lg-3">
          {/* <AppMenu /> */}
          <div className="app-menu">
            <ul>
              <li className="">
                <a href={`/${lang}/${t("menu").laserCut[1]}/${urlLaser}`}>
                  {t("menu").laserCut[0]}
                </a>
              </li>

              <li className="">
                <a href={`/${lang}/${t("menu").bending[1]}`}>
                  {t("menu").bending[0]}
                </a>
              </li>

              <li className="">
                <a href={`/${lang}/${t("menu").services[1]}/${urlService}`}>
                  {t("menu").services[0]}
                </a>
              </li>

              <li className="">
                <a href={`/${lang}/${t("menu").projects[1]}`}>
                  {t("menu").projects[0]}
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="app-footer-wwa col-12 col-sm-6 col-lg-3">
          <UniverseMenu
            use="footer"
            navData={props.navData ? props.navData.universe : null}
          />
        </div>
        <div className="app-footer-b2b col-12 col-sm-6 col-lg-2">
          <StoreAndDownloads />
        </div>
        <div className="app-footer-cntct col-12 col-sm-6  col-lg-2">
          <a href={`/${lang}/${t("menu").contacts[1]}`}>
            {t("menu").contacts[0]}
          </a>
          <SocialMediaIcons />
        </div>
        <div className="app-footer-rgpd col-12 col-lg-2">
          <Rgpd />
        </div>
      </div>
      <FinancingSystems />
      <div className="root-div">
        <a href="#root" className="go-top">
          <img className="s2" src="/assets/imgs/Seta-botao-3.png" alt="seta" />
          <img className="s1" src="/assets/imgs/Seta-botao-4.png" alt="seta" />
        </a>
      </div>

    </footer>
  );
};

export default AppFooter;
