import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import RestDataSource from "../../components/webservices/RestDataSource";
import { Configs } from "../../Toolbox";

import "../../scss/FundedProjectsView.scss";

//DownloadsView
import { insertMediaWithAppAbsPath } from "../../Toolbox";

/**
 * @description
 * Apresentação de conteúdo relacionados com eventual VIEW Sobre Nós // About...
 *
 * @param {object} texts -> p armazenamento dos textos estáticos a apresentar na view
 */
class FundedProjectsView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lang: null,
      data: {},
    };

    this.t = props.t; // para efeitos de tradução
    this.i18n = props.i18n; // para efeitos de tradução
    this.logicLatch = false; // TRINCO LÓGICO - FALSE aberto | TRUE fechado

    this.optionId = undefined; // ID da CATEGORIA dos artigos
    this.optionSlug = undefined; // respectivo slug

    //verifica se o match existe e retira a origem da VIEW
    if (
      this.props.match &&
      this.props.match.params &&
      "optionId" in this.props.match.params &&
      "optionSlug" in this.props.match.params
    ) {
      this.optionId = this.props.match.params.optionId;
      this.optionSlug = this.props.match.params.optionSlug;
    }

    this.webserver = new RestDataSource(); //para a comunicação com a API(REST)
    this.restURI = `rest/fundedprojects/content/460`; //para utilização nos pedidos de dados à API / URI do pedido
  }

  /**
   * Hook equivalente ao componentDidMount
   */

  componentDidMount() {
    window.sessionStorage.removeItem("stickyBFM");
    let { lang } = this.state;
    lang = this.i18n.language;

    //atualiza idioma e pede dados à API
    this.setState({ lang });
  }

  /**
   * @description
   * Actualiza dados resultantes de determinada acção sobre a APP
   * acção q carece de UPDATE de informação ao USER
   *
   * @param {*} prevProps
   * @param {*} prevState
   */
  componentDidUpdate(prevProps, prevState) {
    let { lang } = prevState;

    //url -->  `${this.i18n.language}/${this.restURI}/${this.optionId}`
    let requestURI = this.makeURI();

    // novo idioma seleccionado, actualiza-o
    if (lang !== this.i18n.language) {
      lang = this.i18n.language;

      this.setState({ lang }, () => {
        // idioma alterado, obtem os dados da API no novo idioma
        if (this.logicLatch === false) {
          // fecha o trinco para efeitos de acesso ao pedido de
          // novos conteúdos à API
          this.logicLatch = true;
          // executa pedido
          this.getDataFromAPI(this.makeURI());
        }
      });
    }
  }

  makeURI() {
    let requestURI = `${this.i18n.language}/${this.restURI}`;

    //verifica se existe optionId e optionSlug e acrescenta-os ao URI
    if (this.optionId !== undefined && this.optionSlug !== undefined) {
      requestURI += `${this.optionId}/${this.optionSlug}`;
    }

    return requestURI;
  }

  /**
   * @description
   * Para obter dados da API
   *
   * @param {*} requestURL
   */

  getDataFromAPI = (requestURL) => {
    this.webserver.getData(requestURL, (result, xhr) => {
      this.logicLatch = false;

      if (xhr.status === 200 && "data" in xhr && typeof xhr.data === "object") {
        let { data } = this.state;
        data = result;

        this.setState({ data });
      }
    });
  };

  makeHeaderPage = () => {
    let headerProj = null;

    if (
      this.state.data.content !== null &&
      this.state.data.content !== undefined
    ) {
      headerProj = this.state.data.content.map((val, key) => {
        return (
          <div className="header-projects" key={val.id}>
            <h1 className="title-projects-header">{val.title}</h1>
            <div className="description-projects-header">{val.description}</div>
          </div>
        );
      });
    }
    return headerProj;
  };

  makeBodyPage = () => {
    let detailProject = null;
    if (
      this.state.data.content !== null &&
      this.state.data.content !== undefined &&
      this.state.data.content[0] &&
      this.state.data.content[0].docs &&
      this.state.data.content[0].docs !== null
    ) {
      detailProject = this.state.data.content[0].docs.map((val, key) => {
        let link = Configs.media_prefix + Configs.media.docs + val.name;
        return (
          <li className="each-project" key={key}>
            <a
              /* href={"https://prilux.incograf.eu/assets/docs/" + val.name} */
              href={link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="img-pdf"
                src="/assets/imgs/PDF.png"
                alt="icon_download"
              />
              <div className="col-10 col-lg-9 col-xl-10 info-project">
                <h6 className="title-project">{val.alt}</h6>
              </div>
            </a>
          </li>
        );
      });
      return detailProject;
    }
  };

  render() {
    return (
      <div className="container-fluid ">
        {this.state.data !== undefined ? this.makeHeaderPage() : null}
        <div className="body-projects">
          <ul className="list-of-projects">
            {this.state.data !== undefined ? this.makeBodyPage() : null}
          </ul>
        </div>
      </div>
    );
  }
}

export default withTranslation()(FundedProjectsView);
