//import React, { useEffect, useState } from "react";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import Forms from "../../components/Forms";
import AlertMessage from "../AlertMessage";

import RestDataSource from "../../components/webservices/RestDataSource";
import "../../scss/ContactsView.scss";

//import ReactHtmlParser from "react-html-parser";

import { Configs } from "../../Toolbox";
import HtmlParser from "react-html-parser";

/**
 * @description
 * Apresentação de conteúdo relacionados com eventual VIEW Contactos // Contacts...
 *
 * @param {object} texts -> p armazenamento dos textos estáticos a apresentar na view
 */
class Contacts extends Component {
  constructor(props) {
    super(props);

    /**
     * campos de formulário para pedir ajuda
     * são todos mandatórios
     **/
    this.fields = [
      Object.assign({ required: "required" }, Configs.form_fields.subject), //assunto
      Object.assign({ required: "required" }, Configs.form_fields.name), //nome
      Object.assign({ required: "required" }, Configs.form_fields.email), //email
      Object.assign({ required: "required" }, Configs.form_fields.mobile), //telefone
      Object.assign({ required: "required" }, Configs.form_fields.remarks), //mensagem
      Object.assign({ required: "required" }, Configs.form_fields.rgpdconsent), //consentimento de rgpd
      Object.assign({ required: "required" }, Configs.form_fields.emarketing), //consentimento de marketing
    ];

    // define o subject da mensagem
    this.fields[0].value = this.props.t("menu").contacts[0];

    this.state = {
      lang: null,
      data: {},
      frmFields: this.fields,
      submitRes: null, // para registo de mensagem de retorno da FORM
    };

    this.webserver = new RestDataSource(); //para comunicação com a API (REST)
    this.restURI = `rest/contacts/content/`; //para utilização nos pedidos de dados à API / URI do pedido
    this.restSendContactURI = `rest/contactos/send`; //para utilização nos pedidos de dados à API / URI do pedido

    // p utilização nas acções de tradução
    this.t = null;
    this.i18n = null;

    this.logicLatch = false; // TRINCO LÓGICO - FALSE aberto | TRUE fechado
  }

  /**
   * @description
   * Para inicialização dos componentes e afins após o seu carregamento no DOM
   */
  componentDidMount() {
    window.sessionStorage.removeItem("stickyBFM");
    this.t = this.props.t; // p utilização nas acções de tradução
    this.i18n = this.props.i18n;

    let { lang } = this.state;
    lang = this.i18n.language;

    this.setState({ lang });

    // altera telemóvel para telefone
    if (this.fields[3]) {
      this.fields[3]["data-i18n-key"] = "phone";
    }

    // actualiza info para a form de apply 4 job...
    // o campo remarks neste caso em particular não é remarks é Comments
    // actualiza essa info
    if (this.fields[4]) {
      this.fields[4]["data-i18n-key"] = "yourmsg";
    }

    const requestURI = `${this.i18n.language}/${this.restURI}`;

    this.logicLatch = true;
    this.getDataFromAPI(requestURI);
  }

  /**
   * @description
   * Verifica se existem alterações nas props e/ou state e executa
   * as necessárias alterações em conformidade, exemplo, quando se
   * altera o idioma do site poderá ser necessário obter novos termos
   * no novo idioma para preenchimento do layout...
   * caso se verifique que os mesmos já existam dever-se-à evitar a
   * utilização deste recurso, isto porque, poderá estar a criar um
   * conjunto de pedidos desnecessários e que eventualmente tornarão
   * esta solução mais lenta
   *
   * @param {object} prevProps
   * @param {object} prevState
   */
  componentDidUpdate(prevProps, prevState) {
    // verifica se o idioma actual é igual ao idioma pré actualização
    // e actualiza o mesmo se assim se verificar necessário, i.e., se
    // os idiomas não coincidirem
    let { lang } = prevState;
    const requestURI = `${this.i18n.language}/${this.restURI}`;

    //novo idioma seleccionado, actualiza-o
    if (lang !== this.i18n.language) {
      lang = this.i18n.language;

      this.setState({ lang }, () => {
        //idioma alterado, obtem os dados da API no novo idioma
        if (this.logicLatch === false) {
          //fecha o trinco para efeitos de acesso ao pedido de
          //novos conteúdos à API
          this.logicLatch = true;
          //executa o pedido
          this.getDataFromAPI(requestURI);
        }
      });
    }
  }

  /**
   * @description
   * Método responsavel por usar o pedido de dados à API... a usar nas life cycle
   * componentDidMount e/ou componentDidUpdate
   */
  getDataFromAPI = (requestURL) => {
    this.webserver.getData(requestURL, (result, xhr) => {
      this.logicLatch = false;

      if (xhr.status === 200 && "data" in xhr && typeof xhr.data === "object") {
        /*Faz um scroll para o início da página caso
         * estejamos a carregar no link localizado no footer
         */
        var element = document.querySelector(".wrapper");
        element.scrollIntoView({
          top: 0,
          behavior: "smooth",
        });

        let { data } = this.state;
        data = result;

        this.setState({ data });
      }
    });
  };

  makeIntroContact = () => {
    if (this.state.data !== null && this.state.data.sede) {
      let sede = this.state.data.sede[0];

      return (
        <div className="contactUs">
          <h6 className="ContactTitle">
            {this.props.t("views").contacts.title}
          </h6>
          <ul>
            <li className="visitUs">{this.props.t("views").contacts.visit}</li>
            <li className="info_sede sd_name">{sede.title}</li>
            <li className="info_sede sd_address">{sede.others}</li>
            <li className="info_sede sd_phone">{sede.phone}</li>
            <li className="info_sede sd_email">{sede.email}</li>
          </ul>
        </div>
      );
    }
  };

  makeComerciais = () => {
    let comercial = null;
    if (this.state.data !== null && this.state.data.comercial) {
      comercial = this.state.data.comercial.map((val, key) => {
        if (val.priority >= 2) {
          return (
            <ul className="eachCommercial" key={val.id}>
              <li>{val.title}</li>
              <li>{val.others}</li>
              <li>{val.email}</li>
              <li>{val.phone}</li>
            </ul>
          );
        }
      });
    }
    return comercial;
  };

  makeHorario = () => {
    let work = null;
    if (this.state.data !== null && this.state.data.horarios) {
      work = this.state.data.horarios[0];

      return (
        <div className="horario_info">
          <h5 className="horario_locais">{HtmlParser(work.description)}</h5>
        </div>
      );
    }
    return work;
  };

  makeOtherCompanies = () => {
    let otherCompany = null;

    if (this.state.data !== null && this.state.data.filiais) {
      otherCompany = this.state.data.filiais.map((val, key) => {
        if (val.priority > 0) {
          return (
            <ul className="eachCompany" key={val.id}>
              <li className="name_otherCompany">{val.title}</li>
              <li>{HtmlParser(val.others)}</li>
              <li>{val.phone}</li>
              <li className="email_otherCompany">{val.email}</li>
              <li className="website_otherCompany">{val.website}</li>
            </ul>
          );
        }
      });
      return otherCompany;
    }
  };

  makeMetalWorking = () => {
    let arrayFilial = [];
    //arrayFilial -> testArray
    let arrayConjuntoMetal = [];
    //arrayConjuntoMetal -> tests12

    if (this.state.data !== null && "filiais" in this.state.data) {
      arrayFilial = this.makeMetalWorkingFilial();
    }

    if (this.state.data !== null && "comercial" in this.state.data) {
      arrayConjuntoMetal = this.makeMetalWorkingComercial();
    }

    return (
      <div className="AllMetalWorking">
        {arrayFilial.concat(arrayConjuntoMetal)}
      </div>
    );
  };

  makeMetalWorkingFilial() {
    let otherFilial = this.state.data.filiais
      .filter((val) => {
        return val.priority < 0;
      })
      .map((val, key) => {
        return (
          <ul key={val.id}>
            <li className="name_otherCompany">{val.title}</li>
            <li className="address_otherCompany">{HtmlParser(val.others)}</li>
            <li className="phone_otherCompany">{val.phone}</li>
            <li className="email_otherCompany">{val.email}</li>
          </ul>
        );
      });

    return otherFilial;
  }

  makeMetalWorkingComercial() {
    let Comercial1 = this.state.data.comercial
      .filter((val) => {
        return val.priority < 0;
      })
      .map((val, key) => {
        return (
          <ul className="comercialMetalWorkings" key={val.id}>
            <li>{val.title}</li>
            <li>{HtmlParser(val.others)}</li>
            <li>{val.email}</li>
            <li>{val.phone}</li>
          </ul>
        );
      });

    return Comercial1;
  }

  makeForm = () => {
    return (
      <>
        <Forms
          id="weHelpYou"
          fields={this.state.frmFields}
          //fReset={!this.state.openStats}
          onSubmit={this.handleSubmit}
        />
        <AlertMessage
          onClose={this.handleCloseAlert}
          resStats={this.state.submitRes}
          myClass={`submit-status alert alert-dismissible fade ${
            this.state.submitRes !== null && this.state.submitRes.msg
              ? "show"
              : ""
          }`}
        >
          <span
            dangerouslySetInnerHTML={{
              __html:
                this.state.submitRes !== null && this.state.submitRes.msg
                  ? this.state.submitRes.msg
                  : "",
            }}
          ></span>
        </AlertMessage>
      </>
    );
  };

  /**
   * @description
   * Procede ao tratamento do evento de submissão da FORM
   *
   * @param {*} e
   * @param {*} usrData -> dados de utilizador (sem utilizadade : outra estratégia de desenvolvimento)
   */
  handleSubmit = (e, usrData) => {
    e.preventDefault();

    const target = e.target;
    const { lang } = this.state;

    let myForm = document.getElementById("weHelpYou");
    let formLoader = document.getElementById("formLoader");

    if (formLoader !== undefined) {
      formLoader.classList.add("l-visible");
    }

    if (myForm !== undefined) {
      let data2Send = new FormData(myForm);

      if (data2Send instanceof FormData && this.webserver) {
        this.webserver.sendRequest(
          "POST",
          `${lang}/${this.restSendContactURI}`,
          (result, xhr) => {
            if (formLoader !== undefined) {
              formLoader.classList.remove("l-visible");
            }

            this.dealWithAPIReturnedData(result, xhr);
          },
          data2Send
        );
      }
    }
  };

  dealWithAPIReturnedData = (result, xhr) => {
    if (
      xhr.status === 200 &&
      "res" in result &&
      result.res !== undefined &&
      result.res !== null
    ) {
      let { submitRes } = this.state;
      submitRes = result;

      // procede ao reset da FORM se resposta for positiva...
      if (submitRes.res) {
        document.getElementById("weHelpYou").reset();
      }

      this.setState({ submitRes });
    }
  };

  /**
   *
   * @param {*} res
   */
  handleCloseAlert = (res) => {
    // ao fechar a mensagem de STATUS limpa a STATE no que à mensagem do servidor
    // diz respeito
    let { submitRes } = this.state;
    submitRes = null;
    // altera o estado da mensagem de retorno do processo de envio ao servidor
    this.setState({ submitRes });
  };

  render() {
    return (
      <div className="body_contacts">
        <div className="container">
          <div className="row">
            <div className="col-xl-6">
              {this.state.data !== undefined ? this.makeIntroContact() : null}
            </div>
            <div className="col-xl-6">
              {this.state.data !== undefined ? this.makeHorario() : null}
            </div>
            <div className="col-xl-6">
              <div className="allCommercials">
                {this.state.data !== undefined ? this.makeComerciais() : null}
              </div>
            </div>
            <div className="col-xl-6 has-images-mobile">
              <h6 className="titleHelp">
                {this.props.t("views").contacts.help}
              </h6>
              {this.state.data !== undefined ? this.makeForm() : null}
            </div>
          </div>
        </div>
        <a
          className="anchor_map"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.google.pt/maps/place/Prilux+-+Com%C3%A9rcio,+Constru%C3%A7%C3%A3o+e+Obras+P%C3%BAblicas,+Lda./@40.4838563,-8.6670874,363m/data=!3m1!1e3!4m5!3m4!1s0xd23baaf8082464b:0xa8d9476b1daf941c!8m2!3d40.483756!4d-8.6661348?hl=pt-PT"
        >
          <img
            className="img_map"
            alt="Localização das instalações da Prilux"
            src="../assets/imgs/mapa_contactos.png"
          />
        </a>

        <div className="endContacts">
          <h6 className="ContactSubTitle">
            {this.props.t("views").contacts.subTitle}
          </h6>
          <div className="allCompany">
            {this.state.data !== undefined ? this.makeOtherCompanies() : null}
          </div>
        </div>
      </div>
    );
  }
}

// HOC para traduções
export default withTranslation()(Contacts);
