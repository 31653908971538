import React, {
  Component,
  useState /*, useEffecthis*/,
  useEffect,
} from "react";
import { NavLink, Link, Route, withRouter } from "react-router-dom";
import { withTranslation, useTranslation } from "react-i18next";
import BudgetForm from "./forms/PopUpForm"; // FORM para orçamentos

import "../scss/AppMenu.scss";
import { Configs } from "../Toolbox";

import RestDataSource from "../components/webservices/RestDataSource";
import LanguageSelector from "./LanguageSelector";

import AppSearchBar from "./AppSearchBar";
import "../scss/AppSearchBar.scss";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faChevronDown } from "@fortawesome/free-solid-svg-icons";

import $ from "jquery";

library.add(faBars, faChevronDown);

/**
 * @description
 * Tratamento do menu principal (STICKYBAR)
 *
 * props -> use [stickybar|footer]
 *
 * @param {*}
 */
class AppMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      //lang: props.i18n.language,
      lang: null,
      data: {
        menu: null,
        //mouseOverClass: "", // define a class para o estado de mouseover sobre o menu
        //nailedSticky: false, // define se a stickybar está fixa ou não
        //searchStats: false, // TRUE search ABERTO | search FECHADO
      },
      mouseOverClass: "", // define a class para o estado de mouseover sobre o menu
      //prevMOClass: "",
      prodGhouseClass: "",
      nailedSticky: false, // define se a stickybar está fixa ou não
      searchStats: false, // TRUE search ABERTO | search FECHADO
      menuBodyOpenStats: false, // TRUE para menu aberto | FALSO c.c. (caso contrário)
      wClicked: null,
      universeRootURI: "", // /224/somos-prilux
      laserRootURI: "", // /461/corte-laser
      quinagemRootURI: "", // /462/quinagem
      view: "",
      bestSeller: [],
    };

    this.menuBodyOpenClass = "mb-opened"; // class p estilização de menuBody qdo aberto
    this.subMenuOpenStats = "sm-opened"; // class p estilização do(s) submenu qdo aberto(s)

    this.mainBar = null; // = document.getElementsByClassName("main-bar")[0];
    this.menuBody = null; // identificador do corpo do menu
    this.stickybar = null; // identificador da stickybar
    this.stickybarClientRect = null;
    this.scrollLimit2Fix = null; // limite para a fixação da stickybar

    // mobile to desktop device limit
    this.mobile2Desktop = 992;

    // para registo da largura da janela em momento anterior a um resize
    this.prevWidth = window.innerWidth;

    window.onscroll = this.handleWindowScroll; // tratamento do evento de SCROLL da janela do browser
    window.onresize = this.handleWindowResize; // tratamento do evento resize da janela do browser

    this.webserver = new RestDataSource(); // para comunicação com a Api (REST)
    this.restURI = `rest/navigation/`; // para utilização nos pedidos de dados à API / URI do pedido
    /* this.webserver = new RestDataSource(Configs.rest, null, (err) => {
      console.error(err);
    }); */

    // para utilização nas acções de tradução
    this.t = null;
    this.i18n = null;

    this.logicLatch = false; // TRINCO LÓGICO - FALSE aberto | TRUE fechado
  }

  /**
   * get serviços => 258
   * get estufas => 278
   * get automatismos => 276
   * get produtos => 198 *
   * get projectos => 265 *
   * get universo => 210
   * get corte laser => 461
   * get quinagem => 462
   */
  componentDidMount() {
    this.t = this.props.t; // p utilização nas acções de tradução
    this.i18n = this.props.i18n;

    let { lang, view } = this.state;
    lang = this.i18n.language;

    /**
     * Define qual a view que se está a ver...
     */
    view = this.props.location.pathname.substring(1).split("/")[1];

    /**
     * existe uma CLASS chamada servicos o que provoca conflitos...
     */
    view = view === this.props.t("menu").about[1] ? view : "";

    this.setState({ lang, view }, () => {}); // actualização do idioma em utilização

    // preparação do LINK para o pedido sobre a Api (REST)
    const requestURI = `${this.i18n.language}/${this.restURI}`;

    /**
     * Obtem opções de menu do servidor
     */
    if (this.logicLatch === false) {
      this.getDataFromAPI(requestURI);
    }

    this.mainBar = document.getElementsByClassName("main-bar")[0];
    //this.menuBody = document.getElementsByClassName("menu-body")[0];
    this.stickybar = document.getElementById("stickybar");

    this.stickybarClientRect = this.stickybar.getBoundingClientRect();

    // definição do limite da medida p fixar a stickybar, i.e., após o scrool
    // da janela ultrapassar 40% do limite da sua altura. Se valor > a este limiar
    // fica componente no topo da janela, se valor < ao limiar liberta componente e mostra
    // área de menu e área de acesso a DOWNLOADS e CONTACTOS
    this.scrollLimit2Fix = (this.stickybarClientRect.height * 40) / 100;
  }

  /***
   * @description
   * Para tratamento dos processo de actualização de contetúdos da App qdo se verificar
   * um processo de life cycle UPDATE, por exemplo, actualizar conteúdos qdo se verifica
   * alteraçãodo idioma em utilização
   *
   * @param {*} prevProps -> propriedades anterior à actualização
   * @param {*} prevState -> STATE anterior à actualização
   */
  componentDidUpdate(prevProps, prevState) {
    // verifica se o idioma actual é igual ao idioma pré actualização
    // e actualiza o mesmo se assim se verificar necessário, i.e., se
    // os idiomas não coincidirem
    let { lang, view } = prevState;
    const requestURI = `${this.i18n.language}/${this.restURI}`;

    /**
     * Actualiza a view em utilização por forma a alterar a stickybar se assim
     * se justificar: UNIVERSO e PROJECTOS
     */
    let _view = window.location.href
      .substr(window.location.href.indexOf(this.props.i18n.language))
      .split("/")[1];

    if (_view !== undefined) {
      if (_view.indexOf("#") > 0) {
        _view = _view.substr(0, _view.indexOf("#"));
      }

      if (_view !== undefined && _view !== view) {
        view = _view;
        this.setState({ view });
      }
    }

    // novo idioma seleccionado, actualiza-o
    if (lang !== this.i18n.language) {
      lang = this.i18n.language;

      this.setState({ lang }, () => {
        // idioma alterado, obtem os dados da API no novo idioma
        if (this.logicLatch === false) {
          // fecha o trinco para efeitos de acesso ao pedido de
          // novos conteúdos à API
          this.logicLatch = true;
          // executa pedido
          this.getDataFromAPI(requestURI);
        }
      });
    }

    /**
     * verifica se a janela / dashboard foi clicado...
     */
    if (this.props.wClicked !== null) {
      this.handleWindowClick(this.props.wClicked);
    }

    if (prevProps.location.pathname !== window.location.pathname) {
      window.sessionStorage.setItem("oldUrl", prevProps.location.pathname);
    }
  }

  /**
   * @description
   * Método responsavel por usar o pedido de dados à API... a usar nas life cycle
   * componentDidMount e/ou componentDidUpdate
   *
   * @param {*} requestURL
   */
  getDataFromAPI = (requestURL) => {
    this.logicLatch = true;

    this.webserver.getData(requestURL, (result, xhr) => {
      this.logicLatch = false;

      // trata resultado obtido no pedido à API
      if (xhr.status === 200 && xhr.data && typeof result === "object") {
        let { data, universeRootURI, laserRootURI } = this.state;

        data.menu = result;
        //universeRootURI
        /**
         * Neste momento o conteúdo do menu fica acessível, pelo que se pode
         * alterar o evento click do menu UNIVERSO para ir "cair" no SOMOS PRILUX,
         * i.e., no primeiro elemento da pilha que se obtem do servidor
         */
        if (
          "universe" in result &&
          Array.isArray(result.universe) &&
          result.universe.length > 0
        ) {
          universeRootURI = `/${result.universe[0].id}/${result.universe[0].slug}`;
        }

        //laserRootURI // laser corte
        // situação análoga ao LASER
        if (
          "laser" in result &&
          Array.isArray(result.laser) &&
          result.laser.length > 0
        ) {
          laserRootURI = `/${result.laserCut[0].id}/${result.laserCut[0].slug}/#f${result.laserCut[0].id}`;
        }


        this.setState({ data, universeRootURI, laserRootURI });
        window.sessionStorage.setItem("navigation", JSON.stringify(result));
        this.props.navData(result);
      }
    });
  };

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * AREA DE CONSTRUCAO DE ELEMENTOS DO MENU E RESPECTIVOS EVENTOS *
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  /**
   *
   * @param {*} sector
   * @param {*} urlUri
   */
  makeMenu = (sector, urlUri = "") => {
    let result = null;
    let originalurlUri = urlUri;

    if (this.state.data.menu[sector]) {
      result = this.state.data.menu[sector].map((val) => {
        let tmp =
          val.related && Array.isArray(val.related) && val.related.length > 0
            ? val.related.slice(2)
            : [];

        urlUri = originalurlUri;
        // vamos "transformar" o que é estufa num serviço de acordo com
        // indicações da cliente....
        if (val.code.trim().length > 0) {
          switch (val.code.trim()) {
            case "laser":
              urlUri = urlUri + "-" + this.t("menu").laserCut[1];
              break;
            case "servicos":
              urlUri = urlUri + "-" + this.t("menu").services[1];
              break;
            case "estufas":
              urlUri = this.t("menu").suporte_ghouse[1];
              break;
            default:
              urlUri = originalurlUri;
              break;
          }
        } else {
          urlUri = originalurlUri;
        }

        var componentURL = "";
        if ("offspring" in val && val.offspring && val.offspring.length > 0) {
          let firstComponent = val.offspring[0];
          componentURL = `${firstComponent.id}/${firstComponent.slug}/#f${firstComponent.id}`;
        }

        return (
          <div
            key={val.id}
            id={val.id}
            className="col-12 col-xl-2 menu-item"
            onMouseOver={this.handleMouseOverMenuItem}
            onMouseOut={this.handleMouseOutMenuItem}
          >
            <span
              className="sub-menu-body-title"
              onClick={
                val.offspring !== undefined && val.offspring.length > 0
                  ? this.handleSubMenuClick
                  : null
              }
            >
              <a
                href={
                  sector === "services" || sector === "laser"
                    ? `/${this.state.lang}/${urlUri}/${val.id}/${val.slug}/#f${val.id}`
                    : `/${this.state.lang}/${urlUri}/${val.id}/${val.slug}/${componentURL}`
                }
              >
                {/*  <a href={`/${this.state.lang}/${urlUri}/${val.id}/${val.slug}`}> */}
                {val.title}
                {val.offspring !== undefined &&
                Array.isArray(val.offspring) &&
                val.offspring.length > 0 ? (
                  <FontAwesomeIcon
                    className="sub-item-chevron"
                    icon="chevron-down"
                    size="lg"
                  />
                ) : (
                  ""
                )}
              </a>
            </span>

            {val.offspring !== undefined &&
            Array.isArray(val.offspring) &&
            val.offspring.length > 0
              ? this.makeSubMenu(
                  val.offspring,
                  `${urlUri}/${val.id}/${val.slug}`
                )
              : ""}
          </div>
        );
      });
    }

    //

    return result;
  };

  /**
   *
   * @param {*} e
   */
  handleMouseOverMenuItem = (e) => {
    // referencia o elemento actual do ...
    const target = e.target.closest(".menu-item");

    $(".menu-item--lft-cont")
      .find(".sub-items-container")
      .removeClass("visible");
    $(".menu-item--lft-cont .sub-items-container .visible").css("opacity", "0");

    var childs = $(target).find(".sub-items-container");
    childs.addClass("visible");

    this.dealWithMouseOverMenuForBestSeller(target);

    return;
  };

  /**
   *
   * @param {*} e
   */
  handleMouseOutMenuItem = (e) => {
    const target = e.target.closest(".menu-item");
    this.dealWithMouseOutMenuForBestSeller(target);
  };

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
   * AREA DE CONSTRUCAO DE ELEMENTOS DOS SUBMENU E RESPECTIVOS EVENTOS *
   * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

  /**
   * @description
   * Para a construção de elementos do submenu...
   *
   * @param {*} menuItems
   * @param {*} urlUri
   */
  makeSubMenu = (menuItems, urlUri, menuSpeciel = null) => {
    console.log(menuItems, urlUri);

    return (
      <div
        className="sub-items-container"
        onMouseOver={this.handleMouseOverSubItemContainer}
        onMouseOut={this.handleMouseOutSubItemContainer}
      >
        {menuItems.map((val) => {
          /* Variável para tratamento de elementos das Estufas
           * do tipo produto
           */
          menuSpeciel = `${Configs.base_url}/${this.state.lang}/${
            this.t("menu").ghouse[1]
          }/${val.id}/${val.slug}/#f${val.id}`;

          let urlDiferent = this.props.location.pathname
            .substring(1)
            .split("/")[1];

          return (
            <div
              id={val.id}
              key={val.id}
              className="sub-item"
              onMouseOver={this.handleMouseOverSubMenuItem}
              onMouseOut={this.handleMouseOutSubMenuItem}
            >
              <a
                href={`/${this.state.lang}/${urlUri}/${val.id}/${val.slug}/#f${val.id}`}
                onClick={this.handleStickyMenuItemClick}
              >
                {val.title}
              </a>
            </div>
          );
        })}
      </div>
    );
  };

  /**
   * @description
   * Trata do evento de click nas opções de menu que possuem sub-opções
   *
   * @param {*} e
   */
  handleSubMenuClick = (e) => {
    if (window.mobileAndTabletCheck() !== "Desktop") {
      e.preventDefault();
    }

    const target = e.target.closest("a");
    let menuItem = target.closest(".menu-item");

    // fecha todos os elementos do mesmo nível
    let menuItemParent = menuItem.parentNode;
    let childs = menuItemParent.querySelector(".sbm-opened");

    //if (childs) childs.classList.remove("sbm-opened");

    const menuItemHeight = menuItem.offsetHeight;
    let childsQty = null;

    const setContainerHeight = (sIC, refVal) => {
      sIC.style.height = `${refVal}px`;
    };

    // referência o container dos sub-items
    let subItemsContainer = menuItem.querySelector(".sub-items-container");

    if (subItemsContainer) {
      childsQty = subItemsContainer.childNodes.length;
    }

    let newHeight = childsQty * menuItemHeight;
    let refValue = newHeight / childsQty;

    if (menuItem.classList.contains("sbm-opened")) {
      menuItem.classList.remove("sbm-opened");
      let totalH = subItemsContainer.offsetHeight;

      if (subItemsContainer) {
        for (let i = 1; i <= childsQty; i++) {
          totalH = subItemsContainer.offsetHeight - menuItem.offsetHeight;
          setTimeout(setContainerHeight(subItemsContainer, totalH), 100 * i);
        }
      }
    } else {
      // atribui class sm-opened para abrir os componentes de nível inferior
      menuItem.classList.add("sbm-opened");
      // no caso do menu estar a abrir, atribuí ao container do menu uma altura
      // equivalente ao nr. de elementos da lista * altura de cada um dos elementos
      // da mesma => sub-items-container (height) = calc(N sub-item * 38px)

      // referência o container dos sub-items
      //let subItemsContainer = menuItem.querySelector(".sub-items-container");

      if (subItemsContainer && childsQty) {
        //const menuItemHeight = menuItem.offsetHeight;
        //const childsQty = subItemsContainer.childNodes.length;
        subItemsContainer.style.height = `${newHeight}px`;
      }
    }
  };

  /**
   * @description
   * Tratamentos dos eventos relacionado com o passar do rato sobre os subitens do menu....
   *
   * Exemplo:
   * |-FILTRAGEM
   *  |- FILTROS MX...
   *  |- FILTROS DE LAMELAS
   *  |- FILTRO METÁLICOS
   *  |- CENTRAIS DE FILTRAGEM
   *  |- ...
   *
   * @param {*} e -> eventHandler
   */
  handleMouseOverSubMenuItem = (e) => {
    e.stopPropagation();

    const target = e.target.closest(".sub-item");
    this.dealWithMouseOverMenuForBestSeller(target);
  };

  /**
   * @description
   * Tratamentos dos eventos relacionado com o retirar do rato sobre os subitens do menu....
   *
   * Exemplo:
   * |-FILTRAGEM
   *  |- FILTROS MX...
   *  |- FILTROS DE LAMELAS
   *  |- FILTRO METÁLICOS
   *  |- CENTRAIS DE FILTRAGEM
   *  |- ...
   *
   * @param {*} e -> eventHandler
   */
  handleMouseOutSubMenuItem = (e) => {
    e.stopPropagation();
    const target = e.target.closest(".sub-item");
    this.dealWithMouseOutMenuForBestSeller(target);
  };

  /**
   * @description
   * Proceder de forma a que a área de realacionada com os mais vendidos/
   * procurados apresentem  um produto, se menu de nível 1 ou ou produtos
   * dos mais procrador de menu de nível 2
   *
   * @param {*} e
   */
  handleMouseOverSubItemContainer = (e) => {


    // como o elemento a referenciar não está na mesma secção do DOM
    // procede no sentido de o referenciar....
    let bestSelller = document.querySelector(".bestseller-container");
    if(bestSelller){
      bestSelller.classList.add("bs-sublevel");
    }

  };

  /**
   * @description
   * Proceder de forma a que a área de realacionada com os mais vendidos/
   * procurados apresentem  um produto, se menu de nível 1 ou ou produtos
   * dos mais procrador de menu de nível 2
   *
   * @param {*} e
   */
  handleMouseOutSubItemContainer = (e) => {
    // reverte as alterações realizadas sobre os vários elementos no métood
    // hadleMouseOverSubItem
    let bestSelller = document.querySelector(".bestseller-container");
    if(bestSelller){
      bestSelller.classList.remove("bs-sublevel");
    }
  };

  /**
   * @description
   * Para tratamento de abertura dos elementos do(s) submenu(s) : qdo em mobile, por exemplo
   *
   * @param {*} e -> eventHandler
   */
  handleSubMenuCallerClick = (e) => {
    e.stopPropagation();

    const closeSubItems = (subMenuContainer) => {
      let subItem = document.getElementsByClassName("sbm-opened");

      if (subItem.length > 0) {
        for (let i = 0; i < subItem.length; i++) {
          subItem[i].classList.remove("sbm-opened");
        }
      }
    };

    let target = e.target.nodeName !== "A" ? e.target.closest("A") : e.target;
    //target = target.closest('div[class^="m-"]');

    // referencia container da opcão de menu seleccionada
    const subMenuContainer = target.closest('div[class^="m-"]');
    // referencia todas os irmãos desse mesmo container
    const subMenuContainers = subMenuContainer.parentNode.childNodes;

    // verifica se elemento clicado está aberto e fecha-o parando o evento
    // neste momento
    if (subMenuContainer.classList.contains("sm-opened")) {
      subMenuContainer.classList.remove("sm-opened");
      // verifica se existe algum sub menu aberto neste item,
      closeSubItems(subMenuContainer);
    } else {
      // se o elemento clicado está fechado abre-o
      subMenuContainers.forEach((item) => {
        item.classList.remove("sm-opened");

        // verifica se existe algum sub menu aberto neste item,
        closeSubItems(item);
      });

      // abre a opção de menu seleccionada
      subMenuContainer.classList.add("sm-opened");
    }
  };

  /* * * * * * * * * * * * * * * ** * * * * * * * * * * * * * * * * * *
   * AUXILIARES AO MENU PARA APRESENTAÇÃO DE PRODUTOS MAIS PROCURADOS *
   * BEST SELLER...                                                   *
   * * * * * * * * * * * * * * * ** * * * * * * * * * * * * * * * * * */

  /**
   * @description
   * Para apresentação do respectivo produto associado - beste seller / mais procurado
   *
   * MOSTRAR PRODUTOS ASSOCIADOS
   *
   * @param {*} targetIdItem
   */
  dealWithMouseOverMenuForBestSeller = (targetIdItem) => {
    // obtem o ID do menu que está a disparar o evento
    const menuItemId = targetIdItem.getAttribute("id");

    // produra o ID obetido junto da listagem de produtos associados
    // esse ID é procurado dentro do container com a class => menu-item--rgt-cont
    let openItemWithId = document.querySelector(
      `.menu-item--rgt-cont #i-${menuItemId}`
    );

    // se encontrar o ID esconde todo o resto
    let bSellerList = document.querySelector(".menu-item--rgt-cont .bsc-show");

    if (bSellerList) {
      bSellerList.classList.remove("bsc-show");
      bSellerList.classList.add("bsc-hide");
    }

    // ....e apresenta o elementos com o ID encontrado
    // no formato i-<id>
    if (openItemWithId) {
      openItemWithId.classList.remove("bsc-hide");
      openItemWithId.classList.add("bsc-show");
    }

    // caso contrário nada faz..
  };

  /**
   * @description
   * Para ocultação do respectivo produto associado - beste seller / mais procurado
   *
   * ESCONDER PRODUTOS ASSOCIADOS
   *
   * @param {*} targetIdItem
   */
  dealWithMouseOutMenuForBestSeller = (targetIdItem) => {
    // obtem o ID do menu que está a disparar o evento
    const menuItemId = targetIdItem.getAttribute("id");

    // procura o ID obtido junto da listagem de produtos associados
    // esse ID é procurado dentro do container com a class => menu-item--rgt-cont

    // se encontrar o ID esconde o elemento com o ID encontrado no formato i-<id>

    // caso contrário nada faz...

    // verifica se existemm algum elemento, visivel, primeiro ou segundo nível,
    // se nehum elemento estiver visivel, colocar o primeiro da lista a visivel...
  };

  /* * * * * * * * * * * * * * ** * * * * * * * *
   * TRATAMENTOS DE EVENTOS PARA MENU EM GERAL  *
   * * * * * * * * * * * * * * ** * * * * * * * */

  /**
   * @description
   * Para tratamento do evento de mouse hover sobre o MENU, para que este
   * se mantenha aberto, por exemplo
   *
   * @param {*} e -> eventHandler
   */
  handleMouseOverMenu = (e) => {
    e.stopPropagation();

    const target =
      e.target.nodeName.toLowerCase() === "a" ? e.target.parentNode : e.target;

    let { /* data, */ mouseOverClass, prodGhouseClass } = this.state;

    //data.
    mouseOverClass =
      target.dataset.menu !== undefined ? target.dataset.menu : "";

    // diferencia o corpo de menu de acordo com maqueta, i.e., qdo estufas
    // ou produtos corpo de menu diverge dos demais
    prodGhouseClass =
      mouseOverClass.indexOf("ghouse") >= 0
        ? "prod-ghouse"
        : "";

    //this.setState({ data: data });
    this.setState({ mouseOverClass, prodGhouseClass });

    const open =
      "m-" + mouseOverClass.substring(0, mouseOverClass.indexOf("-"));

    const elem2Open = document.getElementsByClassName(open)[0];

    if (elem2Open) {
      elem2Open.parentNode.childNodes.forEach((node) => {
        node.classList.remove("show");
      });

      elem2Open.classList.add("show");

      let menuBody = document.getElementsByClassName("menu-body-container")[0];
      menuBody.style.maxWidth = elem2Open.classList.contains("m-universe")
        ? "66.6666666%"
        : "50%";
    }
  };

  /**
   * @description
   * Para tratamento do evento de mouse out sobre o MENU, para que este
   * se feche, por exemplo
   *
   * @param {*} e -> eventHandler
   */
  handleMouseOutMenu = (e) => {
    e.stopPropagation();

    let { mouseOverClass, prevMOClass } = this.state;
    //data.
    prevMOClass = mouseOverClass;
    mouseOverClass = "";

    //this.setState({ data: data });
    this.setState({ mouseOverClass, prevMOClass });
  };

  /**
   * @description
   * Para tratamento do evento de click sobre o burger menu,
   * fecha ou abre o menu de acordo com o estado anterior do mesmo
   *
   * @param {*} e -> eventHandler
   */
  handleMenuCallerClick = (e) => {
    e.stopPropagation();

    // muda a definição do estado do menu
    let { menuBodyOpenStats } = this.state;

    // TRUE - ABERTO | FALSE fechado
    menuBodyOpenStats = !this.state.menuBodyOpenStats;

    /**
     * Tratamento da stickybar para os vários cenários de abertura do menu
     * ISTO quando a janela tem largura inferior a 992... se menu caller foi accionado....
     */
    // ao click no MENU CALLER se scroll > 44 este desliza para o topo da página,
    if (menuBodyOpenStats && window.scrollY > 44) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }

    // se menu ABERTO esconde MAIN
    let main = document.querySelector("#root main");
    let footer = document.querySelector(".app-footer");

    /**
     * Inibe o apresenta o MAIN body
     */
    if (menuBodyOpenStats) {
      main.style.opacity = "0";
      main.style.display = "none";
      footer.style.opacity = "0";
      footer.style.display = "none";
    } else {
      main.style.opacity = "1";
      main.style.display = "block";
      footer.style.opacity = "1";
      footer.style.display = "flex";

      // verifica se existe uma class "nailed" associada a main-bar, se existir
      // remove-a
      let nailed = document.querySelector(".main-bar.nailed");

      if (nailed) {
        nailed.classList.remove("nailed");
      }
    }

    // scn #1 : menu abre e scoll é 0 => barra de topo está tda visível e assim vai continuar...

    // scn #2 : menu abre e scoll é > 44... => apenas está visível a parte inferior da barra e assim continua

    // actualiza o estado de abertura do menu
    this.setState({ menuBodyOpenStats: menuBodyOpenStats }, () => {
      // sm-opened
      // se a operação implica o fecho do menu, fecha os elementos que
      // eventualmente possam estar abertos - supostamento apenas um elemento
      //let openedElems = document.getElementsByClassName("sm-opened");
      let openedElements = document.getElementsByClassName("sm-opened");

      // retira a indicação da class sobre todos os elementos de menu que
      // poderão possuir a class indicativa de menu aberto
      for (let i = 0; i < openedElements.length; i++) {
        openedElements[i].classList.remove("sm-opened");
      }
    });
  };

  /**
   * bottom: 110
   *    height: 66
   *    left: 0
   *    right: 1351
   *    top: 44
   *    width: 1351
   *    x: 0
   *    y: 44
   * @param {*} e -> eventHandler
   */
  handleWindowScroll = (e) => {
    const target = e.target;
    const mainBarBoudingClient = this.mainBar.getBoundingClientRect();

    //let { data } = this.state;
    let { data, nailedSticky, menuBodyOpenStats } = this.state;

    /**
     * ATENCÃO:
     * Este bloco de código entra em conflito com o SCROLL quando o  menu se
     * encontra em modo MOBILE e aberto pelo que só deve ser executado quando
     * o menu está fechado.... quando menu aberto, a barra de topo matem-se fixa
     * automaticamente
     */
    // verifica se menu fechado não executa este código e fixa stybar...
    if (
      !menuBodyOpenStats &&
      // this.state.view !== this.props.t("menu").about[1] &&
      //this.state.view !== this.props.t("menu").projects[1] &&
      window.scrollY <= this.scrollLimit2Fix
    ) {
      // posicao relativa
      this.mainBar.classList.remove("nailed");
      //this.menuBody.classList.remove("nailed");
      //data.
      nailedSticky = false;
    } else if (
      !menuBodyOpenStats &&
      // this.state.view !== this.props.t("menu").about[1] &&
      //this.state.view !== this.props.t("menu").projects[1] &&
      window.scrollY > this.scrollLimit2Fix
    ) {
      // posicao relativa
      this.mainBar.classList.add("nailed");
      //this.menuBody.classList.add("nailed");
      //data.
      nailedSticky = true;
    }

    //this.setState({ data: data });
    // { data, nailedSticky }
    this.setState({ data, nailedSticky });
  };

  /**
   * @description
   * Para fecho do menu se este estiver aberto em formato MOBILE e
   * se verificar um resise
   */
  handleWindowResize = () => {
    const actualWidth = window.innerWidth;
    let { menuBodyOpenStats } = this.state;

    // a linha que separa o mobile do desktop são os 992, sendo que
    // neste caso o menu deve ser reinicializado
    // se a largura anterior era inferior a 992 e neste momento está
    // superior a esse limiar, fecha MENU, i.e., menuBodyOpenStats = FALSE
    if (actualWidth > this.prevWidth && actualWidth > this.mobile2Desktop) {
      menuBodyOpenStats = false;
      this.setState({ menuBodyOpenStats });
    }

    if (actualWidth < this.mobile2Desktop) {
      let menuBodyContainer = document.getElementsByClassName(
        "menu-body-container"
      )[0];
      menuBodyContainer.removeAttribute("style");
    }

    if (actualWidth > this.mobile2Desktop) {
      let mainComp = document.querySelector("#root main");
      mainComp.style.display = "block";
      mainComp.style.opacity = 1;
    }

    this.prevWidth = actualWidth;
  };

  handleWindowClick = (e) => {
    /*const target = e.target;
    const search = target.closest(".searchbar");
    let { searchStats } = this.state;


     if (search === null && searchStats) {
      searchStats = false;
      this.setState({ searchStats });
    } */
  };

  /**
   * @description
   * Para tratamento do evento de abertura ou fecho da searchbar
   *
   * @param {*} e -> eventHandler
   */
  handleSearchStats = (e) => {
    /*  e.stopPropagation();
    e.preventDefault(); */

    const target = e.target;

    //let { data } = this.state;
    let { searchStats } = this.state;
    //data.searchStats = !data.searchStats;
    searchStats = !searchStats;

    this.setState({ searchStats });
  };


  /**
   * @description
   * Para fabrico o componente STICKYBAR do menu...
   */
  render() {

    /**
     * click no menu dos serviços --> direciona para o primeiro serviço do menu
     */
    const setServiceRootMenuURL = () => {
      let {
        lang,
        data: { menu },
      } = this.state;

      let firstService = null;
      let serviceUrl = "#";

      if (menu !== null && "services" in menu && menu.services.length > 0) {
        firstService = menu.services[0];

        serviceUrl = `/${lang}/${this.t("menu").services[1]}/${
          firstService.id
        }/${firstService.slug}/#f${firstService.id}`;
      }

      return serviceUrl;
    };

    /**
     * a stickbar assume formatos e/ou comportamentos distintos de acordo com
     * algumas das views que estão a aceder ao site pelo que na sua execução
     * essa situação terá que se ter em consideração
     *
     * VIEWS em que a STICKYBAR é diferente...
     * UNIVERSO
     */

    /// campos para o FORM de pedido de orçamento(s)
    let fields = [
      Object.assign({ required: "required" }, Configs.form_fields.name),
      Object.assign({ required: "required" }, Configs.form_fields.email),
      Object.assign({ required: "required" }, Configs.form_fields.mobile),
      Object.assign({ required: "required" }, Configs.form_fields.remarks),
      Object.assign({ required: "required" }, Configs.form_fields.rgpdconsent),
    ];

    let thisIsIt = null;

    return (
      <section id="stickybar" className={`stickybar ${this.state.view}`}>
        {/**
         * SECÇÂO SUPERIOR DA BARRA DE MENU - STICKYBAR
         */}
        <header className="col-12">
          <div className="sub-menu">
            {/* <StoreAndDownloads /> */}
            {/**
             * COMPONENTE DE MENU DOWNLOADS E CONTACTOS
             */}
            <ul>
              <li>
                <a href={`/${this.state.lang}/${this.props.t("menu").downloads[1]}`}>
                  {this.props.t("menu").downloads[0]}
                </a>
              </li>
              <li>
                <NavLink
                  className={""}
                  to={`/${this.state.lang}/${this.props.t("menu").contacts[1]}`}
                >
                  {this.props.t("menu").contacts[0]}
                </NavLink>
              </li>
            </ul>
          </div>
          {/**
           * COMPONENTE STORE CALLER
           */}
          <div className="store">

          </div>
        </header>
        {/**
         * SECÇÂO INFERIOR DO MENU - OPÇÔES DE MENU :
         * CORTE LASER | QUINAGEM | SERVÇOS | GALERIA | UNIVERSO
         */}
        <div className="col-12 main-bar">
          <div className="col-7 col-sm-9 col-xl-2 logo">
            <a href={`${Configs.base_url}${this.state.lang}`}>
              <img src="/assets/imgs/metalworking_1.svg" alt="" />
            </a>
          </div>
          <nav className="d-xs-none col-xl-8 main-menu app-menu">
            {/**
             * As subopções destes headers de menu surgem mais abaixo numa secção de código
             * cuja raiz é a DIV com class "menu-body"
             */}
            <ul>
              {/* CORTE LASER */}
              <li
                className={`col-2`}
                data-menu="laser-open"
                onMouseOver={this.handleMouseOverMenu}
                onMouseOut={this.handleMouseOutMenu}
              >
                <NavLink
                  to={`/${this.state.lang}/${
                    this.props.t("menu").laserCut[1]
                  }${this.state.laserRootURI}`}
                >
                  {this.props.t("menu").laserCut[0]}
                </NavLink>

                <div className="main-submenu"></div>
              </li>

              {/* QUINAGEM */}
              <li
                className="col-2"
                data-menu="bending-open"
              >
                <a
                  href={`/${this.state.lang}/${
                    this.props.t("menu").bending[1]
                  }`}
                >
                  {this.props.t("menu").bending[0]}
                </a>
              </li>


              {/* SERVICOS */}
              <li
                className={`col-2`}
                data-menu="services-open"
                onMouseOver={this.handleMouseOverMenu}
                onMouseOut={this.handleMouseOutMenu}
              >
                <NavLink
                  onClick={(e) => {
                    const target = e.target;
                    let serviceOnMenuBody = document.querySelector(
                      "#menu-body"
                    );

                    if (serviceOnMenuBody) {
                      serviceOnMenuBody.classList.remove("services-open");
                    }
                  }}
                  to={
                    this.state.data.menu !== null ? setServiceRootMenuURL : "#"
                  }
                >
                  {this.props.t("menu").services[0]}
                </NavLink>
                <div className="main-submenu"></div>
              </li>

              {/* PROJECTOS */}
              <li
                className="col-2"
                data-menu="projects-open"
              >
                <a
                  href={`/${this.state.lang}/${
                    this.props.t("menu").projects[1]
                  }`}
                >
                  {this.props.t("menu").projects[0]}
                </a>
              </li>
              {/* UNIVERSO */}
              <li
                className="col-2"
                data-menu="universe-open"
                onMouseOver={this.handleMouseOverMenu}
                onMouseOut={this.handleMouseOutMenu}
              >
                <a
                  href={`/${this.state.lang}/${this.props.t("menu").about[1]}${
                    this.state.universeRootURI
                  }`}
                >
                  {this.props.t("menu").about[0]}
                </a>
              </li>
            </ul>
          </nav>
          {/* SELECTOR DE IDIOMAS */}
          <div className="col-3 col-sm-2 col-xl-2 search-lang">
            <LanguageSelector />
            <div className="search">
              <a href="#" onClick={this.handleSearchStats}>
                <img
                  src="/assets/imgs/Pesquisa---01.png"
                  alt="pesquisa"
                  onMouseOver={(e) =>
                    (e.currentTarget.src = "/assets/imgs/Pesquisa---02.png")
                  }
                  onMouseOut={(e) =>
                    (e.currentTarget.src = "/assets/imgs/Pesquisa---01.png")
                  }
                />
              </a>
            </div>
          </div>
          <div
            className={`col-2 col-sm-1 d-xl-none menu-caller `}
            onClick={this.handleMenuCallerClick}
          >
            {/* <FontAwesomeIcon icon="bars" size="lg" /> */}
            <div className="menu-caller-container">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
        <div
          id="menu-body"
          //style={{ display: "block" }}
          className={`menu-body 
            ${this.state.prodGhouseClass} ${ this.state.mouseOverClass } 
            ${ this.state.nailedSticky === true ? "nailed" : "" }
            ${ this.state.menuBodyOpenStats === true ? this.menuBodyOpenClass : "" }
          `}
        >
          <div className="col-12 d-xl-none menu-body-header">
            <a href={`${Configs.base_url}${this.state.lang}`}>
              <img src="/assets/imgs/metalworking_2.svg" alt="Logo" />
            </a>
            <div
              className="menu-caller-container"
              onClick={this.handleMenuCallerClick}
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
          <div className="menu-body-container container">
            {/* OPÇÔES DO MENU CORTE LASER */}
            <div className="m-laser ">
              <span
                className="sub-menu-body-title"
                onClick={this.handleSubMenuCallerClick}
              >
                <a href="#" className="d-xl-none">
                  {this.props.t("menu").laserCut[0]}
                  <FontAwesomeIcon icon="chevron-down" size="lg" />
                </a>
              </span>
              <div className="row sub-menu-body">
                {this.state.data.menu
                  ? this.makeMenu("lazerCut", this.props.t("menu").laserCut[1])
                  : null}
              </div>
            </div>

            {/* OPÇÔES DO MENU QUINAGEM */}
            <div className="m-bending">
              <a
                href={`/${this.state.lang}/${this.props.t("menu").bending[1]}`}
                className="d-xl-none"
              >
                {this.props.t("menu").bending[0]}
              </a>
            </div>


            {/* OPÇÔES DO MENU SERVICOS */}
            <div className="m-services ">
              <span
                className="sub-menu-body-title"
                onClick={this.handleSubMenuCallerClick}
              >
                <a href="#" className="d-xl-none">
                  {this.props.t("menu").services[0]}
                  <FontAwesomeIcon icon="chevron-down" size="lg" />
                </a>
              </span>
              <div className="row sub-menu-body">
                {this.state.data.menu
                  ? this.makeMenu("services", this.props.t("menu").services[1])
                  : null}
              </div>
            </div>
            {/* OPÇÔES DO MENU PROJECTOS */}
            <div className="m-projects">
              <a
                href={`/${this.state.lang}/${this.props.t("menu").projects[1]}`}
                className="d-xl-none"
              >
                {this.props.t("menu").projects[0]}
              </a>
            </div>
            {/* OPÇÔES DO MENU UNIVERSO */}
            <div className="m-universe">
              <span
                className="sub-menu-body-title"
                onClick={this.handleSubMenuCallerClick}
              >
                <a href="#" className="d-xl-none">
                  {this.props.t("menu").about[0]}
                  <FontAwesomeIcon icon="chevron-down" size="lg" />
                </a>
              </span>
              <div className="row sub-menu-body">
                {this.state.data.menu
                  ? this.makeMenu("universe", this.props.t("menu").about[1])
                  : null}
              </div>
            </div>
          </div>
        </div>
        <AppSearchBar
          nailedStats={this.state.nailedSticky}
          searchStats={this.state.searchStats}
          hSearchStats={this.handleSearchStats}
        />
      </section>
    );
  }
}

export default withTranslation()(AppMenu);
/**
 * @description
 * Menu Universe...
 */
export const UniverseMenu = (props) => {
  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState();

  useEffect(() => {
    // Actualiza o idioma usado na app... esta var poderá servir como
    // Lifting Up State e por esta via implicar o render dos components
    // child. Neste momento julga-se últil para obter os conteúdos
    // dinâmicos a partir de uma API ou BO
    setLang(i18n.language);
  });

  const makeMenu = () => {
    var id,
      slug = null;
    if (props !== undefined && props !== null && Array.isArray(props.navData)) {
      id = props.navData[0].id;
      slug = props.navData[0].slug;
    }

    let item = (
      <li className="menu-universe">
        <a href={`/${lang}/${t("menu").about[1]}/${id}/${slug}`}>
          {t("menu").about[0]}
        </a>
        <ul className="we-are">
          {props.navData
            ? props.navData.map((val) => {
                return (
                  <li key={val.id}>
                    <a
                      href={`/${lang}/${t("menu").about[1]}/${val.id}/${
                        val.slug
                      }`}
                    >
                      {val.title}
                    </a>
                  </li>
                );
              })
            : null}
        </ul>
      </li>
    );

    if (props.use && props.use === "footer") {
      return <ul>{item}</ul>;
    } else {
      return item;
    }
  };

  return makeMenu();
};

/**
 * @description
 * Menu Loja e Downloads
 */
export const StoreAndDownloads = () => {
  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState();

  useEffect(() => {
    /* window.scrollTo(0, 0); */
    // Actualiza o idioma usado na app... esta var poderá servir como
    // Lifting Up State e por esta via implicar o render dos components
    // child. Neste momento julga-se últil para obter os conteúdos
    // dinâmicos a partir de uma API ou BO
    setLang(i18n.language);
  });

  return (
    <ul className="store-downloads">
      <li>
        <a href={`/${lang}/${t("menu").downloads[1]}`}>
          {t("menu").downloads[0]}
        </a>
      </li>
      <li>
        <a
          href={`/${lang}/${t("menu").about[1]}/236/${
            t("menu").team[1]
          }/#formJobs`}
        >
          {t("menu").workWithUs[0]}
        </a>
      </li>
    </ul>
  );
};

/**
 * @description
 * Menu RGPD
 */
export const Rgpd = () => {
  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState();

  useEffect(() => {
    // Actualiza o idioma usado na app... esta var poderá servir como
    // Lifting Up State e por esta via implicar o render dos components
    // child. Neste momento julga-se últil para obter os conteúdos
    // dinâmicos a partir de uma API ou BO
    setLang(i18n.language);
  });

  return (
    <ul className="rgpd">
      <li>
        <a href={`/${lang}/${t("menu").privacy[1]}`}>{t("menu").privacy[0]}</a>
      </li>
      <li>
        <a
          href={`${t("menu").complainsBook[1]}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t("menu").complainsBook[0]}
        </a>
      </li>
      <li>
        <a
          href={`${t("menu").ral[1]}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t("menu").ral[0]}
        </a>
      </li>
      <li>
        <a href={`${t("menu").reportingChannel[1]}`}
           target="_blank"
           rel="noopener noreferrer">{t("menu").reportingChannel[0]}</a>
      </li>
    </ul>
  );
};
