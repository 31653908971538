import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

/**
 * @description
 * Para tratamento das mensagens de ERRO / RESPOSTA aos inputs nas
 * forms...
 *
 * @param {*} props
 */
const AlertMessage = (props) => {
  /**
   * @description
   * Verifica o estado das PROPS, em concreto o "resStats" e se este
   * difente de NULL significa que a caixa de mensagem estará aberta e
   * nesse caso corre o timer abaixo para a fechar automáticamete na
   * eventualidade de o utilizador n o ter feito
   * <=> componentDidUpdate
   */
  useEffect(() => {
    // identificador da caixa
    let parentTarget = document.getElementsByClassName("submit-status")[0];

    if (props.resStats !== null) {
      setTimeout(() => {
        if (parentTarget !== undefined) {
          parentTarget.classList.remove("show");

          if (props.onClose) {
            props.onClose(true);
          }
        }
      }, 3000);
    }
  });

  /**
   * @description
   * Para tratamento da acção de click sobre o botão CLOSE da caixa
   * de mensagem...
   *
   * @param {*} e
   */
  const handleCloseAlertClick = (e) => {
    e.preventDefault();

    // procede ao fecho da caixa por retirada da class SHOW
    let parentTarget = e.target.closest(".submit-status");
    parentTarget.classList.remove("show");

    if (props.onClose) {
      props.onClose(true);
    }
  };

  return (
    <div
      className={`${props.myClass ? props.myClass : ""} ${
        props.resStats !== null && props.resStats.res
          ? "alert-success"
          : props.resStats !== null && !props.resStats.res
          ? "alert-danger"
          : "alert-light"
      } `}
      role="alert"
    >
      {props.children}
      <button
        type="button"
        className="close"
        data-dismiss=""
        aria-label=""
        onClick={handleCloseAlertClick}
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  );
};

export default AlertMessage;
