import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import $ from "jquery";

import "../../../src/scss/NewsDetailView.scss";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import RestDataSource from "../../components/webservices/RestDataSource";

import { insertMediaWithAppAbsPath, Configs } from "../../Toolbox";

import ReactHtmlParser from "react-html-parser";

import ShareSocialMedia from "../ShareSocialMedia";
import { Parallax, ParallaxProvider } from "react-scroll-parallax";
import VisibilitySensor from "react-visibility-sensor";

/**
 * For News Detail
 */

class NewsDetailView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lang: null,
      data: null,
      stickyBFM: null, // para elementos STICKYMENU
    };

    this.t = props.t; //tradutor
    this.i18n = null;

    this.logicLatch = false; // TRINCO LÓGICO - FALSE aberto | TRUE fechado

    this.optionId = undefined; // ID da CATEGORIA dos artigos
    this.optionSlug = undefined; // respectivo slug

    //verifica se o match existe e retira a origem da VIEW
    if (
      this.props.match &&
      this.props.match.params &&
      "optionId" in this.props.match.params &&
      "optionSlug" in this.props.match.params
    ) {
      this.optionId = this.props.match.params.optionId;
      this.optionSlug = this.props.match.params.optionSlug;
    }

    //this.restURL = null; // = `${this.lang}/rest/news/content/${this.optionId}`;

    this.webserver = new RestDataSource(); // para comunicação com a API (REST)
    this.restURI = `rest/article/`; // para utilização nos pedidos de dados à API / URI do pedido
  }

  /**
   * @description
   * Para inicializaçãpo dos componentes e afins após o seu carregamento no DOM
   *
   */
  componentDidMount() {
    window.sessionStorage.removeItem("stickyBFM");
    this.t = this.props.t; //para utilização nas ações de tradução
    this.i18n = this.props.i18n;

    let { lang } = this.state;
    lang = this.i18n.language;
    /*  data = this.props.pData ? this.props.pData : null; */

    this.setState({ lang });

    // para utilização de JQUERY (OWL)
    window.jQuery = $;
    require("owl.carousel");

    this.logicLatch = true;
    this.getDataFromAPI(this.makeURI());
  }

  /**
   * @description
   * Verifica se existem alterações nas props e/ou state e executa
   * as necessárias alterações em conformidade, exemplo, quando se
   * altera o idioma do site poderá ser necessário obter novos termos
   * no novo idioma para preenchimento do layout...
   * caso se verifique que os mesmos já existam dever-se-à evitar a
   * utilização deste recurso, isto porque, poderá estar a criar um
   * conjunto de pedidos desnecessários e que eventualmente tornarão
   * esta solução mais lenta
   *
   * @param {object} prevProps
   * @param {object} prevState
   */
  componentDidUpdate(prevProps, prevState) {
    let { lang } = this.state;

    // novo idioma seleccionado, actualiza-o
    if (lang !== this.i18n.language) {
      lang = this.i18n.language;

      this.setState({ lang }, () => {
        // idioma alterado, obtem os dados da API no novo idioma
        if (this.logicLatch === false) {
          // fecha o trinco para efeitos de acesso ao pedido de
          // novos conteúdos à API
          this.logicLatch = true;
          // executa pedido
          this.getDataFromAPI(this.makeURI());
        }
      });
    }

    if (
      this.props.navData &&
      "universe" in this.props.navData &&
      (this.state.stickyBFM === null ||
        JSON.stringify(this.props.navData.universe) !==
          JSON.stringify(this.state.stickyBFM))
    ) {
      let { stickyBFM } = this.state;

      if ("universe" in this.props.navData) {
        stickyBFM = this.props.navData.universe;

        this.setState({ stickyBFM }, () => {
          let navElems = stickyBFM.map((val) => {
            return {
              id: val.id,
              text: val.title,
              uri: `${this.t("menu").about[1]}/${val.id}/${val.slug}`,
              onClick: this.handleStickyMenuItemClick,
            };
          });
          this.props.setSFMOptions(navElems);
        });
      }
    }
  }

  makeURI() {
    let requestURI = `${this.i18n.language}/${this.restURI}`; /* /fid${this.optionId} */

    // verifica se existe optionId e acrescenta-os ao URI
    if (this.optionId !== undefined) {
      requestURI += `fid${this.optionId}`;
    }

    return requestURI;
  }

  /**
   * @description
   * Método responsavel por usar o pedido de dados à API... a usar nas life cycle
   * componentDidMount e/ou componentDidUpdate
   */
  getDataFromAPI = (requestURL) => {
    this.webserver.getData(requestURL, (result, xhr) => {
      this.logicLatch = false;

      /* if (typeof _data === "object") */
      if (xhr.status === 200 && "data" in xhr && typeof xhr.data === "object") {
        let { data } = this.state;
        data = result;

        this.setState({ data }, () => {
          // cria o corpo da página
          this.setupBodyContent();
        });
      }
    });
  };

  /**
   * @description
   * Para inicialização de elementos OWL do corpo da página
   */
  setupBodyContent = () => {
    if (
      this.state.data.article !== null &&
      this.state.data.article !== undefined &&
      this.state.data.article
    ) {
      $(".carousel_news").owlCarousel({
        nav: false,
        dots: true,
        autoplay: false,
        loop: true,
        margin: 0,
        responsiveClass: true,
        responsive: {
          0: {
            items: 1,
          },
          992: {
            items: 1,
          },
          1200: {
            items: 1,
          },
        },
      });

      /*  //seta do lado esquerdo
      var setaPrev = document.getElementsByClassName("owl-prev");
      var IconPrev = setaPrev[0].getElementsByTagName("span");
      IconPrev[0].innerHTML =
        ' <img src="../../../../assets/imgs/Seta-01.png" alt=""/>';

      //seta do lado direito
      var setaNext = document.getElementsByClassName("owl-next");
      var IconNext = setaNext[0].getElementsByTagName("span");
      IconNext[0].innerHTML =
        ' <img src="../../../../assets/imgs/Seta-02.png" alt=""/>'; */
    }
  };

  makeDetail = () => {
    let article = null;
    if (this.state.data !== null && this.state.data.article) {
      article = this.state.data.article;
      var newId = article.parentID;
      var OldStickyFM = document.querySelectorAll(".sticky-fm ul ");

      $(OldStickyFM).find("li").removeClass("active");

      $(OldStickyFM)
        .find("li#" + newId + "sticky")
        .addClass("active");

      return (
        <div className="conteudo_detail">
          <h6 className="categoryName">{this.props.t("views").news.bg_word}</h6>
          <h1 className="titleNews">{article.title}</h1>
          <div className="descriptionNews">
            {ReactHtmlParser(article.description)}
          </div>
        </div>
      );
    }
    return article;
  };

  makeCarousel = () => {
    let carousel = null;

    if (this.state.data !== null && this.state.data.article) {
      carousel = this.state.data.article.imgs.map((val, key) => {
        let img = insertMediaWithAppAbsPath(val.name, "imgs");

        return (
          <div className="item" key={val.id}>
            <div
              className="img_item"
              style={{ backgroundImage: "url(" + img + ")" }}
            ></div>
          </div>
        );
      });
      return carousel;
    }
  };

  makeSocialMedia = () => {
    let link = Configs.base_url + this.props.match.url.substring(1);
    let phrase = this.props.t("views").about.quality.share_article;
    return <ShareSocialMedia nameShare={phrase} shareUrl={link} />;
  };

  backList = () => {
    if (this.props.navData !== null && this.props.navData) {
      var urlUniverse =
        this.props.navData.universe[5].id +
        "/" +
        this.props.navData.universe[5].slug;
    }

    var urlAboutNew = Configs.base_url + this.i18n.language + "/" + urlUniverse;
  };

  /**
   * @description
   * Tratamento do click em items do sticky menu, se este estiver localizado
   * na própria VIEW faz scroll da mesma para o topo, se estiver localizado em
   * VIEW diferente procede à ancora para a respectiva VIEW
   *
   * @param {*} e eventHandler
   */
  handleStickyMenuItemClick = (e) => {
    const target = e.target;

    // obtem valores de params a partir das props.match
    // se estes valores estiverem presentes no link que foi clicado
    // encaminhar o conteúdo da VIEW para o topo da mesma
    if (target.href.indexOf(this.optionId) >= 0) {
      e.preventDefault();
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };
  onVisibilityChange = (isVisible = false) => {
    if (isVisible) {
      window.dispatchEvent(new Event("resize"));
    }
  };
  render() {
    return (
      <div className="body_news_detail">
        <a className="changeURL" href={this.urlAboutNew}>
          <img
            onClick={() => this.props.history.goBack()}
            className="closeArticle"
            src="../../../../assets/imgs/close.png"
            alt=""
          />
        </a>

        <div className="bg_gray_1"></div>
        {this.state.data !== undefined ? this.makeDetail() : null}
        <div className="animate-content">
          <ParallaxProvider>
            <Parallax className="custom-class" x={[0, 60]}>
              <div className="word_news">
                {this.props.t("views").news.bg_word}
              </div>
            </Parallax>
          </ParallaxProvider>
        </div>
        <div className="line"></div>
        <div className="owl-carousel carousel_news">
          {this.state.data !== undefined ? this.makeCarousel() : null}
        </div>
        <div>
          {this.state.data !== undefined ? this.makeSocialMedia() : null}
        </div>
      </div>
    );
  }
}

export default withTranslation()(NewsDetailView);
setTimeout(() => {
  window.dispatchEvent(new Event("resize"));
}, 1000);
