import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import ReactHtmlParser from "react-html-parser";
import { Parallax, ParallaxProvider } from "react-scroll-parallax";
/**
 * @description
 * Para tratamento e apresentação do menu lateral / topo /
 * direito sobreposto a todo o conteúdo.
 *
 * @param {string} state.lang -> para definição do idioma
 * @param {string} state.data -> opçoes a apresentar no menu a
 *                              dispnibilizar via props...
 */
class HeaderContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lang: null,
      data: null,
      headerData: this.props.headerData,
    };

    // para efeitos de tradução / internacionalidação
    this.t = this.props.t;
    this.i18n = this.props.i18n;
  }

  /**
   * @description
   *
   * @param {*}
   */
  componentDidMount() {
    let { lang } = this.state;
    lang = this.i18n.language;
    this.setState({ lang });
  }

  /**
   * @description
   * Para cosntrução das opções do menu. este processo acontede em
   * modo UPDATE devido ao facto, entre outros, de estas opções
   * apenas se verificarem disponíveis após a conclusão do acesso
   * à API a obtenção de NAVIGATION data, por exemplo e no caso das
   * páginas ce contexto ABOUT... (SOMOS...:)
   *
   * @param {*} prevProps -> valores das props anteriores ao UPDATE
   * @param {*} prevState -> valores de state var antrior ao UPDATE
   */
  componentDidUpdate(prevProps, prevState) {
    let { headerData } = this.state;
    let { lang } = prevState;

    if (headerData === null && this.props.headerData) {
      headerData = this.props.headerData;
      lang = this.i18n.language;
      this.setState({ headerData, lang });
    } else if (
      headerData !== null &&
      this.state.headerData.description_universo !==
        this.props.headerData.description_universo
    ) {
      headerData = this.props.headerData;
      lang = this.i18n.language;
      this.setState({ headerData, lang });
    }
  }

  render() {

    const { headerData } = this.state;

    const cord = { x: [0, 0], y: [0, 0] };

    if (
      headerData &&
      headerData.cat_universo &&
      headerData.cat_universo === this.props.t("menu").projects[0] //"Projetos"
    ) {
      /* cord.x = [-85, 25]; */
      cord.x = [0, -50];
      cord.y = [0, 0];
    } else if (
      headerData &&
      headerData.cat_universo &&
      headerData.cat_universo === this.props.t("menu").downloads[0] //"Downloads"
    ) {
      //  console.log("não prod");
      cord.x = [0, 0];
      cord.y = [-95, 85];
    }

    return headerData ? (
      <div className="col-12 header-content">
        <p className="cat_universo">
          {ReactHtmlParser(headerData.cat_universo)}
        </p>
        <p className="subcat_universo">
          {ReactHtmlParser(headerData.subcat_universo)}
        </p>
        <p className="titulo_universo">
          {ReactHtmlParser(headerData.titulo_universo)}
        </p>
        <p className="description_universo">
          {ReactHtmlParser(headerData.description_universo)}
        </p>

        <ParallaxProvider>
          <Parallax
            className="custom-class"
            x={cord.x}
            y={cord.y}
            tagOuter="figure"
          >
            <div className={"bground_content " + headerData.cat_universo}></div>
          </Parallax>
        </ParallaxProvider>
      </div>
    ) : (
      ""
    );
  }
}

export default withTranslation()(HeaderContent);
setTimeout(() => {
  window.dispatchEvent(new Event("resize")); /* console.clear(); */
}, 1000);
