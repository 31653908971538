import React, { Component /* , Fragment */ } from "react";
import { withTranslation } from "react-i18next";

import $ from "jquery";

import "../../scss/views/Home.scss";

//import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "../../assets/plugins/owl_carousel/customize_owlcarousel.css";

import RestDataSource from "../../components/webservices/RestDataSource";
import {
  extractElementsFromArrayByIsType,
  insertMediaWithAppAbsPath,
} from "../../Toolbox";

//import Button from "../forms/FormComponents";
import AppButton from "../AppButton";

import ReactHtmlParser from "react-html-parser";
import HtmlParser from "react-html-parser";
import Error404 from "../error/Error404";

/**
 * @description
 * Apresentação de conteúdo relacionados com eventual VIEW Sobre Nós // About...
 *
 * @param {object} texts -> p armazenamento dos textos estáticos a apresentar na view
 */
class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lang: null,
      data: {},
    };

    this.webserver = new RestDataSource(); // para comunicação com a API (REST)
    this.restURI = `rest/home/content/`; // para utilização nos pedidos de dados à API / URI do pedido

    // p utilização nas acções de tradução
    this.t = null;
    this.i18n = null;

    this.logicLatch = false; // TRINCO LÓGICO - FALSE aberto | TRUE fechado
  }

  /**
   * @description
   * Para inicialização dos componentes e afins após o seu carregamento no DOM
   *
   */
  componentDidMount() {
    window.sessionStorage.removeItem("stickyBFM");
    this.t = this.props.t; // p utilização nas acções de tradução
    this.i18n = this.props.i18n;

    let { lang } = this.state;
    lang = this.i18n.language;

    this.setState({ lang });

    const requestURI = `${this.i18n.language}/${this.restURI}`;

    // para utilização de JQUERY (OWL)
    window.jQuery = $;
    require("owl.carousel");

    this.logicLatch = true;
    this.getDataFromAPI(requestURI);
  }

  /***
   * @description
   *
   * @param {*} prevProps
   * @param {*} prevState
   */
  componentDidUpdate(prevProps, prevState) {
    // verifica se o idioma actual é igual ao idioma pré actualização
    // e actualiza o mesmo se assim se verificar necessário, i.e., se
    // os idiomas não coincidirem
    let { lang } = prevState;
    const requestURI = `${this.i18n.language}/${this.restURI}`;

    // novo idioma seleccionado, actualiza-o
    if (lang !== this.i18n.language) {
      lang = this.i18n.language;

      this.setState({ lang }, () => {
        // idioma alterado, obtem os dados da API no novo idioma
        if (this.logicLatch === false) {
          // fecha o trinco para efeitos de acesso ao pedido de
          // novos conteúdos à API
          this.logicLatch = true;
          // executa pedido
          this.getDataFromAPI(requestURI);
        }
      });
    }
  }

  /**
   * @description
   * Método responsavel por usar o pedido de dados à API... a usar nas life cycle
   * componentDidMount e/ou componentDidUpdate
   */
  getDataFromAPI = (requestURL) => {
    this.webserver.getData(requestURL, (result, xhr) => {
      this.logicLatch = false;

      //console.log(result);

      // trata dados retornados
      /* typeof result === "object" */
      if (xhr.status === 200 && "data" in xhr && typeof xhr.data === "object") {
        // actualizar STATE var
        let { data } = this.state;
        data = result;

        this.setState({ data }, () => {
          // cria o corpo da página
          this.setupBodyContent();
        });
      } else {
        return <Error404 />;
      }
    });
  };

  /**
   * @description
   * Para inicialização de elementos OWL do corpo da página
   */
  setupBodyContent = () => {
    // verifica se foram retornados dados para:
    // carousel / banner
    if ("carousel" in this.state.data) {
      // inicia carrocel do banner / topo
      $(".apresentacao_slideshow").owlCarousel({
        autoplay: false,
        loop: false,
        margin: 0,
        responsiveClass: true,
        responsive: {
          0: {
            items: 1,
          },
          992: {
            items: 1,
          },
          1200: {
            items: 1,
          },
        },
        dots: true,
        dotsEach: true,
        controls: false,
        onTranslate: function () {
          $(".owl-item")
            .find("video")
            .each(function () {
              this.controls = false;
            });
        },
        onResized: function () {
          $(".owl-item")
            .find("video")
            .each(function () {
              this.controls = false;
            });
        },
      });

      $(".owl-item")
        .find("video")
        .each(function () {
          this.controls = false;
        });
    }

    // featuredProducts
    if ("featProds" in this.state.data) {
      // inicia a área de destaque de produto
      $(".destaques_slideshow")
        .owlCarousel({
          autoplay: false, //true
          center: true,
          loop: true,
          margin: 0,
          responsiveClass: true,
          responsive: {
            0: {
              items: 1,
            },
            992: {
              items: 2,
            },
            1200: {
              items: 3,
            },
          },
          dots: true,
        })
        .on("initialized.owl.carousel translate.owl.carousel", function (e) {
          let idx = e.item.index;
          //console.log(idx);

          $(".owl-item").eq(idx).addClass("active center");
          $(".owl-item")
            .eq(idx - 1)
            .addClass("active");
          $(".owl-item")
            .eq(idx + 1)
            .addClass("active");
          $(".owl-item").removeClass("active center");
          $(".owl-item").removeClass("active");
        });
    }

    // featuredProjects
    if ("featProjs" in this.state.data) {
      // inicia área de destaque de projectos
      $(".projetos_slideshow").owlCarousel({
        autoplay: false,
        loop: true,
        margin: -1,
        responsiveClass: true,
        responsive: {
          0: {
            items: 1,
          },
          992: {
            items: 1,
          },
          1200: {
            items: 1,
          },
        },
        dots: true,
      });
    }
  };

  /**
   * Banner -- slideshow
   */
  makeBanner = () => {
    let banner = null;

    banner = this.state.data.carousel.map((val, key) => {
      var arrayImgs = val.imgs;

      // obtem imagem do banner
      let img = extractElementsFromArrayByIsType(arrayImgs, "generic");
      // valida resultado obtido e gera o link absoluto da imagem
      img =
        img !== null ? insertMediaWithAppAbsPath(img[0].name, "imgs") : null;

      // obtem video do banner
      let video = extractElementsFromArrayByIsType(arrayImgs, "video");
      video =
        video !== null
          ? insertMediaWithAppAbsPath(video[0].name, "imgs")
          : null;

      let linkUrl =
        Array.isArray(val.links) && val.links.length > 0 ? val.links[0] : "";

      return (
        <div className="item" key={val.id}>
          {/*  <div
            className="apresentacao_imgs"
            style={{ backgroundImage: "url(" + img + ")" }}
          ></div> */}

          {arrayImgs !== null && arrayImgs[0].type === "video" ? (
            <>
              <video autoPlay loop muted playsInline className="apresentacao_video">
                <source src={video} type="video/mp4" />
              </video>
            </>
          ) : (
            <>
              <div
                className="apresentacao_imgs"
                style={{ backgroundImage: "url(" + img + ")" }}
              ></div>

              <div className="filtro_verde">
                <div className="bground"></div>

                <div className="apresentacao_conteudo">
                  <h1 className="apresentacao_titulo">
                    {ReactHtmlParser(val.title)}
                  </h1>
                  <p className="apresentacao_descricao">
                    {ReactHtmlParser(val.subTitle)}
                  </p>
                  <button
                    type="button" /* className="btn_more" */
                    className={`btn_more ${
                      linkUrl === undefined || linkUrl === "" ? "d-none" : ""
                    }`}
                  >
                    <a href={linkUrl.url} target={linkUrl.target}>
                      <AppButton type="" className="" text={this.t("more")} />
                    </a>
                  </button>
                </div>
              </div>
            </>
          )}

          {/* <div className="filtro_verde">
            <div className="bground"></div>

            <div className="apresentacao_conteudo">
              <h1 className="apresentacao_titulo">
                {ReactHtmlParser(val.title)}
              </h1>
              <p className="apresentacao_descricao">
                {ReactHtmlParser(val.subTitle)}
              </p>
              <button
                type="button" // className="btn_more"
                className={`btn_more ${
                  linkUrl === undefined || linkUrl === "" ? "d-none" : ""
                }`}
              >
                <a href={linkUrl.url} target={linkUrl.target}>
                  <AppButton type="" className="" text={this.t("more")} />
                </a>
              </button>
            </div>
          </div> */}
        </div>
      );
    });

    return banner;
  };

  /**
   * Destaques -- slideshow
   */
  makeDestak = () => {
    let destak = null;

    destak = this.state.data.featProds.map((val, key) => {
      // obtem imagem do banner
      let img = extractElementsFromArrayByIsType(val.imgs, "generic");
      // valida resultado obtido e gera o link absoluto da imagem
      img =
        img !== null ? insertMediaWithAppAbsPath(img[0].name, "imgs") : null;

      let linkUrl =
        Array.isArray(val.links) && val.links.length > 0 ? val.links[0] : "";

      return (
        <div className="item" key={key}>
          <div
            className="destak_img"
            style={{ backgroundImage: `url(${img})` }}
          ></div>
          <div className="xpto filtro"></div>
          <div className="barra"></div>
          <div className="MouseMove">
            <span></span>
          </div>

          <div className="destaques_conteudo">
            <h3 className="destaques_titulo">{val.title}</h3>
            <p className="subcategoria_cat">
              {ReactHtmlParser(val.description)}
            </p>
            <button
              type="button" /* className="btn_more" */
              className={`btn_more ${
                linkUrl === undefined || linkUrl === "" ? "d-none" : ""
              }`}
            >
              <a href={linkUrl.url} target={linkUrl.target}>
                <AppButton type="" className="" text={this.t("more")} />
              </a>
            </button>
          </div>
        </div>
      );
    });

    return destak;
  };

  /**
   * Universo -- variantes
   */
  makeUniverso = () => {
    let universo = null;

    universo = this.state.data.universe.map((val, key) => {
      // obtem imagem do banner
      let img = extractElementsFromArrayByIsType(val.imgs, "generic");
      // valida resultado obtido e gera o link absoluto da imagem
      img =
        img !== null ? insertMediaWithAppAbsPath(img[0].name, "imgs") : null;

      // prepara URL...
      let url = `${this.i18n.language}/${this.t("menu").about[1]}/${val.id}/${
        val.slug
      }`;

      return (
        <div className="universo_somos col-lg-2 col-md-4 col-sm-6" key={key}>
          <div
            className="universo_detalhe "
            style={{ backgroundImage: `url(${img})` }}
          >
            <a href={url}>
              <div className="universo_body">
                <div className="universo_detalhe_1"></div>
                <h6 className="universo_titulo" href={url}>
                  {val.title}
                </h6>
                <div className="btn_universe">
                  <AppButton className="universe_button" />
                </div>
              </div>
            </a>
          </div>
        </div>
      );
    });

    return universo;
  };

  /**
   * Serviços
   */
  makeService = () => {
    let service = null;

    service = this.state.data.featServices.map((val, key) => {
      // obtem imagem do banner
      let img = extractElementsFromArrayByIsType(val.imgs, "generic");
      // valida resultado obtido e gera o link absoluto da imagem
      img =
        img !== null ? insertMediaWithAppAbsPath(img[0].name, "imgs") : null;

      let linkUrl =
        Array.isArray(val.links) && val.links.length > 0 ? val.links[0] : "";

      return (
        <div
          className="col-12 col-lg-6 col-xl-6 ft-servicos"
          key={key}
          style={{ backgroundImage: `url(${img})` }}
        >
          <>
            <div className="filtro_cor"></div>
            <div className="introSection">
              <div className="line white_bg"></div>
              <h6 className="title_service">
                {HtmlParser(this.props.t("menu").services[0])}
              </h6>
            </div>
            <div className="servicos_conteudo">
              <h6 className="servicos_titulo">{ReactHtmlParser(val.title)}</h6>
              <button
                type="button" /* className="btn_more" */
                className={`btn_more ${
                  linkUrl === undefined || linkUrl === "" ? "d-none" : ""
                }`}
              >
                <a href={linkUrl.url} target={linkUrl.target}>
                  <AppButton type="" className="" text={this.t("more")} />
                </a>
              </button>
            </div>
          </>
        </div>
      );
    });

    return service;
  };

  /**
   * Projectos
   */
  makeProject = () => {
    let project = null;

    project = this.state.data.featProjs.map((val, key) => {
      // obtem imagem do banner
      let img = extractElementsFromArrayByIsType(val.imgs, "generic");
      // valida resultado obtido e gera o link absoluto da imagem
      img =
        img !== null ? insertMediaWithAppAbsPath(img[0].name, "imgs") : null;

      let linkUrl =
        Array.isArray(val.links) && val.links.length > 0 ? val.links[0] : "";

      return (
        <div className="item" key={key}>
          <div
            className="bg_projeto"
            style={{ backgroundImage: `url(${img})` }}
          >
            <div className="bg_titulo">
              <h6 className="titulo_projeto">{val.title}</h6>
              <div className="descricao_projeto">
                {ReactHtmlParser(val.description)}
              </div>
              <button
                type="button"
                className={`btn_more ${
                  linkUrl === undefined || linkUrl === "" ? "d-none" : ""
                }`}

                /* className="btn_more" */
              >
                <a href={linkUrl.url} target={linkUrl.target}>
                  <AppButton type="" className="" text={this.t("more")} />
                </a>
              </button>
            </div>
          </div>
        </div>
      );
    });

    return project;
  };

  render() {
    return (
      <>
        <div className="apresentacao">
          <div className="owl-carousel apresentacao_slideshow">
            {this.state.data.carousel !== undefined ? this.makeBanner() : null}
          </div>
        </div>

        <div className="destaques">
          <div className="introSection">
            <div className="line"></div>
            <h6 className="slogan_destaques">
              {HtmlParser(this.props.t("views").home.slogan_destak)}
            </h6>
          </div>

          <div className="owl-carousel destaques_slideshow">
            {this.state.data.featProds !== undefined ? this.makeDestak() : null}
          </div>
        </div>

        <div className="universo_variantes_1">
          <div className="introSection">
            <div className="line"></div>
            <h6 className="title_universo">
              {this.props.t("views").home.title_universo}
            </h6>
          </div>

          <h6 className="slogan_universo">
            {HtmlParser(this.props.t("views").home.slogan_universo)}
          </h6>

          <div className="universo_variantes">
            {this.state.data.universe !== undefined
              ? this.makeUniverso()
              : null}
          </div>
        </div>

        <div className="oferta">
          {this.state.data.featServices !== undefined
            ? this.makeService()
            : null}

          <div className="col-12 col-lg-6 col-xl-6 projectos">
            <div className="introSection">
              <div className="line"></div>
              <h6 className="title_universo">
                {this.props.t("menu").projects[0]}
              </h6>
            </div>
            <div className="owl-carousel projetos_slideshow">
              {this.state.data.featProjs !== undefined
                ? this.makeProject()
                : null}
            </div>
          </div>
        </div>
      </>
    );
  }
}

// HOC para traduções
export default withTranslation()(Home);
