import React, { Component, Fragment } from "react";
import { withTranslation } from "react-i18next";
import $ from "jquery";
import RestDataSource from "../../components/webservices/RestDataSource";

import {
  insertMediaWithAppAbsPath,
  extractElementsFromArrayByIsType,
} from "../../Toolbox";

import AppButton from "../AppButton";

import ReactHtmlParser from "react-html-parser";

import "../../scss/ServicesView.scss";
import { Parallax, ParallaxProvider } from "react-scroll-parallax";


class BendingView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lang: null,
      data: {},
    };

    this.t = this.props.t; //tradutor
    this.i18n = null;

    this.logicLatch = false; //TRINCO LÓGICO - FALSE aberto | TRUE fechado

    this.optionId = undefined; //ID da CATEGORIA dos artigos
    this.optionSlug = undefined; //respectivo slug

    if (
      this.props.match &&
      this.props.match.params &&
      "optionId" in this.props.match.params &&
      "optionSlug" in this.props.match.params
    ) {
      this.optionId = this.props.match.params.optionId;
      this.optionSlug = this.props.match.params.optionSlug;
    }

    this.webserver = new RestDataSource(); // para comunicação com a API (REST)
    this.restURI = `rest/bending/content/`; // para utilização nos pedidos de dados à API / URI do pedido
  }

  /**
   * @description
   * Para inicialização dos componentes e afins após o seu carregamento no DOM
   */
  componentDidMount() {
    window.isFristTimeLoad = true;
    window.sessionStorage.removeItem("stickyBFM");
    this.t = this.props.t; // p utilização nas acções de tradução
    this.i18n = this.props.i18n;

    let { lang } = this.state;
    lang = this.i18n.language;

    this.setState({ lang });

    this.logicLatch = true;
    this.getDataFromAPI(this.makeURI());
  }

  /**
   * @description
   * Verifica se existem alterações nas props e/ou state e executa
   * as necessárias alterações em conformidade, exemplo, quando se
   * altera o idioma do site poderá ser necessário obter novos termos
   * no novo idioma para preenchimento do layout...
   * caso se verifique que os mesmos já existam dever-se-à evitar a
   * utilização deste recurso, isto porque, poderá estar a criar um
   * conjunto de pedidos desnecessários e que eventualmente tornarão
   * esta solução mais lenta
   *
   * @param {object} prevProps
   * @param {object} prevState
   */
  componentDidUpdate(prevProps, prevState) {
    let { lang } = prevState;
    let isSameUrl = false;
    var onwNavBar = this.props.navData;

    if (!window.isFristTimeLoad) {
      return;
    }

    if (lang !== this.i18n.language) {
      lang = this.i18n.language;

      this.setState({ lang }, () => {
        //idioma alterado, obtem os dados da API no novo idioma
       /* if (this.logicLatch === false) {
          //fecha o trinco para efeitos de acesso ao pedido de
          //novos conteúdos à API
          this.logicLatch = true;
          //executa o pedido
          this.getDataFromAPI(this.makeURI()); //(requestURI);
        }*/
        this.getDataFromAPI(this.makeURI()); //(requestURI);
        let oldUrl = window.sessionStorage.getItem("oldUrl")
            ? window.sessionStorage.getItem("oldUrl")
            : prevProps.match.url,
            newUrl = window.location.href,
            isSameUrl = true;
        if (!newUrl.includes(oldUrl) || window.isFristTimeLoad) {
          isSameUrl = false;
        }

        if (onwNavBar === undefined) {
          onwNavBar = JSON.parse(window.sessionStorage.getItem("navigation"));
        }
      });


    }
  }

  /**
   * @description
   *  Método responsável para fazer o pedido de dados à API
   *
   */
  makeURI() {
    let requestURI = `${this.i18n.language}/${this.restURI}`;

    // verifica se existe optionId e optionSlug e acrescenta-os ao URI
    if (this.optionId !== undefined && this.optionSlug !== undefined) {
      requestURI += `${this.optionId}`;
    }

    return requestURI;
  }

  /**
   * @description
   * Método responsável por usar o pedido de dados à API... a usar nas life cycle
   * componentDidMount e/ou componentDidUpdate
   */
  getDataFromAPI = (requestURL) => {
    this.webserver.getData(requestURL, (result, xhr) => {
      this.logicLatch = false;

      if (xhr.status === 200 && "data" in xhr && typeof xhr.data === "object") {
        let { data } = this.state;
        data = result;

        this.setState({ data });
      }
    });
  };

  makeBody = () => {
    let body = null;
    if (this.state.data !== null && this.state.data.content) {
      body = this.state.data.content.all.map((val, key) => {
        let linkUrl =
          Array.isArray(val.links) && val.links.length > 0 ? val.links[0] : "";

        let img_generic = extractElementsFromArrayByIsType(val.imgs, "generic");
        let img_thumbnail = extractElementsFromArrayByIsType(
          val.imgs,
          "thumbnail"
        );
        let img_gallery = extractElementsFromArrayByIsType(val.imgs, "gallery");

        let backgroundGeneric,
          backgroundThumbnail,
          backgroundGallery = [];

        backgroundGeneric =
          img_generic !== null
            ? insertMediaWithAppAbsPath(img_generic[0].name, "imgs")
            : "";

        backgroundThumbnail =
          img_thumbnail !== null
            ? insertMediaWithAppAbsPath(img_thumbnail[0].name, "imgs")
            : "";

        backgroundGallery =
          img_gallery !== null
            ? insertMediaWithAppAbsPath(img_gallery[0].name, "imgs")
            : "";

        var slog = val.subTitle.split(" ");

        /*
         * validação do id para a criação da ancora para o menu lateral
         * verifica se o id do artigo não é undefined,
         * caso se verifique essa condição, então
         * a variável idAncora recebe o id da categoria associada ao
         * id do artigo
         */
        if (val.id !== "undefined") {
          var idAncora = val.category[0].id;
        }

        return (
          <div
            className={`serviceUnit ${
              key % 2 === 0 && key > 0 ? "serviceUnitGray" : ""
            }`}
            key={val.id}
            /* id={"f" + idAncora} */
          >
            <div className="each-category-service" id={"f" + idAncora}></div>
            <div
              className={`imageThird ${
                backgroundGallery.length === 0 ? "block" : ""
              }`}
              style={{ backgroundImage: "url(" + backgroundGallery + ")" }}
            >
              <div className="line"></div>
              <div
                className={`eachElement ${
                  key % 2 === 0 && key > 0 ? "eachElementGray" : ""
                }`}
              >
                <div className="conteudoText">
                  <h3 className="category_section">
                    {this.props.t("menu").services[0]}
                  </h3>
                  <h1 className="title_section">
                    {ReactHtmlParser(val.title.split())}
                  </h1>
                  <div className="description_section">
                    {ReactHtmlParser(val.description)}
                  </div>
                  <button
                    type="button"
                    className={`btn_more ${
                      linkUrl.length === 0 ? "d-none" : ""
                    }`}
                  >
                    <a href={linkUrl.url}>
                      <AppButton type="" className="" text={this.t("more")} />
                    </a>
                  </button>
                </div>
              </div>
              <Fragment>
                <div
                  className={`conteudoMedia ${
                    backgroundThumbnail.length === 0 ? "noExtra" : ""
                  }`}
                >
                  <div
                    className={`imageFirst ${
                      backgroundGeneric.length === 0 ? "d-none" : ""
                    }`}
                    style={{
                      backgroundImage: "url(" + backgroundGeneric + ")",
                    }}
                  ></div>
                  <div
                    className={`imageSecond ${
                      backgroundThumbnail.length === 0 ? "d-none" : ""
                    }`}
                    style={{
                      backgroundImage: "url(" + backgroundThumbnail + ")",
                    }}
                  ></div>
                </div>
              </Fragment>
              <div
                className={`wordSpace ${
                  slog[0].length === 0 /* slog[0]  !== ""*/ ? "d-none" : ""
                }`}
              >
                <ParallaxProvider>
                  <Parallax
                    className="custom-class"
                    x={[-30, 60]}
                    tagOuter="figure"
                  >
                    <h1 className="word_title">
                      {slog !== null ? slog[0] : null}
                      <Parallax
                        className="custom-class"
                        x={[30, -60]}
                        tagOuter="figure"
                      >
                        <span
                          className={`wordStroke ${
                            slog !== null &&
                            slog.length >= 2 &&
                            !slog[0].length - 1
                              ? "wordStroke2"
                              : "" || (slog !== null && slog.slice(-1)[0]) //slog[0].length - 1
                              ? "wordStroke3"
                              : ""
                          }`}
                        >
                          {slog !== null ? slog.slice(1).join(" ") : null}
                        </span>
                      </Parallax>
                    </h1>
                  </Parallax>
                </ParallaxProvider>
              </div>
            </div>
            {/*  <div className="line"></div> */}
          </div>
        );
      });
    }
    return body;
  };

  render() {
    return (
      <div className="body_services">
        {this.state.data !== undefined ? this.makeBody() : null}
        {/* {this.makeBody()} */}
        <p className="phrase_side">{this.props.t("views").servicos.slogan}</p>
      </div>
    );
  }
}

export default withTranslation()(BendingView);
setTimeout(() => {
  window.dispatchEvent(new Event("resize")); /* console.clear(); */
}, 1000);
