import React, { Component, Fragment } from "react";
import { NavLink } from "react-router-dom";
import { withTranslation } from "react-i18next";
import $ from "jquery";

import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";

import "../scss/fragments/StickyFilterMenu.scss";

import { library } from "@fortawesome/fontawesome-svg-core";
//import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { faCoffee } from '@fortawesome/free-solid-svg-icons'

import { changeBrowserHistory } from "../../src/Toolbox";

import {
  faCommentsDollar,
  faFilter,
  faSearch,
  faSortNumericDownAlt,
  faSortNumericUpAlt,
  //  faCommentsDollar,
} from "@fortawesome/free-solid-svg-icons";
library.add(faFilter, faSearch, faSortNumericDownAlt, faSortNumericUpAlt);

/**
 * @description
 * Para tratamento e apresentação do menu lateral / topo /
 * direito sobreposto a todo o conteúdo.
 *
 * @param {string} state.lang -> para definição do idioma
 * @param {string} state.data -> opç~poes a apresentar no menu a
 *                              dispnibilizar via props...
 */
class StickyFilterMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lang: null,
      data: null, // stickyFMOptions
      //sizeBody: false, // TRUE para menu aberto | FALSO c.c. (caso contrário)
    };

    // para efeitos de tradução / internacionalização
    /* this.t = this.props.t;
    this.i18n = this.props.i18n; */
    this.t = null;
    this.i18n = null;

    // mobile to desktop device limit
    this.mobile2Desktop = 992;
  }

  /**
   * @description
   *
   * @param {*}
   */
  componentDidMount() {
    /**TEMP ***/
    this.t = this.props.t; //para utilização nas ações de tradução
    this.i18n = this.props.i18n;

    let { lang } = this.state;
    lang = this.i18n.language;

    this.setState({ lang });
  }

  /**
   * @description
   * Para cosntrução das opções do menu. este processo acontece em
   * modo UPDATE devido ao facto, entre outros, de estas opções
   * apenas se verificarem disponíveis após a conclusão do acesso
   * à API a obtenção de NAVIGATION data, por exemplo e no caso das
   * páginas ce contexto ABOUT... (SOMOS...:)
   *
   * @param {*} prevProps -> valores das props anteriores ao UPDATE
   * @param {*} prevState -> valores de state var antrior ao UPDATE
   */
  componentDidUpdate(prevProps, prevState) {
    let { data, lang } = prevState;

    /**
     * Se DATA null e os dados obtidos são um array de dados, com
     * conteúdo, conjugado com o facto dos dados actuais serem
     * diferentes dos dados anteriormente guardados, procede à
     * actualização da informação / dados
     */

    if (
      data === null &&
      Array.isArray(this.props.stickyFMOptions) &&
      this.props.stickyFMOptions.length > 0 &&
      data !==
        prevState /* &&
      prevProps.stickyFMOptions !== prevState.data */
    ) {
      data = this.props.stickyFMOptions;
      this.setState({ data });
    }

    if (lang !== this.i18n.language) {
      lang = this.i18n.language;
      this.setState({ lang });
    }

    var isCollidedToSticky = false;
    var isCollidedOffSetToSticky = 100000;

    $(window).on("resize scroll", function () {
      let $stickyMenu = $(".sticky-fm");
      let $appFooter = $(".app-footer");

      //StickyMenu
      if ($stickyMenu.isColliding($(".app-footer"))) {
        if (!isCollidedToSticky) {
          isCollidedToSticky = true;
          isCollidedOffSetToSticky = $(window).scrollTop();
        }
        let fixedSize =
          $appFooter.offset().top - $stickyMenu.outerHeight() + "px";
        $stickyMenu.css({
          position: "absolute",
          top: fixedSize,
        });
      }

      if (isCollidedOffSetToSticky >= $(window).scrollTop()) {
        $stickyMenu.css({
          position: "fixed",
          top: "110px",
        });
      }
    });
  }

  /**
   * @description
   * Default event handler...
   *
   * @param {*} e
   */
  handleItemClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const target = e.target;

    let _goToByHref = target.href.substring(target.href.indexOf("#f") + 1);

    let _goTo = document.getElementById(_goToByHref);

    if (_goTo !== undefined && _goTo !== null) {
      /* _goTo.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start",
      }); */

      window.scrollTo({
        top: _goTo.offsetTop,
        behavior: "smooth",
      });
    } else {
      window.location.href = _goToByHref;

      /* _goToByHref.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start",
      }); */
      window.scrollTo({
        top: _goTo.offsetTop - 300,
        behavior: "smooth",
      });
      return _goToByHref;
    }
    if (window.mobileAndTabletCheck() !== "Desktop") {
      this.closeMenuMobile(e);
    }
  };

  /*   changeURL = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const target = e.target;

    let _goTo = target.href.substring(target.href.indexOf("#f") + 1);

    _goTo = document.getElementById(_goTo);

    if (_goTo !== undefined) {
      _goTo.scrollIntoView({
        behavior: "smooth",
        // block: "center",
       // inline: "center", 
      });
    }
  }; */

  /**Abrir StickyMenu em dispositivos móveis */
  openMenuMobile = (e) => {
    e.preventDefault();
    e.stopPropagation();

    let icon = document.getElementsByClassName("iconSticky");
    icon[0].style.right = "-80px";

    let menu = document.getElementsByClassName("sticky-fm");
    menu[0].style.right = "0";
  };

  /**Fechar StickyMenu em dispositivos móveis */
  closeMenuMobile = (e) => {
    e.preventDefault();
    e.stopPropagation();

    let icon = document.getElementsByClassName("iconSticky");
    icon[0].style.right = "0px";

    let menu = document.getElementsByClassName("sticky-fm");
    menu[0].style.right = "-380px";
  };

  render() {
    let stickyBFMByStorage = JSON.parse(
      window.sessionStorage.getItem("stickyBFM")
    );
    let data = this.state.data;

    if (stickyBFMByStorage !== null) {
      data = stickyBFMByStorage;
    }
    return (
      <Fragment>
        <div className="iconSticky d-lg-none" onClick={this.openMenuMobile}>
          <img src="/assets/imgs/Seta-botao-01.png" alt="seta1" />
          <img src="/assets/imgs/Seta-botao-01.png" alt="seta2" />
          <img src="/assets/imgs/Seta-botao-01.png" alt="seta3" />
        </div>

        <div className="sticky-fm">
          <div className="closeSticky d-lg-none" onClick={this.closeMenuMobile}>
            <img src="/assets/imgs/close.png" alt="close" />
          </div>
          <ul>
            {data !== null
              ? data.map((val) => {
                  let thisIsIt = null;
                  // verifica se o window location possui o URI do valor em curso
                  if (window.location.pathname.indexOf(val.uri) >= 0) {
                    thisIsIt = "active";
                  } else if (window.location.href.indexOf(val.uri) >= 0) {
                    thisIsIt = "active";
                  }

                  return (
                    <li
                      key={val.id}
                      className={thisIsIt !== null ? thisIsIt : ""}
                      id={val.id + "sticky"}
                      /*  onClick={this.changeURL} */
                    >
                      <a
                        href={`/${this.state.lang}/${val.uri}`}
                        className={""}
                        onClick={
                          val.onClick !== undefined
                            ? val.onClick
                            : this.handleItemClick
                        }
                      >
                        {val.text}
                      </a>
                    </li>
                  );
                })
              : null}
          </ul>
        </div>
      </Fragment>
    );
  }
}

export default withTranslation()(StickyFilterMenu);

export class ListFilter extends Component {
  isFirstLoad = true;
  constructor(props) {
    super(props);

    this.state = {
      options: null, // stickyFMOptions
      searchKeyDownEvent: null, // para detecção do ENTER na pesquisa
      magStats: false, // TRUE aberto | FALSE fechado
      filterStats: false, // TRUE aberto | FALSE fechado
      searchStats: false,
      orderStats: false,
      selectedOrder: "",
      selOrderOption: null,
    };

    this.searchClass = "m-opened";
    this.filterClass = "f-opened";

    this.t = this.props.t;
  }

  /**
   *
   */
  componentDidMount() {
    let { selectedOrder, selOrderOption } = this.state;

    selOrderOption = "otidesc";
    selectedOrder = this.props.t("newer");
    this.setState({ selectedOrder, selOrderOption });
  }

  /**
   *
   * @param {*} prevProps
   * @param {*} prevState
   */
  componentDidUpdate(prevProps, prevState) {
    let { options, searchKeyDownEvent } = this.state;

    if (
      this.props.filterOptions &&
      this.props.filterOptions !== undefined &&
      (options === null ||
        JSON.stringify(options) !== JSON.stringify(this.props.filterOptions))
    ) {
      options = this.props.filterOptions;
      this.setState({ options });
    }

    if (
      this.props.srchKeyDownHandler &&
      this.props.srchKeyDownHandler !== undefined &&
      (searchKeyDownEvent === null ||
        JSON.stringify(searchKeyDownEvent) !==
          JSON.stringify(this.props.srchKeyDownHandler))
    ) {
      searchKeyDownEvent = this.props.srchKeyDownHandler;
      this.setState({ searchKeyDownEvent });
    }
  }

  /**
   * @description
   * Fabrica os componentes do filtro
   *
   */
  makeFilter = () => {
    return (
      <>
        <span
          className="f-close d-lg-none"
          onClick={this.handleFilterCallerClick}
        ></span>
        <ul>
          {this.state.options
            ? this.state.options.map((val, key) => {
                let thisIsIt = null;
                $(".filter-container.ProjectsView li").removeClass("active");
                $(".filter-container.ProjectsView li a").removeClass("active");
                // verifica se o window location possui o URI do valor em curso
                if (window.location.pathname.indexOf(val.uri) >= 0) {
                  thisIsIt = "active";
                } else if (
                  window.location.search.indexOf("fc1=" + val.id) >= 0
                ) {
                  $("#" + val.id + "filtro").addClass("active");
                } else {
                  if (this.isFirstLoad) {
                    this.isFirstLoad = !this.isFirstLoad;
                    setTimeout(() => {
                      $("#-1filtro").addClass("active");
                      $("#-1filtro a").addClass("active");
                    }, 100);
                  }
                }
                return (
                  <li
                    key={val.id}
                    className={thisIsIt !== null ? thisIsIt : ""}
                    id={val.id + "filtro"}
                  >
                    <NavLink
                      to={`/${val.uri}`}
                      data-slug={val.slug ? val.slug : ""}
                      onClick={(e) => {
                        if (typeof val.onClick === "function") {
                          val.onClick(e);
                        } else {
                          this.haldleFilterClick(e);
                        }
                      }}
                    >
                      {val.text}
                    </NavLink>
                  </li>
                );
              })
            : null}
        </ul>
      </>
    );
  };

  /**
   * @description
   * Fabrica os componentes do ordenador
   */
  makeOrder = () => {
    return (
      <Dropdown
        onBlur={this.handleDropDownBlur}
        onClick={(e) => this.changeOrderStats()}
        onSelect={this.handleOrderSelect}
      >
        <Dropdown.Toggle variant="light" id="dropdown-basic">
          {this.state.selectedOrder ? this.state.selectedOrder : ""}
          <img src="/assets/imgs/Seta-botao-3.png" alt="seta" />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item
            href="otidesc"
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            {this.t("newer")}
          </Dropdown.Item>
          <Dropdown.Item
            href="otiasc"
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            {this.t("older")}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  changeOrderStats = () => {
    let { orderStats } = this.state;

    orderStats = !orderStats;
    this.setState({ orderStats }, () => {
      let order = document.getElementsByClassName("order");

      if (order.length > 0) {
        let dropDownBtn = order[0].getElementsByClassName("dropdown-toggle"); //dropdown-toggle
        let optionsDrop = document.getElementsByClassName("dropdown-menu");

        if (dropDownBtn.length > 0) {
          // orderStats = true => add class or-open
          // orderStats = true => remove class or-open
          orderStats === true
            ? dropDownBtn[0].classList.add("or-open")
            : dropDownBtn[0].classList.remove("or-open");
        }

        switch (
          optionsDrop[0].childNodes[0].innerHTML === this.state.selectedOrder
        ) {
          case true:
            optionsDrop[0].childNodes[0].style.display = "none";
            optionsDrop[0].childNodes[1].style.display = "block";
            break;

          default:
            optionsDrop[0].childNodes[0].style.display = "block";
            optionsDrop[0].childNodes[1].style.display = "none";
            break;
        }
      }
    });
  };

  /**
   * @description
   * Para tratamento de eventuais necessidades ao fecho da
   * dropdown, exemplo, recolocação do chevron indicativo do
   * fecho/abertura deste componente
   *
   * @param {*} e
   */
  handleDropDownBlur = (e) => {
    if (this.state.orderStats === true) {
      this.changeOrderStats();
    }
  };

  /**
   * @description
   * Para tratamento da informaçaõ relacionada com a ordenação do(s)
   * pedidos(s) à base de dados
   *
   * @param {*} selOption
   */
  handleOrderSelect = (selOption) => {
    this.setOrder(selOption);
  };

  /**
   *
   * @param {*} e
   */
  haldleFilterClick = (e) => {
    e.preventDefault();

    const target = e.target;

    let selFilter = target.href;
    let selFilerExpr = target.dataset.slug;

    let liCont = target.closest("li");
    let parentUl = liCont.parentNode;
    $(".flt-container .active").removeClass("active");

    $(".filter ul li").removeClass("active");

    /*
      .parentUl.parentNode.getElementsByClassName("active")[0]
      .classList.remove("active");*/

    liCont.classList.add("active");

    let fcFilter = selFilter.split("/").filter((val) => {
      return val.indexOf("fc") >= 0;
    });

    //fecha o sticky após o click dos filtros
    setTimeout(() => {
      let { filterStats } = this.state;
      filterStats = !filterStats;
      this.setState({ filterStats });
    }, 300);

    changeBrowserHistory(null, "");
    this.props.setParentData({ selFilter });
  };

  handleOnChange = (e) => {
    e.preventDefault();

    const target = e.target;

    let searchExpr = target.value;
    this.props.setParentData({ searchExpr });
  };

  /* handleSearchOnKeyDown = (e) => {
    const target = e.target;

    if (e.key === "Enter") {
      this.props.setParentData({ searchExpr });
    }
  }; */

  handleMagnifierClick = (e) => {
    const target = e.target;
    let { magStats } = this.state;

    magStats = !magStats;

    if (!magStats) {
      // limpa input
      document.getElementById("listsearch").value = "";
      let searchExpr = null;
      this.props.setParentData({ searchExpr });
    }

    this.setState({ magStats });
  };

  /**
   * @description
   * Caller do filtro qdo em mobile...
   *
   * @param {*} e
   */
  handleFilterCallerClick = (e) => {
    e.preventDefault();
    const target = e.target;

    let { filterStats, searchStats } = this.state;
    filterStats = !filterStats;

    if (searchStats) {
      searchStats = false;
    }

    this.setState({ filterStats, searchStats });
  };

  /**
   * @description
   * Tratamento de acções sobre a pesquisa...
   *
   * @param {*} e
   */
  handleSearchCallerClick = (e) => {
    const target = e.target;

    let { searchStats, filterStats } = this.state;
    searchStats = !searchStats;

    if (filterStats) {
      filterStats = false;
    }

    this.setState({ searchStats, filterStats });
  };

  /**
   * @description
   * Executa a troca das opções do filtro quando em mobile
   *
   * otidesc => otiasc
   * otiascc => otides
   *
   * @param {*} e
   */
  handleMobileSorterClick = (e) => {
    const target = e.target;
    let { selOrderOption } = this.state;

    // efeitos de troca...
    // otidesc => otiasc
    // otiasc => otidesc
    selOrderOption = selOrderOption === "otidesc" ? "otiasc" : "otidesc";
    // actualiza opções de filtro em STATE var para que as mesmas se façam reflectir
    // na versão desktop
    this.setOrder(selOrderOption);
  };

  /**
   *
   * @param {*} selOption
   */
  setOrder = (selOption) => {
    let { selectedOrder, selOrderOption } = this.state;
    selOrderOption = selOption;

    // asc => newer | desc => older
    // definição do texto da dropdown... esta situação poderia ser executada de
    // outra forma... eventual refactoring mais tarde...
    switch (selOption) {
      case "otiasc":
        selectedOrder = this.t("older");
        break;

      default:
        selectedOrder = this.t("newer");
    }

    this.setState({ selectedOrder, selOrderOption });
    this.props.setParentData({ selectOrder: selOption });
  };

  render() {
    return (
      <>
        <div
          className="filter-caller d-lg-none"
          onClick={this.handleFilterCallerClick}
        >
          <FontAwesomeIcon icon="filter" size="lg" />
        </div>
        <div
          className="filter-caller d-lg-none"
          onClick={this.handleSearchCallerClick}
        >
          <FontAwesomeIcon icon="search" size="lg" />
        </div>
        <div
          className="filter-caller d-lg-none"
          onClick={this.handleMobileSorterClick}
        >
          <FontAwesomeIcon
            icon="sort-numeric-down-alt"
            className={`myorder otidesc ${
              this.state.selOrderOption === "otidesc" ? "" : "d-none"
            }`}
            size="lg"
          />
          <FontAwesomeIcon
            icon="sort-numeric-up-alt"
            className={`myorder otiasc ${
              this.state.selOrderOption === "otidesc" ? "d-none" : ""
            }`}
            size="lg"
          />
        </div>
        <div
          className={`filter ${this.state.filterStats ? this.filterClass : ""}`}
        >
          {
            /* <ListFilter filterOptions={this.state.filter} /> */
            this.makeFilter()
          }
        </div>
        <div className="order">{this.makeOrder()}</div>
        <div
          className={`list-search ${
            this.state.searchStats ? this.searchClass : ""
          }`}
        >
          <span
            className="f-close d-lg-none"
            onClick={this.handleSearchCallerClick}
          ></span>
          <div className="container-fluid col-12 col-lg-10">
            <div
              className={`form-group ${
                this.state.magStats ? this.searchClass : ""
              }`}
            >
              <input
                type="text"
                className="form-control"
                id="listsearch"
                name="listsearch"
                placeholder={this.t("menu").search[0]}
                onChange={this.handleOnChange}
                onKeyDown={this.state.searchKeyDownEvent}
              />
              <span className="underline"></span>
            </div>
            <div
              className="magnifier d-none d-lg-block"
              onClick={this.handleMagnifierClick}
            >
              <FontAwesomeIcon icon="search" size="lg" />
            </div>
          </div>
        </div>
      </>
    );
  }
}

