import React, { Component /* Fragment */ } from "react";
import { withTranslation } from "react-i18next";
import {
  extractElementsFromArrayByIsType,
  insertMediaWithAppAbsPath,
} from "../../../Toolbox";

import ReactHtmlParser from "react-html-parser";

import "../../../../src/scss/AboutSinergy.scss";

/**
 * AKA SOMOS SIRNEGIES
 */
class AboutSinergy extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lang: null,
      data: null,
    };

    this.t = props.t; // tradutor
    this.i18n = null;
  }

  componentDidMount() {
    //console.log(this.props);

    this.t = this.props.t; // p utilização nas acções de tradução
    this.i18n = this.props.i18n;

    let { lang } = this.state;
    lang = this.i18n.language;

    this.setState({ lang });
  }

  componentDidUpdate(prevProps, prevState) {
    window.sessionStorage.removeItem("stickyBFM");
    let { lang, data } = this.state;

    /*  if (
      this.props.pData &&
      Object.keys(this.props.pData).length > 0 &&
      data === null
    ) {
      data = this.props.pData.content;
      this.setState({ data }, () => console.log(this.state));
    } */

    if (
      "pData" in this.props &&
      "content" in this.props.pData &&
      (data === null ||
        JSON.stringify(this.props.pData.content) !== JSON.stringify(data)) &&
      Object.keys(this.props.pData).length > 0
    ) {
      lang = this.i18n.language;
      data = this.props.pData.content;
      this.setState({ lang, data });
    }
  }

  makeIntro = () => {
    let intro = null;

    if (this.state.data !== null && this.state.data.sinergias.intro) {
      return (
        <h2 className="title_sinergy">
          {this.state.data.sinergias.intro.title}
        </h2>
      );
    }
    return intro;
  };

  makeSinergy = () => {
    let sinergy = null;

    if (this.state.data !== null && this.state.data.sinergias.company) {
      // console.log(this.state.data);
      sinergy = this.state.data.sinergias.company.map((val, key) => {
        // obtem imagem do banner
        let img = extractElementsFromArrayByIsType(val.imgs, "generic");

        // valida resultado obtido e gera o link absoluto da imagem
        img =
          img !== null ? insertMediaWithAppAbsPath(img[0].name, "imgs") : null;
        // obtem alt para imagem para efeitos de SEO
        let alt = val.imgs[0].alt;

        var linkUrl =
          Array.isArray(val.links) && val.links.length > 0 ? val.links[0] : "";

        var openLink = function image(img) {
          var src = "https://" + linkUrl.url;

          if (val.links && val.links !== undefined && val.links.length > 0) {
            window.open(src);
          }
        };

        return (
          <div className="each-company" key={val.id}>
            <div className="media ">
              <a href={linkUrl.url} target="_blank" rel="noopener noreferrer">
                <img src={img} className="company_logos" alt={alt} />
              </a>
              <div className="media-body">
                <h6 className="description_companies">{ReactHtmlParser(val.description)}</h6>
              </div>
            </div>
          </div>
        );
      });
    }
    return sinergy;
  };

  render() {
    //return null;
    return (
      <>
        <div className="body-content_about">
          {this.state.data !== undefined ? this.makeIntro() : null}
          <div className="all-company">
            {this.state.data !== undefined ? this.makeSinergy() : null}
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(AboutSinergy);
