import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import ReactHtmlParser from "react-html-parser";

import Forms from "../../components/Forms";
import "../../scss/form/PopUpForm.scss";
//import { type } from "jquery";

import RestDataSource from "../../components/webservices/RestDataSource";

import { setDataToFormData } from "../../Toolbox";
/* import { data } from "jquery";
import { lang } from "moment"; */

/**
 * @description
 * Imprime um FORMUlÁRIO no formato POPUP o conteúdo do formulário tem
 * de ser adaptado de acordo com as necessidades do cliente
 *
 * Para o suporte desta a qualquer conteúdo será necessário proceder a
 * refactoring no sentido de a dotar da possibilidade de alterar o conteúdo central
 *
 */
class PopUpForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lang: null,
      openStats: false, // definição do estado de abertura da FORM -> FALSE p fechado | TRUE p aberto
      resMsgStats: false,
      resMsg: "",
      formData: null, // dados da FORM
    };

    this.resMsgStatsClass = "sr-opened";
    this.openStatsClass = "br-opened"; // class que permite alterar visualmente o container da FORM
    // de acordo com o seu estado de abertura

    this.fields = props.fields ? props.fields : null;
    this.t = this.props.t; // para internacionalização
    this.i18n = this.props.i18n; // para internacionalização

    this.webserver = new RestDataSource(); // para comunicação com a API (REST)
    this.restURI = `/rest/orcamento/send`; // para utilização nos pedidos de dados à API / URI do pedido

    window.onclick = this.handleWindowClick;
  }

  /**
   * @description
   *
   */
  componentDidMount() {
    this.t = this.props.t;

    let { lang } = this.state;
    lang = this.i18n.language;

    this.setState({ lang });
  }

  /**
   * @description
   *
   * @param {*}
   * @param {*}
   */
  componentDidUpdate(prevProps, prevState) {
    let { lang } = this.state;

    if (lang !== prevState.lang) {
      lang = this.props.i18n.language;
      this.setState({ lang });
    }

    /**
     * verifica se a janela / dashboar foi clicado...
     */
    /* if (this.props.wClicked !== null) {
      this.handleWindowClick(this.props.wClicked);
    } */
  }

  /**
   * @description
   * Para tratamento dos dados iseridos na form e submissão dos mesmos para eventual API.
   * Esta FORM opera no formato POPUP o que em si poderá significar que necessitará de proceder
   * ao tratamento de informação com origem em componentes distintos ou tratamento de informação
   * p vários fins, o que não é o caso neste projecto, i.e., esta serve apenas para solicitar
   * um orçamento junto da organização, pelo que o tratamento da informação fica lozalidaza neste
   * método
   *
   * @param {*} e
   * @param {*} usrData -> Dados inseridos pelo utilizador, guardados no component FORMS.js
   */
  handleSubmit = (e, usrData) => {
    e.preventDefault();
    const target = e.target;
    const { lang } = this.state;
    let { resMsg, resMsgStats } = this.state; // p tratamento da resposta do servidor

    resMsgStats = true; // abre popup de apresentação do feedback do servidor
    resMsg = this.t("emailSending"); // mostra mensagem "A enviar email..."

    // actualiza STATE vars e procede ao envio dos dados para API enviar email
    // com pedido de ORÇAMENTO
    this.setState({ resMsg, resMsgStats }, () => {
      // SEND DATA TO SERVER
      /**
       * cria objecto para recolha de dados inseridos na form... da form de pedido de orçamento
       * devem constar:
       * -> nome        - string
       * -> cidade      - string
       * -> telemóvel   - numeric
       * -> cometnários - string
       * -> li e aceito os termos etc.... -> array
       */
      let data2Send = setDataToFormData(usrData); // transforma o array de dados de utilizador num objecto FORMDATA

      // sendRequest(method, uri, callback, data)
      if (data2Send instanceof FormData) {
        // se processo anterior OK, procede ao envio da info.
        this.webserver.sendRequest(
          "POST", // método a usar no pedido
          `${lang}${this.restURI}`, // URL do pedido
          (result, xhr) => {
            // callback - resposta ao pedido
            // SE TDO OK
            if (
              xhr.status === 200 &&
              "res" in result &&
              result.res !== undefined &&
              result.res !== null
            ) {
              // obtem a resposta do servidor
              resMsg =
                "msg" in result && result.msg.trim().length > 0
                  ? ReactHtmlParser(result.msg)
                  : "";

              resMsgStats = true;
              this.setState({ resMsg, resMsgStats });

              // coloca a fonte a vermelho se assim se justificar... ERRO
              // errstats
              // se ERRO de servidor, sinaliza a resposta
              if (!result.res) {
                let textStats = document
                  .getElementsByClassName("sendmail-resp")[0]
                  .getElementsByClassName("text");

                textStats[0].classList.add("errstats");
              }
            } else {
              // SE NOK
              throw this.t("errorHandler").emailNotSentError;
            }
          },
          data2Send
        );
      }
    });
  };

  /**
   * @description
   * Activa o eventoClick nas setas e no header do POPUP
   *
   * Tratamento do evento para abrir/fechar a FORM
   *
   * @param {*} e
   */
  handleOpenClick = (e) => {
    e.stopPropagation();

    const budgetRequestContent = e.target.closest(".budget-request-content");
    const target = budgetRequestContent.getElementsByTagName("header")[0];

    let { openStats, resMsgStats, resMsg } = this.state;
    openStats = !openStats;

    // FAZ o reset de eventuais mensagens de erro e fecha
    // o popup de feedback de envio de email
    resMsgStats = false;
    resMsg = "";

    // procede ao RESET da FORM se o mesmo não estiver sinalizado como
    // erro
    let formContainer = document.getElementsByClassName(
      "budget-request-body"
    )[0];
    let errStats = formContainer.getElementsByClassName("text")[0];

    if (!errStats.classList.contains("errstats")) {
      let form = formContainer.getElementsByTagName("form");
      form[0].reset();
    }

    errStats.classList.remove("errstats");

    this.setState({ openStats, resMsgStats, resMsg });
  };

  /**
   * @description
   * Fecha mensagem de feedback ao processo de envio do pedido de orçamento
   *
   * @param {*} e
   */
  handleResMsgClose = (e) => {
    e.stopPropagation();

    const target = e.target;
    let { resMsgStats, resMsg } = this.state;

    resMsg = ""; // limpa a mensagem de feedback do envio do email
    resMsgStats = false; // fecha popup de mensagem

    this.setState({ resMsgStats, resMsg });

    // procede ao RESET da FORM se o mesmo não estiver sinalizado como
    // erro
    let formContainer = target.closest(".budget-request-body");
    let errStats = formContainer.getElementsByClassName("text")[0];

    if (!errStats.classList.contains("errstats")) {
      let form = formContainer.getElementsByTagName("form");
      form[0].reset();
    }

    errStats.classList.remove("errstats");
  };

  /**
   * @description
   * Para fecho da form de POPUP peça orçamento se esta estiver aberta...
   *
   * @param {*} e
   */
  handleWindowClick = (e) => {
    //const budgetRequestContent = e.target.closest(".budget-request-content");
    let { openStats } = this.state;

    /**
     * Verifica se o elemento clicado não está dentro do popup
     * de orçamento e se este elemento está aberto. Se condição
     * TRUE feça o popup de pedido de orçamento
     */
    if (e.currentTarget !== null && openStats) {
      openStats = false;
      this.setState({ openStats });
    }
  };

  handleCloseClick = (e) => {
    e.stopPropagation();

    const budgetRequestContent = e.target.closest(".budget-request-container");
    const target = budgetRequestContent.getElementsByTagName("span")[0];

    let { openStats, resMsgStats, resMsg } = this.state;
    openStats = !openStats;

    // FAZ o reset de eventuais mensagens de erro e fecha
    // o popup de feedback de envio de email
    resMsgStats = false;
    resMsg = "";

    // procede ao RESET da FORM se o mesmo não estiver sinalizado como
    // erro
    let formContainer = document.getElementsByClassName(
      "budget-request-body"
    )[0];
    let errStats = formContainer.getElementsByClassName("text")[0];

    if (!errStats.classList.contains("errstats")) {
      let form = formContainer.getElementsByTagName("form");
      form[0].reset();
    }

    errStats.classList.remove("errstats");

    this.setState({ openStats, resMsgStats, resMsg });
  };

  render() {
    return (
      <>
        <div
          className={`budget-request-container ${
            this.state.openStats ? this.openStatsClass : ""
          }`}
        >
          <span className="frm-arrow-container" onClick={this.handleCloseClick}>
            <img src="/assets/imgs/Seta-botao-3.png" alt="" />
            <img src="/assets/imgs/Seta-botao-4.png" alt="" />
          </span>
          {/* <div
            style={{
              overflow: "scroll",
              height: "calc(100vh - 66px)",
              zIndex: "999999",
            }}
          > */}
          <div className="col-12 budget-request-content">
            {/*  <span
              className="frm-arrow-container"
              onClick={this.handleOpenClick}
            >
              <img src="/assets/imgs/seta-botao-3.png" alt="" />
              <img src="/assets/imgs/Seta-botao-4.png" alt="" />
            </span> */}
            <header className="col-9 col-sm-8" onClick={this.handleOpenClick}>
              <span className=""></span>
              <h3 className="">{this.t("forms").titles.budget}</h3>
              <span
                className="closeMenu d-md-none"
                style={{ backgroundImage: "url(/assets/imgs/close.png)" }}
              ></span>
            </header>
            <div className="col-12 budget-request-body">
              <div
                className={`sendmail-resp ${
                  this.state.resMsgStats ? this.resMsgStatsClass : ""
                }`}
              >
                <span className="text">{this.state.resMsg}</span>
                <span
                  className="f-close"
                  onClick={this.handleResMsgClose}
                ></span>
              </div>

              <Forms
                id="budget"
                fields={this.fields}
                fReset={!this.state.openStats}
                onSubmit={this.handleSubmit}
              />
            </div>
            <footer className="col-12"></footer>
          </div>
          {/*  </div> */}
        </div>
      </>
    );
  }
}

export default withTranslation()(PopUpForm);
