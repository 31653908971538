import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import ReactHtmlParser from "react-html-parser";
//import HtmlParser from "react-html-parser";
import {
  extractElementsFromArrayByIsType,
  insertMediaWithAppAbsPath,
} from "../../../Toolbox";

//import TimeLine from "../universe/TimeLine";

import "../../../../src/scss/AboutUs.scss";

import { Parallax, ParallaxProvider } from "react-scroll-parallax";

/**
 * AKA SOMOS PRILUX
 */
class AboutUs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lang: null,
      data: null,
    };

    this.t = props.t; // tradutor
    this.i18n = null;
  }

  componentDidMount() {
    window.sessionStorage.removeItem("stickyBFM");
    this.t = this.props.t; // p utilização nas acções de tradução
    this.i18n = this.props.i18n;

    let { lang } = this.state;
    lang = this.i18n.language;

    this.setState({ lang });
  }

  componentDidUpdate(prevProps, prevState) {
    let { lang, data } = this.state;

    if (
      "pData" in this.props &&
      "content" in this.props.pData &&
      (data === null ||
        JSON.stringify(this.props.pData.content) !== JSON.stringify(data)) &&
      Object.keys(this.props.pData).length > 0
    ) {
      lang = this.i18n.language;
      data = this.props.pData.content;
      this.setState({ lang, data });
    }
  }

  makeBrands = () => {
    let brand = null;

    if (this.state.data !== null && this.state.data.brands) {
      brand = this.state.data.brands.map((val, key) => {
        // obtem imagem do banner
        let img = extractElementsFromArrayByIsType(val.imgs, "generic");
        // valida resultado obtido e gera o link absoluto da imagem
        img =
          img !== null ? insertMediaWithAppAbsPath(img[0].name, "imgs") : null;

        return (
          <div className="media" key={val.id}>
            <div
              className="align-self-start logo_marca"
              style={{ backgroundImage: `url(${img})` }}
            ></div>
            {/* <div className="Teste_img"></div> */}
            <div className="media-body">
              <p className="mt-0">{val.description}</p>
            </div>
          </div>
        );
      });
    }

    return brand;
  };

  render() {
    return (
      <>
        <div className="body-content_about">
          <p className="titulo_body_conteudo">
            {this.state.data
              ? ReactHtmlParser(this.state.data.about.title)
              : ""}
          </p>

          <div className="description_body_conteudo">
            {this.state.data
              ? ReactHtmlParser(this.state.data.about.description)
              : ""}
          </div>

          <div className="body_values">
            <div className="col-xl-6 somos_valor">
              <ParallaxProvider>
                <Parallax
                  className="custom-class"
                  //x={[-20, 0]}
                  x={[-40, 0]}
                  tagOuter="figure"
                >
                  <p className="valor_titulo">
                    {this.state.data
                      ? ReactHtmlParser(this.state.data.value[0].title)
                      : ""}
                  </p>
                </Parallax>
              </ParallaxProvider>

              <p className="valor_description">
                {this.state.data
                  ? ReactHtmlParser(this.state.data.value[0].description)
                  : ""}
              </p>
            </div>

            <div className="col-xl-6 marcas_valor">
              {/*   {this.makeBrands()}*/}
              {this.state.data !== undefined ? this.makeBrands() : null}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(AboutUs);
