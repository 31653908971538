import React /* useState, useEffect, isValidElement */ from "react";
//import { useTranslation } from "react-i18next";

// style
import "../scss/SocialMedia.scss";

/**
 * @dependencies
 * - /src/Toolbox
 */
import { Configs } from "../Toolbox";

/**
 * fb => Facebook
 * in => LinkedIn
 * ut => Youtube
 * ...
 *
 * @param {*} props
 */
const SocialMediaIcons = (props) => {
  const fAwsome = "fa"; // fa | fas | far
  //const { t } = useTranslation();

  // obtem os endereços das redes sociais do ficheiro Toolbox / Configs
  // remove as redes sociais que não possume conteúdo
  const social_media = Object.entries(Configs.social_media).filter((val) => {
    /**
     * remove as redes sociais sem conteúdo... daqui é transformado num
     * array com o formato:
     * [["fb", ["http://link.com", "icon-class-fontawsome"]],
     *  ["in", ["http://link.com", "icon-class-fontawsome"]],
     *  ["ut", ["http://link.com", "icon-class-fontawsome"]]]
     */
    return Array.isArray(val[1]) && val[1].length > 0;
  });

  /**
   * @description
   * Fabrica os ícones para as redes sociais de acordo com o conteúdo
   * do objecto social_media localizado em Configs ficheiro Toolbox
   *
   * @remark
   * Para eventual alteração do layout modificar esta seccção de código,
   * em alternativa alterar as CSS em social-media.scss
   * A cor dos ícones é a cor geral da fonte do site, para alterar, editar
   * o ficheiro social-media.scss
   *
   * @param {*}
   * @returns {jsx} -> ícones das redes sociais
   */
  const makeSocialMedia = () => {
    // processo de fabrico os icones
    let icones = social_media.map((val) => {
      return (
        <a key={val[0]} href={val[1][0]} target="_new">
          {/**
           * @remark
           * Ao alterar a versão do FontAwsome poderá ser necessário
           * alterar a class fa | far | fas
           **/}
          <i className={`${fAwsome} ${val[1][1]}`}></i>
        </a>
      );
    });

    return icones;
  };

  /**
   * mostra ícones no layout
   */
  return <div className="social-media">{makeSocialMedia()}</div>;
};

export default SocialMediaIcons;
