import React, { Component } from "react";
import { withTranslation /* , useTranslation */ } from "react-i18next";

class AppSearchBar extends Component {
  constructor(props) {
    super(props);
    this.t = props.t; // para efeitos de tradução
    this.i18n = props.i18n; // para efeitos de tradução

    this.state = { openStats: null /*this.props.searchStats*/, toSearch: "" };

    this.openClass = "sb-opened";
    this.nailedClass = "nailed";
    this.lang = null;
  }



  componentDidUpdate(prevProps, prevState) {
    this.lang = this.i18n.language;
  }

  handleClose = (e) => {
    e.stopPropagation();

    const target = e.target;
    //let { openStats } = this.state;
    let { toSearch } = this.state;

    //openStats = false;
    toSearch = "";

    this.setState({ toSearch: toSearch });

    this.props.hSearchStats(e);
  };

  handleKeyPress = (e) => {
    e.stopPropagation();

    const target = e.target;

    if (e.key === "Enter") {
      window.location =
        window.location.origin +
        `/${this.lang}/${this.t("search")}/q=${this.state.toSearch}`;

      //${this.state.toSearch
    }
  };

  handleChange = (e) => {
    e.stopPropagation();

    const target = e.target;

    let { toSearch } = this.state;
    toSearch = target.value;

    this.setState({ toSearch: toSearch });
  };

  render() {
    return (
      <div
        className={`col-12 searchbar ${
          this.props.searchStats ? this.openClass : ""
        } ${this.props.nailedStats ? this.nailedClass : ""}`}
      >
        <div className="col-lg-9 container-fluid">
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              id="search"
              name="search"
              value={this.state.toSearch}
              placeholder={this.props.t("menu").search[0]}
              onKeyPress={this.handleKeyPress}
              onChange={this.handleChange}
            />
            <span className="underline"></span>
            {/*<label htmlFor="exampleInputPassword1">"Enter" para pesquisa</label>*/}
          </div>
          <span className="close" onClick={this.handleClose}></span>
        </div>
      </div>
    );
  }
}

export default withTranslation()(AppSearchBar);
